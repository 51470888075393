import { Button } from 'components';
import styled from 'styled-components';

import backgroundImage from '../../media/login_view_image.jpg';
export const MainContainerWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
`;

export const MainContainerLeftSide = styled.div`
  width: 63%;
`;

export const MainContainerRightSide = styled.div`
  width: 37%;
  background: linear-gradient(rgba(10, 39, 86, 0.75), rgba(10, 39, 86, 0.8)),
    url(${backgroundImage});
  background-size: cover;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const MainContainerHeader = styled.div`
  max-width: 216px;
  margin-left: 10.15%;
  margin-top: 50px;
`;

export const MainContainerContentWrapper = styled.div`
  margin: 0 10.15%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const SiteLinksContainer = styled.ul`
  text-align: center;
  width: 12rem;
  margin-bottom: 2.5rem;
  columns: 2;
  list-style-type: none;
  padding: unset;
  margin-inline: auto;
  box-sizing: border-box;

  @media ${props => props.theme.media.desktop} {
    width: 18.5rem;
  }

  @media ${props => props.theme.media.bigDesktop} {
    columns: 4;
    width: 100%;
    padding: 0 3rem;
    margin-bottom: 4rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
`;

export const SampleFileButton = styled(Button)`
  background: white;
  height: 3rem;
  width: 100%;
  color: ${({ theme }) => theme.pallete.primary};
  background: ${({ theme }) => theme.pallete.primaryHover};

  &:hover {
    color: ${({ theme }) => theme.pallete.primaryHover};
    background: ${({ theme }) => theme.pallete.primary};
  }
`;

export const MainUserMenuWrapper = styled.div`
  margin: 6.8%;
`;

export const TextWrapper = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.pallete.primaryHover};
  text-transform: uppercase;
  font-weight: 800;
  margin: auto;
  text-align: center;
  padding: 2rem;
`;
