import DragAndDropFileUpload from 'components/DragAndDropFileUpload';
import {
  ModalContentWrapper,
  ModalHeader,
  ModalWrapper,
  StyledCloseIcon,
} from 'components/Modal/Modal.styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from 'store/actions/modalActions/ModalVisibility';

const LoadFileModal = ({
  hideModal,
  children,
  onCloseCallback,
  onDropAction,
  loadedFileComponent,
  showUploadField,
}) => (
  <ModalWrapper>
    <ModalContentWrapper>
      <ModalHeader>
        <StyledCloseIcon
          onClick={() => {
            hideModal();
            if (onCloseCallback) onCloseCallback();
          }}
        />
      </ModalHeader>
      {showUploadField ? (
        <DragAndDropFileUpload onDropAction={onDropAction}>
          {children}
        </DragAndDropFileUpload>
      ) : (
        loadedFileComponent
      )}
    </ModalContentWrapper>
  </ModalWrapper>
);

LoadFileModal.propTypes = {
  children: PropTypes.string,
  hideModal: PropTypes.func,
  loadedFileComponent: PropTypes.object,
  onCloseCallback: PropTypes.func,
  onDropAction: PropTypes.func,
  showUploadField: PropTypes.bool,
};

const mapDispatchToProps = {
  hideModal,
};

export default connect(null, mapDispatchToProps)(LoadFileModal);
