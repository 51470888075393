import { historyConst } from 'store/constants/historyConst';

export const clearHistoryStep = () => ({
  type: historyConst.CLEAR_HISTORY_STEPS,
});

export const setHistoryStep = historyStep => ({
  type: historyConst.SET_HISTORY_STEPS,
  historyStep,
});

export const setHistoryStepIndex = historyStepIndex => ({
  type: historyConst.SET_HISTORY_STEP_INDEX,
  historyStepIndex,
});

export const updateHistorySteps = historyStep => ({
  type: historyConst.UPDATE_HISTORY_STEPS,
  historyStep,
});
