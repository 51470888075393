import { ToolbarButton } from 'components/Button/Button.styled';
import { CustomDownloadIcon } from 'components/StructuresToolbar/StructuresToolbar.styled';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getEtaFile, getRsaFile } from 'store/selectors/promotionsSelectors';

const ContentImplementationSaveButtons = ({
  etaFile,
  onClickAction,
  rsaFile,
}) => (
  <>
    <ToolbarButton
      disabled={!etaFile}
      onClick={() => onClickAction(etaFile.id)}
      wide
    >
      <CustomDownloadIcon />
      Save ETA
    </ToolbarButton>
    <ToolbarButton
      disabled={!rsaFile}
      onClick={() => onClickAction(rsaFile.id)}
      wide
    >
      <CustomDownloadIcon />
      Save RSA
    </ToolbarButton>
  </>
);

const mapStateToProps = state => ({
  etaFile: getEtaFile(state),
  rsaFile: getRsaFile(state),
});

ContentImplementationSaveButtons.propTypes = {
  etaFile: PropTypes.object,
  rsaFile: PropTypes.object,
  onClickAction: PropTypes.func,
};

export default connect(mapStateToProps, null)(ContentImplementationSaveButtons);
