import { ArrowFromBottom as FileArrowFromBottomIcon } from '@styled-icons/boxicons-regular/ArrowFromBottom';
import { Close as CloseIcon } from '@styled-icons/evaicons-solid/Close';
import ReactModal from 'react-modal';
import styled, { keyframes } from 'styled-components';
ReactModal.setAppElement('#root');

const moveIcon = keyframes`
    0%, 100% {
      transform: translate(0, 0);
    }

    80% {
      transform: translate(0, -15px);
    }
`;

export const StyledReactModal = styled(ReactModal).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Overlay {
    z-index: 999;
  }
  .Modal {
    background: red;
    border: unset;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const ModalContentWrapper = styled.div`
  height: 90vh;
  width: 73vw;
  background: white;
  border-radius: 12px;
  margin: auto;
  padding: 0 1.561vw;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.media.notebook} {
    p,
    span {
      font-size: 0.7rem;
      line-height: 0.8rem;
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 5.09vh;
  min-height: 40px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.pallete.inputsBorder};

  button {
    padding: unset;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.pallete.errorButtonColor};
  height: 2.84vh;
  min-height: 28px;
`;

export const StyledFileIcon = styled(FileArrowFromBottomIcon)`
  animation: ${moveIcon} 1.5s linear infinite;
  color: ${({ theme }) => theme.pallete.placeholderText};
  height: 9.099vh;
  min-height: 30px;
`;
