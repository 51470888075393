import { TileBarOptionsTriangle } from 'components/TileOptionBar/TileOptionBar.styled';
import styled from 'styled-components';

export const SearchInputWrapper = styled.div`
  width: 15rem;
  height: 2.5rem;
  min-height: 45px;
  display: flex;
  position: absolute;
  top: -60%;
  background: white;
  align-items: center;
  padding: 0 0.989vw;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: ${({ shouldBeOnTop }) => (shouldBeOnTop ? 3 : 1)};
  right: -0.5rem;
`;

export const CustomSearchInput = styled.input`
  border: unset;
  margin: unset;
  width: inherit;
  height: inherit;
`;

export const SearchInputTriangle = styled(TileBarOptionsTriangle)`
  position: absolute;
  top: 100%;
  right: 10px;
  border-width: 0.5rem 0.34rem 0 0.34rem;
`;
