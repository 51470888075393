import axios from 'axios';
import { userConstants } from 'store/constants/userConst';
import { handleError } from 'utils';

const fetchGoogleAddsAccountsBegin = () => ({
  type: userConstants.FETCH_GOOGLE_ADS_ACCOUNT_BEGIN,
});

const fetchGoogleAddsAccountsSuccess = () => ({
  type: userConstants.FETCH_GOOGLE_ADS_ACCOUNT_SUCCESS,
});

const fetchGoogleAddsAccountsFailed = () => ({
  type: userConstants.FETCH_GOOGLE_ADS_ACCOUNT_FAILED,
});

export const fetchGoogleAdsAccounts = () => async dispatch => {
  dispatch(fetchGoogleAddsAccountsBegin);
  try {
    const { data } = await axios.get('/ga/account/');
    dispatch(fetchGoogleAddsAccountsSuccess());
    return data;
  } catch (error) {
    dispatch(fetchGoogleAddsAccountsFailed());
    return handleError(error, 'Fetch Google Ads accounts');
  }
};
