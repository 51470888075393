import { Avatar, UserIcon } from 'components/Avatar';
import useEscape from 'hooks/useEscape';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showModal } from 'store/actions/modalActions/ModalVisibility';

import {
  LogoutIcon,
  UserMenuDropdown,
  UserMenuDropdownItem,
  UserMenuWrapper,
} from './';

const UserMenu = ({ variant }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);

  useEscape(() => setShowDropdown(false));

  return (
    <UserMenuWrapper onBlur={() => setShowDropdown(false)}>
      <Avatar
        variant={variant}
        onClick={() => setShowDropdown(prevState => !prevState)}
      >
        <UserIcon />
      </Avatar>
      {showDropdown && (
        <UserMenuDropdown>
          <UserMenuDropdownItem
            onMouseDown={() =>
              history.push('/change-password', {
                from: window.location.pathname,
              })
            }
          >
            Change Password
          </UserMenuDropdownItem>
          <UserMenuDropdownItem
            onMouseDown={() =>
              dispatch(
                showModal({
                  modalType: 'logoutModal',
                  modalProps: { isModalOpen: true },
                }),
              )
            }
            logout
          >
            <LogoutIcon />
            Logout
          </UserMenuDropdownItem>
        </UserMenuDropdown>
      )}
    </UserMenuWrapper>
  );
};

UserMenu.propTypes = {
  variant: PropTypes.string,
};

export default UserMenu;
