import axios from 'axios';
import { skuConst } from 'store/constants/skuConst';
import { handleError } from 'utils';

export const downloadPromotionStructureBegin = () => ({
  type: skuConst.DOWNLOAD_PROMOTION_STRUCTURE_BEGIN,
});

export const downloadPromotionStructureSuccess = () => ({
  type: skuConst.DOWNLOAD_PROMOTION_STRUCTURE_SUCCESS,
});

export const downloadPromotionStructureFailed = () => ({
  type: skuConst.DOWNLOAD_PROMOTION_STRUCTURE_FAILED,
});

export const downloadPromotionStructureAction = promotionId => async dispatch => {
  dispatch(downloadPromotionStructureBegin());

  try {
    const { data } = await axios.get(`/ga/sku/${promotionId}/file`, {
      responseType: 'blob',
    });
    dispatch(downloadPromotionStructureSuccess());
    return data;
  } catch (error) {
    dispatch(downloadPromotionStructureFailed());
    return handleError(error, 'Download promotion structure');
  }
};
