import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const batchPromotionAdGroupsBegin = () => ({
  type: promotionConst.BATCH_PROMOTION_ADGROUPS_BEGIN,
});

export const batchPromotionAdGroupsSuccess = () => ({
  type: promotionConst.BATCH_PROMOTION_ADGROUPS_SUCCESS,
});

export const batchPromotionAdGroupsFailed = () => ({
  type: promotionConst.BATCH_PROMOTION_ADGROUPS_FAILED,
});

export const batchPromotionAdGroupsAction = (
  promotionId,
  adGroupsIdsList,
) => async dispatch => {
  dispatch(batchPromotionAdGroupsBegin());
  try {
    await axios.post(`/ga/ps_ad_group/${promotionId}/batch_create/`, {
      ad_groups: adGroupsIdsList,
    });
    dispatch(batchPromotionAdGroupsSuccess());
  } catch (error) {
    dispatch(batchPromotionAdGroupsFailed());
    handleError(error, 'Batch promotion ad groups');
  }
};
