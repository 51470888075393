import axios from 'axios';
import { adGroupConst } from 'store/constants/adGroupConst';
import { handleError } from 'utils';

export const addAdGroupToPromotionBegin = () => ({
  type: adGroupConst.ADD_ADGROUP_TO_PROMOTION_BEGIN,
});

export const addAdGroupToPromotionSuccess = () => ({
  type: adGroupConst.ADD_ADGROUP_TO_PROMOTION_SUCCESS,
});

export const addAdGroupToPromotionFailed = () => ({
  type: adGroupConst.ADD_ADGROUP_TO_PROMOTION_FAILED,
});

export const addAdGroupToPromotionAction = (
  promotionId,
  adGroupData,
) => async dispatch => {
  dispatch(addAdGroupToPromotionBegin());
  try {
    const { data } = await axios.post(`/ga/ps_ad_group/${promotionId}/`, {
      ...adGroupData,
      with_contents: true,
    });
    dispatch(addAdGroupToPromotionSuccess());
    return data;
  } catch (error) {
    dispatch(addAdGroupToPromotionFailed());
    handleError(error, 'Add group to promotion');
  }
};
