import { ReactComponent as LeftCurvedArrow } from 'media/svg/icons/curvedArrow.svg';
import { ReactComponent as DownloadIcon } from 'media/svg/icons/download.svg';
import { ReactComponent as TrashIconSvg } from 'media/svg/icons/trash.svg';
import styled from 'styled-components';

export const StructuresToolbarWrappper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.04vh;
`;

export const RightCurvedArrow = styled(LeftCurvedArrow)`
  transform: scaleX(-1);
`;

export const CustomDownloadIcon = styled(DownloadIcon)`
  margin-right: 0.344vw;
`;
export const TrashIcon = styled(TrashIconSvg)`
  padding-right: 0.2vw;
`;
