import axios from 'axios';
import queryString from 'query-string';
import { keywordsConst } from 'store/constants/keywordsConst';
import { handleError } from 'utils';

const fetchKeywordsBegin = () => ({
  type: keywordsConst.FETCH_KEYWORDS_BEGIN,
});

const fetchKeywordsSuccess = () => ({
  type: keywordsConst.FETCH_KEYWORDS_SUCCESS,
});

const fetchKeywordsFailed = () => ({
  type: keywordsConst.FETCH_KEYWORDS_FAILED,
});

export const fetchKeywords = (userId, query) => async dispatch => {
  const stringifiedQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  dispatch(fetchKeywordsBegin);

  try {
    const { data } = await axios.get(
      `ga/keyword/${userId}/${stringifiedQuery && `?${stringifiedQuery}`}`,
    );
    dispatch(fetchKeywordsSuccess());
    return data;
  } catch (error) {
    dispatch(fetchKeywordsFailed());
    return handleError(error, 'Fetch keywords');
  }
};
