import { NoContent } from 'components';
import CheckButtonsCells from 'components/CheckButtonsCells';
import { TileTooltip } from 'components/FeatureTile/FeatureTile.styles';
import { SkuTableRow } from 'components/SkuFilterModal/FilteredAdGroupTable/FilteredAdGroupTable.styled';
import { EllipsisBeginSpan } from 'components/TableItems/TableItems.styled';
import useAdGroupsData from 'hooks/useAdGroupsData';
import PropTypes from 'prop-types';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { getHasNext } from 'store/selectors/helpersSelectors';
import { getAdGroupsListToFilter } from 'store/selectors/promotionsSelectors';
import { withTheme } from 'styled-components';

import {
  BrandListHeader,
  BrandTableCell,
  BrandTableColumnHeader,
  BrandTableContainer,
  BrandTableContent,
} from './BrandTable.styled';

const BrandTable = ({ hasNext, fetchedAdGroups, theme }) => {
  const wide = '38%';
  const narrow = '8%';
  const columns = ['Campaign', 'Ad Group', 'Brand', 'Generic', 'Brand product'];
  const { fetchAdGroupsData } = useAdGroupsData();
  const [page, setPage] = useState(1);
  const fetchMoreData = async () => {
    const nextPage = page + 1;
    await fetchAdGroupsData({ page: nextPage }, { showToast: true });
    setPage(nextPage);
  };

  if (fetchedAdGroups && !fetchedAdGroups?.[0]) return <NoContent />;

  return (
    <BrandTableContainer>
      <BrandListHeader>
        {columns.map(name => {
          const isDataRow = columns.slice(0, 2).includes(name);
          return (
            <BrandTableColumnHeader
              flexBasis={isDataRow ? wide : narrow}
              maxWidth={isDataRow ? wide : narrow}
              center={!isDataRow}
              key={name}
            >
              {name}
            </BrandTableColumnHeader>
          );
        })}
      </BrandListHeader>
      <InfiniteScroll
        dataLength={fetchedAdGroups?.length || 0}
        next={fetchMoreData}
        hasMore={hasNext}
        scrollableTarget={'infiniteScrollBrandTable'}
        loader={<div> Loading </div>}
      >
        <BrandTableContent>
          {fetchedAdGroups?.map(
            ({
              ad_group,
              ad_group_name,
              campaign_name,
              ad_content_labels,
              id,
            }) => (
              <SkuTableRow key={ad_group}>
                <BrandTableCell data-tip={campaign_name} maxWidth={wide}>
                  <EllipsisBeginSpan>
                    &#x200E;{campaign_name}&#x200E;
                  </EllipsisBeginSpan>
                </BrandTableCell>
                <BrandTableCell data-tip={ad_group_name} maxWidth={wide}>
                  <EllipsisBeginSpan>
                    &#x200E;{ad_group_name}&#x200E;
                  </EllipsisBeginSpan>
                </BrandTableCell>
                <CheckButtonsCells adGroupId={id} labels={ad_content_labels} />
              </SkuTableRow>
            ),
          )}
        </BrandTableContent>
      </InfiniteScroll>
      <TileTooltip
        textColor={theme.pallete.primary}
        backgroundColor={theme.pallete.primaryDisabled}
      />
    </BrandTableContainer>
  );
};

const mapStateToProps = state => ({
  fetchedAdGroups: getAdGroupsListToFilter(state),
  hasNext: getHasNext(state),
});

BrandTable.propTypes = {
  parentRef: PropTypes.object,
  theme: PropTypes.object,
  hasNext: PropTypes.bool,
  isFetching: PropTypes.bool,
  fetchedAdGroups: PropTypes.arrayOf(
    PropTypes.shape({
      ad_group: PropTypes.number,
      ad_group_name: PropTypes.string,
      campaign_name: PropTypes.string,
      ad_content_labels: PropTypes.array,
      id: PropTypes.number,
      sitelink_labels: PropTypes.array,
    }),
  ),
  fetchAdGroupsData: PropTypes.func,
};
export default connect(mapStateToProps, null)(withTheme(BrandTable));
