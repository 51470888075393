import { NoContent } from 'components';
import { EllipsisBeginSpan } from 'components/TableItems/TableItems.styled';
import PropTypes from 'prop-types';

import useFilteredAdgroupTable from './FilteredAdgroupTable.hook';
import {
  DisableCheck,
  FilterListHeader,
  RowsContainer,
  SkuTableCell,
  SkuTableColumnHeader,
  SkuTableRow,
} from './FilteredAdGroupTable.styled';

export const FilteredAdgroupTable = ({ unselectedRows, onChangeSelection }) => {
  const { dataSource, filterProductNames } = useFilteredAdgroupTable();
  const cellWidth = filterProductNames ? '37%' : '45%';
  const filteredData = dataSource.toJS();

  if (!filteredData.length) {
    return (
      <NoContent
        height="100%"
        message="There are not any active campaigns or ad groups..."
      />
    );
  }

  return (
    <>
      <FilterListHeader>
        <SkuTableColumnHeader flexBasis={cellWidth} maxWidth={cellWidth}>
          Ad group
        </SkuTableColumnHeader>
        <SkuTableColumnHeader flexBasis={cellWidth} maxWidth={cellWidth}>
          Campaign
        </SkuTableColumnHeader>
        {filterProductNames && (
          <>
            <SkuTableColumnHeader flexBasis="16%" maxWidth="16%">
              Product
            </SkuTableColumnHeader>
            <SkuTableColumnHeader flexBasis="5%">
              Similarity index
            </SkuTableColumnHeader>
          </>
        )}
        <SkuTableColumnHeader center flexBasis="5%">
          Checked
        </SkuTableColumnHeader>
      </FilterListHeader>
      <RowsContainer>
        {filteredData?.map(
          ({
            campaign_ga_name,
            ad_group_ga_name,
            ad_group_id,
            similarity,
            product_name,
          }) => (
            <SkuTableRow
              key={ad_group_id}
              disabledRow={
                unselectedRows && unselectedRows.includes(ad_group_id)
              }
            >
              <SkuTableCell flexBasis={cellWidth} maxWidth={cellWidth}>
                <EllipsisBeginSpan data-tip={ad_group_ga_name}>
                  &#x200E;{ad_group_ga_name}&#x200E;
                </EllipsisBeginSpan>
              </SkuTableCell>
              <SkuTableCell
                data-tip={campaign_ga_name}
                flexBasis={cellWidth}
                maxWidth={cellWidth}
              >
                <EllipsisBeginSpan>
                  &#x200E;{campaign_ga_name}&#x200E;
                </EllipsisBeginSpan>
              </SkuTableCell>
              {filterProductNames && (
                <>
                  <SkuTableCell flexBasis={'16%'} maxWidth={'16%'}>
                    <EllipsisBeginSpan data-tip={product_name}>
                      &#x200E;{product_name}&#x200E;
                    </EllipsisBeginSpan>
                  </SkuTableCell>
                  <SkuTableCell center flexBasis="5%" maxWidth="5%">
                    {similarity && Number(similarity).toFixed(2)}
                  </SkuTableCell>
                </>
              )}
              <SkuTableCell
                center
                flexBasis="5%"
                maxWidth="5%"
                onClick={() => onChangeSelection(ad_group_id)}
              >
                <DisableCheck
                  disabledRow={
                    unselectedRows && unselectedRows.includes(ad_group_id)
                  }
                />
              </SkuTableCell>
            </SkuTableRow>
          ),
        )}
      </RowsContainer>
    </>
  );
};

FilteredAdgroupTable.propTypes = {
  unselectedRows: PropTypes.array,
  onChangeSelection: PropTypes.func,
};

export default FilteredAdgroupTable;
