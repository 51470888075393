import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider as ThemeProviderBase } from 'styled-components';

import { GlobalStyle } from './';
import { theme } from './theme';

export const ThemeProvider = ({ children }) => (
  <ThemeProviderBase theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProviderBase>
);

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
