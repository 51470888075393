import axios from 'axios';
import { keywordsConst } from 'store/constants/keywordsConst';
import { handleError } from 'utils';

const addNewKeywordBegin = () => ({
  type: keywordsConst.ADD_NEW_KEYWORD_BEGIN,
});

const addNewKeywordSuccess = () => ({
  type: keywordsConst.ADD_NEW_KEYWORD_SUCCESS,
});

const addNewKeywordFailed = () => ({
  type: keywordsConst.ADD_NEW_KEYWORD_FAILED,
});

export const addNewKeywordAction = (
  accountId,
  keywordData,
) => async dispatch => {
  dispatch(addNewKeywordBegin);
  try {
    const { data } = await axios.post(`ga/keyword/${accountId}/`, keywordData);
    dispatch(addNewKeywordSuccess());
    return data;
  } catch (error) {
    dispatch(addNewKeywordFailed());
    return { error: handleError(error, 'Add new keyword') };
  }
};
