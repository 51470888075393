import { SearchInputWrapper } from 'components/TileSearchInput/SearchInput.styled';
import styled from 'styled-components';

export const CampaignSearchWrapper = styled(SearchInputWrapper)`
  padding: 0 1rem;
  border: 1px solid ${({ theme }) => theme.pallete.inputsBorder};
  margin-top: 1.2%;
  position: relative;
  box-shadow: unset;
  width: 35%;
  right: unset;
  height: 2.75rem;
  min-width: 360px;
`;

export const SearcherInput = styled.input`
  height: unset;
  width: inherit;
  margin: 2.25% 0 2.25% 0.78125vw;
  border: unset;
  padding: unset;
`;

export const CampaignSearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const IconWrapper = styled.div`
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
