import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getUserAccountDetails } from 'store/selectors/userSelectors';

import { DropdownHint, DropdownItem } from './ChooseAccount.styled';

const SelectedAccount = ({ isFetchingAccounts, accountDetails }) => {
  if (isFetchingAccounts) {
    return <DropdownItem> Loading...</DropdownItem>;
  }
  if (!Object.keys(accountDetails).length || !accountDetails?.userName) {
    return <DropdownItem> No accounts</DropdownItem>;
  }
  const { clientCustomerId, userName } = accountDetails;
  return (
    <DropdownItem>
      {userName}
      <DropdownHint>{clientCustomerId}</DropdownHint>
    </DropdownItem>
  );
};

SelectedAccount.propTypes = {
  isFetchingAccounts: PropTypes.bool,
  accountDetails: PropTypes.object,
};

const mapStateToProps = state => ({
  accountDetails: getUserAccountDetails(state),
});

export default connect(mapStateToProps, null)(SelectedAccount);
