import useCampaign from 'hooks/useCampaign';
import useToasts from 'hooks/useToasts';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { setIsLoading as isLoadingSetter } from 'store/actions/helpersActions/helpersSetters';
import { getIsLoading, getSearchQuery } from 'store/selectors/helpersSelectors';
import { getHasMoreCampaigns } from 'store/selectors/promotionsSelectors';

const useCampaignBoxWrapper = () => {
  const dispatch = useDispatch();
  const hasMoreCampaigns = useSelector(getHasMoreCampaigns);
  const searchQuery = useSelector(getSearchQuery);
  const loadStateFromStore = useSelector(getIsLoading);
  const { campaigns, fetchAddedPromotionCampaigns } = useCampaign();
  const { showFetchingToast, closeStaticToast } = useToasts();
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMoreCampaigns = async () => {
    showFetchingToast();
    const nextPage = page + 1;
    await fetchAddedPromotionCampaigns({ page: nextPage, search: searchQuery });
    setPage(nextPage);
    closeStaticToast();
  };

  const fetchPromotionCampaigns = async () => {
    setIsLoading(true);
    const data = await fetchAddedPromotionCampaigns();
    setIsLoading(false);
    dispatch(isLoadingSetter(false));
    if (!data) return;
    setHasNext(!!data.next);
  };

  useEffect(() => {
    fetchPromotionCampaigns();
  }, []);

  useEffect(() => {
    setHasNext(hasMoreCampaigns);
  }, [hasMoreCampaigns]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [campaigns]);

  return {
    campaigns,
    fetchMoreCampaigns,
    hasNext,
    isLoading,
    loadStateFromStore,
  };
};

export default useCampaignBoxWrapper;
