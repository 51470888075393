import {
  ListHeader,
  TableColumnHeader,
} from 'components/TableItems/TableItems.styled';
import styled from 'styled-components';

export const BrandListHeader = styled(ListHeader)`
  background: ${({ theme }) => theme.pallete.tableRowsHighlight};
  color: #000;
  border-bottom: 2px solid ${({ theme }) => theme.pallete.primary};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

export const BrandTableContainer = styled.div`
  width: 95%;
  span {
    &:nth-child(odd) {
      color: unset;
    }
  }
`;
export const BrandTableColumnHeader = styled(TableColumnHeader)`
  min-width: 67px;
`;

export const BrandTableContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BrandTableCell = styled.span`
  display: flex;
  flex-basis: ${({ theme, flexBasis }) =>
    flexBasis || theme.customTableFieldSize};
  align-items: center;
  ${({ center }) => center && 'justify-content:center;'}
  min-width: 67px;
  margin-left: 4px;
`;
