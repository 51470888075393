import { FeatureTileVariants } from 'components/FeatureTile';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateNewFeaturesDraftAction } from 'store/actions/draftActions/updateDraftsActions';

import useAdGroups from './useAdGroups';
import useKeywords from './useKeywords';

const useNewFeature = (parentId, type) => {
  const {
    addNewAdGroup,
    addAdGroupToPromotion,
    fetchCampaignsAdGroups,
  } = useAdGroups();
  const {
    addNewKeyword,
    addKeywordToPromotion,
    fetchAdGroupKeywords,
  } = useKeywords();
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const focusTextInput = () => {
    inputRef.current.focus();
    inputRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const onInputBlur = async () => {
    dispatch(updateNewFeaturesDraftAction([]));
    if (!inputValue) return;

    if (type === FeatureTileVariants.adGroup) {
      const { id: newAdGroupId } = await addNewAdGroup({
        ga_name: inputValue,
        campaign: parentId,
      });
      await addAdGroupToPromotion(newAdGroupId);
      await fetchCampaignsAdGroups({ campaignId: parentId });
    }

    if (type === FeatureTileVariants.keyword) {
      const results = await addNewKeyword({
        ga_text: inputValue,
        ad_group: parentId,
      });
      if (results?.error) return;
      const { id: newKeywordId } = results;
      await addKeywordToPromotion(newKeywordId);
      await fetchAdGroupKeywords(parentId, { addToHistory: true });
    }
  };

  useEffect(() => {
    if (!inputRef.current) return;
    focusTextInput();
  }, [inputRef]);

  return { inputRef, inputValue, onInputBlur, setInputValue };
};

export default useNewFeature;
