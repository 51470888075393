import { StructuresToolbar } from 'components';
import CampaignBoxWrapper from 'components/CampaignBox/CampaignBoxWrapper';
import { FilterInput } from 'components/FilterInput';
import { StructuresContainer } from 'containers';
import { ContainerTitle } from 'containers/StructuresContainer/StrucuresContainer.styled';
import useAccess from 'hooks/useAccess';
import { memo } from 'react';

import useSummaryView from './useSummaryView';

const SummaryView = () => {
  useAccess();
  const { setSearchQuery } = useSummaryView();

  return (
    <StructuresContainer currentStep={3}>
      <StructuresToolbar showUndoRedo={false} />
      <ContainerTitle>Summary</ContainerTitle>
      <FilterInput
        placeholder="Enter campaign name to filter..."
        onChange={setSearchQuery}
      />
      <CampaignBoxWrapper useLoadStateFromStore withPadding={false} />
    </StructuresContainer>
  );
};

export default memo(SummaryView);
