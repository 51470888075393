import { combineReducers } from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import {
  draftReducer,
  helpersReducer,
  historyReducer,
  modalReducer,
  promotionsReducer,
  userReducer,
} from './reducers';

export const configureStore = () => {
  let enhancer = compose(applyMiddleware(thunk));
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    enhancer = compose(
      applyMiddleware(thunk, logger),
      window.navigator.userAgent.includes('Chrome')
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        : compose,
    );
  }

  const reducers = combineReducers({
    modal: modalReducer,
    promotions: promotionsReducer,
    user: userReducer,
    history: historyReducer,
    draft: draftReducer,
    helpers: helpersReducer,
  });

  return createStore(reducers, enhancer);
};
