import {
  FeatureContentContainer,
  FeatureTileWrapper,
  FlexButton,
  TileTitle,
} from 'components/FeatureTile/FeatureTile.styles';
import styled from 'styled-components';

export const CampaignTileWrapper = styled(FeatureContentContainer)`
  padding: 0 12px;

  @media screen and (max-width: 850px) {
    justify-content: center;
  }
`;

export const CampaignFlexButton = styled(FlexButton)`
  margin: 0 0.885vw 0 0.7291vw;
  height: 60px;
  width: 350px;

  @media ${props => props.theme.media.notebook} {
    width: 180px;
    height: 50px;

    span {
      width: 180px;
      font-size: 14px;
      line-height: 14px;
    }
  }

  @media ${props => props.theme.media.desktop} {
    width: 250px;
    height: 50px;
  }
`;

export const CampaignTitle = styled(TileTitle)`
  background: ${({ theme }) => theme.pallete.campaignTile};
  color: ${({ theme }) => theme.pallete.campaignTileText};
`;

export const CustomFeatureTileWrapper = styled(FeatureTileWrapper)`
  margin: unset;
  padding-bottom: unset;
`;
