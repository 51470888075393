import Dropdown from 'components/Dropdown/Dropdown';
import useEscape from 'hooks/useEscape';
import useOutsideClick from 'hooks/useOutsideClick';
import { ReactComponent as MagnifierIcon } from 'media/svg/icons/magnifier.svg';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import {
  CustomSearchInput,
  SearchInputTriangle,
  SearchInputWrapper,
} from './SearchInput.styled';
import useSearchInput from './useSearchInput.hook';

const TileSearchInput = ({
  ids,
  tileType,
  placeholder,
  setIsTileSearchOpen,
}) => {
  const wrapperRef = useRef(null);
  const {
    showDropdown,
    setShowDropdown,
    fetchSearchHints,
    dataSource,
    onClickAction,
    fetchingData,
    setDropdownHeight,
    setSearchQuery,
  } = useSearchInput(ids, tileType);

  useOutsideClick(wrapperRef, () => setIsTileSearchOpen(false));
  useEscape(() => setIsTileSearchOpen(false));

  return (
    <SearchInputWrapper
      shouldBeOnTop
      ref={wrapperRef}
      onClick={() => setShowDropdown(prevState => !prevState)}
    >
      <MagnifierIcon />
      <CustomSearchInput
        placeholder={placeholder}
        onChange={e => setSearchQuery(e.target.value)}
      />
      <SearchInputTriangle />
      {showDropdown && (
        <Dropdown
          onSize={size => setDropdownHeight(size.height)}
          fetchingData={fetchingData}
          dataSource={dataSource}
          loadMoreAction={fetchSearchHints}
          onClickAction={onClickAction}
          setShowDropdown={setShowDropdown}
        />
      )}
    </SearchInputWrapper>
  );
};

TileSearchInput.propTypes = {
  tileType: PropTypes.string,
  placeholder: PropTypes.string,
  onClickAction: PropTypes.func,
  parentId: PropTypes.number,
  ids: PropTypes.object,
  setIsTileSearchOpen: PropTypes.func,
};

export default TileSearchInput;
