import { useDispatch, useSelector } from 'react-redux';
import { setFilteredAdGroupsBySimilarity } from 'store/actions/promotionActions/promotionSetters';
import { fetchFilteredAdGroupsBySimilarityAction } from 'store/actions/skuFilesActions/fetchFilteredAdGroupsBySimilarity';
import {
  getFilteredAdGroupsBySimilarity,
  getSelectedPromotionId,
} from 'store/selectors/promotionsSelectors';

const useSkuSimilarityFiltration = () => {
  const dispatch = useDispatch();
  const filteredAdGroupsBySimilarity = useSelector(
    getFilteredAdGroupsBySimilarity,
  );
  const promotionId = useSelector(getSelectedPromotionId);

  const clearFilteredAdGroupsBySimilarity = () =>
    dispatch(setFilteredAdGroupsBySimilarity([]));

  const fetchFilteredAdGroupsBySimilarity = async (query, excludedAdGroups) =>
    dispatch(
      fetchFilteredAdGroupsBySimilarityAction(
        promotionId,
        query,
        excludedAdGroups,
      ),
    );

  const removeLastFetchedSimilarityResults = amount =>
    dispatch(
      setFilteredAdGroupsBySimilarity(
        filteredAdGroupsBySimilarity.slice(0, -amount),
      ),
    );
  return {
    clearFilteredAdGroupsBySimilarity,
    fetchFilteredAdGroupsBySimilarity,
    filteredAdGroupsBySimilarity,
    removeLastFetchedSimilarityResults,
  };
};

export default useSkuSimilarityFiltration;
