import { PrivateRoute } from 'components';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  ChangePasswordView,
  ChooseAccount,
  ContentImplementationView,
  LoginView,
  SitelinksView,
  StructuresView,
  SummaryView,
} from 'views';

const MainRouter = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/account" component={ChooseAccount} />
      <PrivateRoute path="/structure" component={StructuresView} />
      <PrivateRoute path="/change-password" component={ChangePasswordView} />
      <PrivateRoute
        path="/content-implementation"
        component={ContentImplementationView}
      />
      <Route path="/sitelinks" component={SitelinksView} />
      <Route path="/summary" component={SummaryView} />
      <Route path={['/login', '/']}>
        <LoginView />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default MainRouter;
