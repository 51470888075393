import { SitelinksTable } from 'components';
import {
  Loader,
  StyledLoaderContainer,
  StyledLoaderMessage,
} from 'components/Loader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsLoading } from 'store/selectors/helpersSelectors';

const SitelinksTableWrapper = ({ isLoading }) => {
  if (isLoading) {
    return (
      <StyledLoaderContainer height="inherit">
        <Loader />
        <StyledLoaderMessage>Fetching data...</StyledLoaderMessage>
      </StyledLoaderContainer>
    );
  }

  return <SitelinksTable />;
};

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
});

SitelinksTableWrapper.propTypes = {
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, null)(SitelinksTableWrapper);
