import styled from 'styled-components';

export const DragAndDropContainer = styled.div`
  height: 90%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DAE4F2' stroke-width='4' stroke-dasharray='10%2c 12' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.pallete.placeholderText};
  font-size: 0.9rem;
  margin: 30px 0;
  overflow: hidden;

  span {
    color: ${({ theme }) => theme.pallete.settingsHover};
    padding: 1rem;
    text-align: center;
  }
`;

export const DragAndDropContentWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.pallete.placeholderText};
  border-radius: 10%;
  height: 20rem;
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
