import { FeatureTileVariants } from 'components/FeatureTile';
import {
  FlexButton,
  SettingsButtonWrapper,
  SettingsDots,
} from 'components/FeatureTile/FeatureTile.styles';
import TileOptionBar from 'components/TileOptionBar/TileOptionBar';
import TileSearchInput from 'components/TileSearchInput';
import PropTypes from 'prop-types';
import React from 'react';

const CampaignTileSettings = ({
  addTileAction,
  deleteTileAction,
  isOptionsOpen,
  setIsOptionsOpen,
  isTileSearchOpen,
  setIsTileSearchOpen,
  ids,
}) => (
  <SettingsButtonWrapper isTileSearchOpen={isTileSearchOpen}>
    {isTileSearchOpen && (
      <TileSearchInput
        ids={{ parentId: ids.parentId, featureId: ids.parentId }}
        tileType={FeatureTileVariants.campaign}
        placeholder={`Find ${FeatureTileVariants.adGroup}`}
        setIsTileSearchOpen={setIsTileSearchOpen}
      />
    )}
    {isOptionsOpen && (
      <TileOptionBar
        addItemAction={() => addTileAction(ids.parentId, ids.psCampaignId)}
        deleteItemAction={() =>
          deleteTileAction(ids.parentId, ids.psCampaignId)
        }
        setIsTileSearchOpen={setIsTileSearchOpen}
        setIsOptionsOpen={setIsOptionsOpen}
      />
    )}
    <FlexButton
      disabled={isTileSearchOpen}
      onClick={() => setIsOptionsOpen(!isOptionsOpen)}
    >
      <SettingsDots />
    </FlexButton>
  </SettingsButtonWrapper>
);

CampaignTileSettings.propTypes = {
  addTileAction: PropTypes.func,
  campaignBoxType: PropTypes.bool,
  deleteTileAction: PropTypes.func,
  isOptionsOpen: PropTypes.bool,
  setIsOptionsOpen: PropTypes.func,
  isTileSearchOpen: PropTypes.bool,
  setIsTileSearchOpen: PropTypes.func,
  ids: PropTypes.shape({
    parentId: PropTypes.number,
    psCampaignId: PropTypes.number,
  }),
};

export default CampaignTileSettings;
