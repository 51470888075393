import DragAndDropFileUpload from 'components/DragAndDropFileUpload';
import {
  ModalContentWrapper,
  ModalHeader,
  ModalWrapper,
  StyledCloseIcon,
} from 'components/Modal/Modal.styled';
import useSku from 'hooks/useSku';

import SkuFilterContentWrapper from './SkuFilterContentWrapper';

const SkuFilterModal = () => {
  const {
    closeSkuModal,
    fileLoaded,
    sendSkuFile,
    setFileLoaded,
    isProcessing,
  } = useSku();

  return (
    <ModalWrapper>
      <ModalContentWrapper>
        <ModalHeader>
          <button disabled={isProcessing} onClick={closeSkuModal}>
            <StyledCloseIcon />
          </button>
        </ModalHeader>
        {fileLoaded ? (
          <SkuFilterContentWrapper
            isProcessing={isProcessing}
            setFileLoaded={setFileLoaded}
          />
        ) : (
          <DragAndDropFileUpload onDropAction={sendSkuFile}>
            Drag and drop some SKU files here, or click to select files
          </DragAndDropFileUpload>
        )}
      </ModalContentWrapper>
    </ModalWrapper>
  );
};
export default SkuFilterModal;
