import { UserMenu } from 'components';
import { Button, buttonVariants } from 'components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { showModal } from 'store/actions/modalActions/ModalVisibility';

import useStructuresHeader from './StructuresHeader.hook';
import {
  HeaderButtonsWrapper,
  HeaderLogo,
  HeaderUserMenuWrapper,
  LogoWrapper,
  StructuresHeaderWrapper,
} from './StructuresHeader.styled';

const StructuresHeader = ({ showModal }) => {
  const {
    backButtonAction,
    backToPromotionAction,
    buttonData,
    goBackButtonVisibility,
    goBackToPromotionsVisibility,
    isFinishButton,
    nextButtonAction,
    nextButtonVisibility,
  } = useStructuresHeader();

  return (
    <StructuresHeaderWrapper>
      <LogoWrapper>
        <HeaderLogo />
      </LogoWrapper>
      <HeaderButtonsWrapper>
        {buttonData &&
          buttonData?.map(({ label, modalType, uploadedFileType }) => (
            <Button
              key={label}
              variant={buttonVariants.WARNING}
              onClick={() => {
                showModal({
                  modalType,
                  modalProps: { isModalOpen: true },
                  uploadedFileType,
                });
              }}
            >
              {label}
            </Button>
          ))}
        {goBackToPromotionsVisibility && (
          <Button
            variant={buttonVariants.SECONDARY}
            onClick={backToPromotionAction}
          >
            Promotions list
          </Button>
        )}
        {goBackButtonVisibility && (
          <Button variant={buttonVariants.SECONDARY} onClick={backButtonAction}>
            Back
          </Button>
        )}
        {nextButtonVisibility && (
          <Button
            variant={buttonVariants.PRIMARY}
            fontSize={isFinishButton ? '0.6rem' : undefined}
            onClick={nextButtonAction}
            wrapText
          >
            {isFinishButton ? 'Upload structure to Google account' : 'Next'}
          </Button>
        )}
      </HeaderButtonsWrapper>
      <HeaderUserMenuWrapper>
        <UserMenu />
      </HeaderUserMenuWrapper>
    </StructuresHeaderWrapper>
  );
};

const mapDispatchToProps = {
  showModal,
};

StructuresHeader.propTypes = {
  showModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(StructuresHeader);
