import {
  ButtonLoader,
  StyledFlexMessageContainer,
  StyledToastLoaderMessage,
} from 'components/Loader/Loader.styled';
import { useRef } from 'react';
import { toast } from 'react-toastify';

const useToasts = () => {
  const toastId = useRef(null);

  const showStaticToast = (message, options) =>
    (toastId.current = toast.info(message, {
      hideProgressBar: true,
      autoClose: false,
      ...options,
    }));

  const closeStaticToast = () =>
    toastId && toastId.current && toast.dismiss(toastId.current);

  const showRequestResultsToast = (hasError, errorMsg, succesMsg, callback) => {
    closeStaticToast();
    if (hasError) return toast.error(errorMsg);
    if (callback) callback();
    return toast.success(succesMsg);
  };

  const showFetchingToast = () =>
    showStaticToast(
      <StyledFlexMessageContainer>
        <ButtonLoader />
        <StyledToastLoaderMessage>Fetching data...</StyledToastLoaderMessage>
      </StyledFlexMessageContainer>,
      { position: 'bottom-center', autoClose: false },
    );

  return {
    closeStaticToast,
    showStaticToast,
    showRequestResultsToast,
    showFetchingToast,
  };
};

export default useToasts;
