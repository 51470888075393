import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  border-radius: 50%;
  -webkit-animation: ${spin} 1s linear infinite; /* Safari */
  animation: ${spin} 1s linear infinite;
  border: 16px solid ${({ theme }) => theme.pallete.primary};
  border-top: 16px solid ${({ theme }) => theme.pallete.primaryHover};
  width: 100px;
  height: 100px;

  @media ${props => props.theme.media.tablet} {
    width: 50px;
    height: 50px;
  }
`;

export const ButtonLoader = styled(Loader)`
  border: 3px solid ${({ theme }) => theme.pallete.primary};
  border-top: 3px solid ${({ theme }) => theme.pallete.primaryHover};
  width: 10px;
  height: 10px;
  margin: auto;
`;

export const StyledLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  ${({ height }) => height && `height: ${height} ;`};
`;

export const StyledLoaderMessage = styled.span`
  color: ${({ theme }) => theme.pallete.placeholderText};
  padding: 10px;
`;

export const StyledToastLoaderMessage = styled.span`
  padding: 0 5px;
`;

export const StyledFlexMessageContainer = styled.div`
  display: flex;
`;
