import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    font-size: 14px;
  }
  .Toastify__toast--error {
    background: ${({ theme }) => theme.pallete.errorTextColor};
  }
  .Toastify__toast--warning {
    background: ${({ theme }) => theme.pallete.warningColor};
  }
  .Toastify__toast--success {
    background: ${({ theme }) => theme.pallete.successBorderColor};
  }
`;
