import axios from 'axios';
import queryString from 'query-string';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

const fetchAccountPromotionsBegin = () => ({
  type: promotionConst.FETCH_ACCOUNT_PROMOTIONS_BEGIN,
});

const fetchAccountPromotionsSuccess = fetchedPromotions => ({
  type: promotionConst.FETCH_ACCOUNT_PROMOTIONS_SUCCESS,
  fetchedPromotions,
});

const fetchAccountPromotionsFailed = () => ({
  type: promotionConst.FETCH_ACCOUNT_PROMOTIONS_FAILED,
});

export const fetchAccountPromotions = (accountId, query) => async dispatch => {
  const stringifiedQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  dispatch(fetchAccountPromotionsBegin());
  try {
    const { data } = await axios.get(
      `/ga/promotion_structure/${accountId}/${
        stringifiedQuery && `?${stringifiedQuery}`
      }`,
    );
    dispatch(fetchAccountPromotionsSuccess(data));
    return data;
  } catch (error) {
    dispatch(fetchAccountPromotionsFailed());
    return handleError(error, 'Fetch account promotions');
  }
};
