import PropTypes from 'prop-types';

import { Loader, StyledLoaderContainer, StyledLoaderMessage } from '../Loader';
import SkuFilterContent from './SkuFilterContent/SkuFilterContent';

const SkuFilterContentWrapper = ({ isProcessing, setFileLoaded }) => {
  if (isProcessing) {
    return (
      <StyledLoaderContainer height="100%">
        <Loader />
        <StyledLoaderMessage>Processing SKU...</StyledLoaderMessage>
      </StyledLoaderContainer>
    );
  }
  return <SkuFilterContent setFileLoaded={setFileLoaded} />;
};

SkuFilterContentWrapper.propTypes = {
  isProcessing: PropTypes.bool,
  setFileLoaded: PropTypes.func,
};

export default SkuFilterContentWrapper;
