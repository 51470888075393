import 'react-toastify/dist/ReactToastify.css';

import { Modal, StyledToastContainer } from 'components';
import { ThemeProvider } from 'theme';
import { configureAxiosDefaults } from 'utils';

import MainRouter from './routers/MainRouter/';

configureAxiosDefaults();

function App() {
  return (
    <ThemeProvider>
      <Modal />
      <MainRouter />
      <StyledToastContainer />
    </ThemeProvider>
  );
}

export default App;
