import useOutsideClick from 'hooks/useOutsideClick';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setDropdownHeightAction } from 'store/actions/helpersActions/helpersSetters';

const useDropdown = setShowDropdown => {
  const dispatch = useDispatch();
  const dropdownItemRef = useRef(null);
  useEffect(() => () => dispatch(setDropdownHeightAction(null)), []);

  useOutsideClick(dropdownItemRef, () => setShowDropdown(false));

  return { dropdownItemRef };
};

export default useDropdown;
