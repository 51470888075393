import { campaignBoxTypes } from 'components/CampaignBox';
import useKeywords from 'hooks/useKeywords';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNewFeaturesDraft } from 'store/selectors/draftSelectors';
import { getCampaignBoxType } from 'store/selectors/helpersSelectors';
import { getFetchedPromotionKeywordsByAdGroupId } from 'store/selectors/promotionsSelectors';

const useAdGroupBox = ({ id: psAdGroupId, ad_group: adGroupId }) => {
  const [promotionKeywords, setPromotionKeywords] = useState([]);
  const { fetchAdGroupKeywords } = useKeywords();
  const fetchedPromotionAdGroups = useSelector(state =>
    getFetchedPromotionKeywordsByAdGroupId(state, adGroupId),
  );
  const featuresInDraft = useSelector(state =>
    getNewFeaturesDraft(state, psAdGroupId),
  );
  const campaignBoxType = useSelector(getCampaignBoxType);

  const fetchExpandedAdGroupChildren = async (isBoxExpanded, callback) => {
    if (campaignBoxType === campaignBoxTypes.ETIQUETTES) return;
    if (!isBoxExpanded) await fetchAdGroupKeywords(adGroupId, 1);
    await callback();
  };

  useEffect(() => {
    if (!fetchedPromotionAdGroups) return;
    setPromotionKeywords(fetchedPromotionAdGroups.toJS());
  }, [fetchedPromotionAdGroups]);

  useEffect(() => {
    if (!featuresInDraft) {
      return setPromotionKeywords(
        fetchedPromotionAdGroups && fetchedPromotionAdGroups.toJS(),
      );
    }

    setPromotionKeywords(prevState =>
      Array.isArray(prevState)
        ? [...prevState, featuresInDraft?.toJS()]
        : [featuresInDraft?.toJS()],
    );
  }, [featuresInDraft]);

  return {
    promotionKeywords,
    fetchExpandedAdGroupChildren,
  };
};
export default useAdGroupBox;
