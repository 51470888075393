import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const initLabelsBegin = () => ({
  type: promotionConst.INIT_LABELS_BEGIN,
});

export const initLabelsSuccess = () => ({
  type: promotionConst.INIT_LABELS_SUCCESS,
});

export const initLabelsFailed = () => ({
  type: promotionConst.INIT_LABELS_FAILED,
});

export const initLabelsAction = (promotionId, accountId) => async dispatch => {
  dispatch(initLabelsBegin());
  try {
    await axios.post(
      `/ga/promotion_structure/${accountId}/${promotionId}/init_labels/`,
    );
    dispatch(initLabelsSuccess());
  } catch (error) {
    dispatch(initLabelsFailed());
    return handleError(error, 'Init label action');
  }
};
