import axios from 'axios';
import queryString from 'query-string';
import { skuConst } from 'store/constants/skuConst';
import { handleError } from 'utils';

export const fetchFilteredAdGroupsBySimilarityBegin = () => ({
  type: skuConst.FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_BEGIN,
});

export const fetchFilteredAdGroupsBySimilaritySuccess = filteredAdGroupsBySimilarity => ({
  type: skuConst.FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_SUCCESS,
  filteredAdGroupsBySimilarity,
});

export const fetchFilteredAdGroupsBySimilarityFailed = () => ({
  type: skuConst.FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_FAILED,
});

export const fetchFilteredAdGroupsBySimilarityAction = (
  promotionId,
  query,
  excludedAdGroups,
) => async dispatch => {
  const stringifyQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  dispatch(fetchFilteredAdGroupsBySimilarityBegin());

  try {
    const {
      data,
    } = await axios.post(
      `/ga/sku/${promotionId}/filter_by_product_names/${
        stringifyQuery && `?${stringifyQuery}`
      }`,
      { exclude_ad_groups: excludedAdGroups },
    );
    dispatch(fetchFilteredAdGroupsBySimilaritySuccess(data.results));
    return {
      hasNext: !!data.next,
      hasPrevious: !!data.previous,
      resultsLength: data.results.length,
      count: data.count,
    };
  } catch (error) {
    dispatch(fetchFilteredAdGroupsBySimilarityFailed());
    return handleError(error);
  }
};
