import { Map } from 'immutable';
import { modalConst } from 'store/constants/modalConst';

const initialState = Map({
  modalType: null,
  modalProps: Map({ isModalOpen: false }),
  uploadedFileType: 'ETA',
});
const hideModal = () => initialState;

const showModal = (state, { modalType, modalProps, uploadedFileType }) =>
  state.mergeDeep(
    Map({
      modalType,
      modalProps: Map({ ...modalProps }),
      uploadedFileType,
    }),
  );

export const modalReducer = (state = initialState, action) =>
  ((
    {
      [modalConst.SHOW_MODAL]: showModal,
      [modalConst.HIDE_MODAL]: hideModal,
    }[action.type] || (fn => fn)
  )(state, action));
