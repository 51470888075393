import PropTypes from 'prop-types';

import {
  FilterCustomInput,
  FilterIcon,
  FilterInputWrapper,
} from './FilterInput.styled';

const FilterInput = ({ height, placeholder, onChange }) => (
  <FilterInputWrapper height={height}>
    <FilterIcon />
    <FilterCustomInput
      placeholder={placeholder}
      onChange={event => onChange(event.target.value)}
    />
  </FilterInputWrapper>
);
FilterInput.propTypes = {
  height: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default FilterInput;
