import { ReactComponent as MagnifierIcon } from 'media/svg/icons/magnifier.svg';
import styled from 'styled-components';

export const TileBarOptionsWrapper = styled.div`
  position: absolute;
  top: -30px;
  z-index: 3;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  background: white;
  border-radius: 4px;
  ${({ showSearch }) => !showSearch && `width: 6.145vw;`}
  height: 4.085vh;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
`;

export const TileBarOptionsTriangle = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  margin: auto;
  border-width: 1.25vw 0.894vw 0 0.894vw;
  border-color: white transparent transparent transparent;
  line-height: 0px;
`;

export const OptionButton = styled.button`
  width: 3.268vh;
  height: 3.268vh;
  border: 1px solid ${({ theme }) => theme.pallete.primaryDisabled};
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset;
  transition: 0.6s;

  path {
    fill: ${({ theme }) => theme.pallete.primary};
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &:hover {
    background: ${({ theme }) => theme.pallete.primary};
    path {
      fill: white;
      stroke: white;
    }
  }
`;

export const CustomMagnifierIcon = styled(MagnifierIcon)`
  path {
    fill: white;
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.pallete.primary};
      stroke: white;
    }
  }
`;
