import { createSelector } from 'reselect';

export const getSelectedPromotion = createSelector(
  state => state.promotions,
  promotions => promotions.get('selectedPromotion'),
);

export const getSelectedPromotionId = createSelector(
  state => state.promotions,
  promotions => promotions.getIn(['selectedPromotion', 'id']),
);

export const getSelectedPromotionName = createSelector(
  state => state.promotions,
  promotions => promotions.getIn(['selectedPromotion', 'name']),
);

export const getSelectedPromotionSkuFile = createSelector(
  state => state.promotions,
  promotions => promotions.getIn(['selectedPromotion', 'sku_file']),
);

export const getSelectedPromotionProcessingSku = createSelector(
  state => state.promotions,
  promotions => promotions.get('getSelectedPromotionProcessingSku'),
);

export const getFetchedPromotionCampaigns = createSelector(
  state => state.promotions,
  promotions => promotions.get('fetchedPromotionCampaigns'),
);

export const getFetchedPromotionAdGroups = createSelector(
  state => state.promotions,
  promotions => promotions.get('fetchedPromotionAdGroups'),
);

export const getFetchedPromotionKeywordsByAdGroupIdSelector = (
  state,
  adGroupId,
) => state.promotions.getIn(['fetchedPromotionKeywords', String(adGroupId)]);

export const getFetchedPromotionKeywordsByAdGroupId = createSelector(
  getFetchedPromotionKeywordsByAdGroupIdSelector,
  keyword => keyword,
);

export const getAdGroupsListToFilter = createSelector(
  state => state.promotions,
  promotions => promotions.get('adGroupsListToFilter')?.toJS(),
);

export const getAdGroupsListToFilterLength = createSelector(
  state => state.promotions,
  promotions => promotions.get('adGroupsListToFilter')?.size,
);

export const getFilteredAdGroupsBySkuBox = createSelector(
  state => state.promotions,
  promotions => promotions.get('filteredAdGroupsBySkuBox'),
);

export const getFilteredAdGroupsBySimilarity = createSelector(
  state => state.promotions,
  promotions => promotions.get('filteredAdGroupsBySimilarity'),
);

export const getFilterProductNames = createSelector(
  state => state.promotions,
  promotions => promotions.get('filterProductNames'),
);

export const getHasMoreCampaigns = createSelector(
  state => state.promotions,
  promotions => promotions.get('hasMoreCampaigns'),
);

export const getSitelinksFileData = createSelector(
  state => state.promotions,
  promotions => promotions.get('sitelinksFileData'),
);

export const getAdContentFile = createSelector(
  [
    state => state.promotions.get('adContentFiles'),
    state => state.modal.get('uploadedFileType'),
  ],

  (adContentFiles, uploadedFileType) =>
    adContentFiles.find(({ type }) => type === uploadedFileType),
);

export const getEtaFile = createSelector(
  state => state.promotions.get('adContentFiles'),
  adContentFiles => adContentFiles?.find(({ type }) => type === 'ETA'),
);

export const getRsaFile = createSelector(
  state => state.promotions.get('adContentFiles'),
  adContentFiles => adContentFiles?.find(({ type }) => type === 'RSA'),
);
