import fileDownload from 'js-file-download';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchAdContentMatched } from 'store/actions/adContent/fetchAdContentMatched';
import { fetchAdContentFileAction } from 'store/actions/adContent/getAdContentFile';
import { getSelectedPromotionId } from 'store/selectors/promotionsSelectors';

const useContentImplementation = () => {
  const dispatch = useDispatch();
  const promotionId = useSelector(getSelectedPromotionId);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPromotionAdContentFileData = async () => {
    if (!promotionId) return;
    return dispatch(fetchAdContentFileAction(promotionId));
  };

  const downloadMatchedAdContentFile = async adContentFileId => {
    if (!adContentFileId) return toast.error('Load ad content file first!');
    const file = await dispatch(
      fetchAdContentMatched(promotionId, adContentFileId),
    );
    fileDownload(file, 'adContentMatched.xlsx');
  };

  return {
    downloadMatchedAdContentFile,
    isLoading,
    setIsLoading,
    fetchPromotionAdContentFileData,
    promotionId,
  };
};

export default useContentImplementation;
