import PropTypes from 'prop-types';
import React from 'react';

import {
  CustomStep,
  StepsWrapper,
  StructureSteps,
} from './StructuresSteps.styled';

const StructuresSteps = ({ collapseSteps, currentStep }) => (
  <StepsWrapper $collapsed={collapseSteps}>
    <StructureSteps
      $collapsed={collapseSteps}
      direction="vertical"
      current={currentStep}
    >
      <CustomStep
        $collapsed={collapseSteps}
        title={!collapseSteps && 'Load account structure'}
        icons={{ finish: 1 }}
      />
      <CustomStep
        $collapsed={collapseSteps}
        title={!collapseSteps && 'Implementation add content'}
        icons={{ finish: 2 }}
      />
      <CustomStep
        $collapsed={collapseSteps}
        title={!collapseSteps && 'Sitelinks'}
        icons={{ finish: 3 }}
      />
      <CustomStep
        $collapsed={collapseSteps}
        title={!collapseSteps && 'Summary'}
        icons={{ finish: 4 }}
      />
    </StructureSteps>
  </StepsWrapper>
);

StructuresSteps.propTypes = {
  collapseSteps: PropTypes.bool,
  currentStep: PropTypes.number,
};

export default StructuresSteps;
