import { LoadedFileComponent } from 'components/LoadedFileComponent';
import LoadFileModal from 'components/LoadFileModal';

import useImportSitelinksFile from './useImportSitelinksFile';

const ImportSitelinksFileModal = () => {
  const {
    sendSitelinkFile,
    showUploadField,
    setShowUploadField,
    sitelinkFileData,
  } = useImportSitelinksFile();

  return (
    <LoadFileModal
      onDropAction={sendSitelinkFile}
      loadedFileComponent={
        <LoadedFileComponent
          headerMessage={`Loaded sitelinks file: `}
          contentMessage={sitelinkFileData?.file}
          onButtonClick={() => setShowUploadField(prevState => !prevState)}
          buttonText={`Load new sitelinks file`}
        />
      }
      showUploadField={showUploadField}
    >
      Load sitelinks file
    </LoadFileModal>
  );
};
export default ImportSitelinksFileModal;
