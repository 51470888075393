import { LogoutConfirm } from 'components/LogoutConfirm';
import { ModalWrapper } from 'components/Modal/Modal.styled';
import useEscape from 'hooks/useEscape';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/actions/modalActions/ModalVisibility';

import { LogoutModalContent } from './LogoutModal.styled';

const LogoutModal = () => {
  const dispatch = useDispatch();
  useEscape(() => dispatch(hideModal()));

  return (
    <ModalWrapper>
      <LogoutModalContent>
        <LogoutConfirm />
      </LogoutModalContent>
    </ModalWrapper>
  );
};

export default LogoutModal;
