import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

import { setSelectedPromotion } from './promotionSetters';

export const fetchPromotionStructureBegin = () => ({
  type: promotionConst.FETCH_PROMOTION_STRUCTURE_BEGIN,
});

export const fetchPromotionStructureSuccess = () => ({
  type: promotionConst.FETCH_PROMOTION_STRUCTURE_SUCCESS,
});

export const fetchPromotionStructureFailed = () => ({
  type: promotionConst.FETCH_PROMOTION_STRUCTURE_FAILED,
});

export const fetchPromotionStructureAction = (
  accountId,
  promotionId,
) => async dispatch => {
  dispatch(fetchPromotionStructureBegin());

  try {
    const { data: promotionData } = await axios.get(
      `/ga/promotion_structure/${accountId}/${promotionId}/`,
    );
    dispatch(fetchPromotionStructureSuccess());
    setSelectedPromotion(promotionData);
    return promotionData;
  } catch (error) {
    dispatch(fetchPromotionStructureFailed());
    return { error: handleError(error) };
  }
};
