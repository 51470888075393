import useCampaign from 'hooks/useCampaign';
import useSku from 'hooks/useSku';
import useToasts from 'hooks/useToasts';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchPromotionAdGroupsAction } from 'store/actions/promotionActions/batchPromotionAdGroups';
import {
  setFilteredAdGroupsBySimilarity,
  setFilterProductNames,
} from 'store/actions/promotionActions/promotionSetters';
import {
  getFilterProductNames,
  getSelectedPromotionId,
} from 'store/selectors/promotionsSelectors';

import useSkuBoxFiltration from '../useSkuBoxFiltration.hook';
import useSkuSimilarityFiltration from '../useSkuSimilarityFiltration.hook';

const useSkuFilterContent = () => {
  const dispatch = useDispatch();
  const promotionId = useSelector(getSelectedPromotionId);
  const isFilteredByProductNames = useSelector(getFilterProductNames);
  const [unselectedSkuBoxRows, setUnselectedSkuBoxRows] = useState([]);
  const [unselectedSimilarityRows, setUnselectedSimilarityRows] = useState([]);
  const [skuBoxFiltrationCount, setSkuBoxFiltrationCount] = useState(0);
  const [fetchedElemsAmount, setFetchedElemsAmount] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [resultsLength, setResultsLength] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [listSize, setListSize] = useState(0);
  const { closeSkuModal } = useSku();
  const { showFetchingToast, closeStaticToast } = useToasts();
  const { fetchAddedPromotionCampaigns } = useCampaign();
  const {
    clearFilteredAdGroupsFromSkuBox,
    fetchFilteredAdGroupsBySkuBox,
    filteredAdGroupsBySkuBox,
    removeLastFetchedSkuBoxResults,
  } = useSkuBoxFiltration();
  const {
    fetchFilteredAdGroupsBySimilarity,
    filteredAdGroupsBySimilarity,
    removeLastFetchedSimilarityResults,
    clearFilteredAdGroupsBySimilarity,
  } = useSkuSimilarityFiltration();

  const onChangeUselectedRows = (adGroupId, value, setter) => {
    if (value.indexOf(adGroupId) < 0) {
      return setter(prevState => [...prevState, adGroupId]);
    }
    setter(prevState => prevState.filter(elem => elem !== adGroupId));
  };

  const clearLastFetchedAdGroups = () =>
    !isFilteredByProductNames
      ? clearFilteredAdGroupsFromSkuBox()
      : clearFilteredAdGroupsBySimilarity();

  const confirmFiltration = async () => {
    await dispatch(
      batchPromotionAdGroupsAction(promotionId, getAllSelectedRows()),
    );
    await fetchAddedPromotionCampaigns();
    closeSkuModal();
  };

  const getAllSelectedRows = () => {
    const selectedSimilarityRows = getFilteredAdGroupsIds(
      filteredAdGroupsBySimilarity,
      unselectedSimilarityRows,
    );
    const selectedSkuBoxRows = getFilteredAdGroupsIds(
      filteredAdGroupsBySkuBox,
      unselectedSkuBoxRows,
    );
    return selectedSkuBoxRows.concat(selectedSimilarityRows);
  };

  const fetchAllAdGroups = async () => {
    setIsFetching(true);
    await clearLastFetchedAdGroups();
    let resultsLength;
    if (!isFilteredByProductNames) {
      const results = await fetchFilteredAdGroupsBySkuBox({
        limit: listSize,
      });
      ({ resultsLength } = results.resultsLength);
    } else {
      ({ resultsLength } = await fetchFilteredAdGroupsBySimilarity({
        limit: listSize,
      }));
    }
    setFetchedElemsAmount(prevState => prevState + resultsLength);
    setHasNext(false);
    setHasPrevious(false);
    setResultsLength(resultsLength);
    setIsFetching(false);
  };

  const fetchMoreAdGroups = async () => {
    showFetchingToast();
    let hasNext, hasPrevious, resultsLength;

    if (!isFilteredByProductNames) {
      ({
        hasNext,
        hasPrevious,
        resultsLength,
      } = await fetchFilteredAdGroupsBySkuBox({
        limit: 50,
        offset: fetchedElemsAmount,
      }));
    } else {
      ({
        hasNext,
        hasPrevious,
        resultsLength,
      } = await fetchFilteredAdGroupsBySimilarity({
        limit: 50,
        offset: fetchedElemsAmount,
      }));
    }
    closeStaticToast();

    if (!Number.isInteger(resultsLength)) return;

    setFetchedElemsAmount(prevState => prevState + resultsLength);
    setHasNext(hasNext);
    setHasPrevious(hasPrevious);
    setResultsLength(resultsLength);
  };

  const getFilteredAdGroupsIds = (dataSource, unselectedRows) => {
    const filteredAdGroup = dataSource
      .toJS()
      .filter(({ ad_group_id: id }) => !unselectedRows.includes(id));
    return filteredAdGroup.map(({ ad_group_id: id }) => id);
  };

  const onChangeSelection = adGroupId => {
    if (isFilteredByProductNames)
      return onChangeUselectedRows(
        adGroupId,
        unselectedSimilarityRows,
        setUnselectedSimilarityRows,
      );
    return onChangeUselectedRows(
      adGroupId,
      unselectedSkuBoxRows,
      setUnselectedSkuBoxRows,
    );
  };

  const onFiltrationTypeChange = value =>
    dispatch(setFilterProductNames(value));

  const fetchFilteredAdGroups = async () => {
    setIsFetching(true);
    let count, resultsLength;
    if (!isFilteredByProductNames) {
      ({ count, resultsLength } = await fetchFilteredAdGroupsBySkuBox({
        limit: 50,
        offset: 0,
      }));
      setSkuBoxFiltrationCount(count);
    } else {
      ({ count, resultsLength } = await fetchFilteredAdGroupsBySimilarity(
        { limit: 50, offset: 0 },
        getFilteredAdGroupsIds(filteredAdGroupsBySkuBox, unselectedSkuBoxRows),
      ));
    }
    setIsFetching(false);
    if (!Number.isInteger(resultsLength)) return;
    setListSize(count);
    setFetchedElemsAmount(resultsLength);
  };

  const removeLastFetchedAdGroups = () => {
    if (!isFilteredByProductNames)
      removeLastFetchedSkuBoxResults(resultsLength);
    else removeLastFetchedSimilarityResults(resultsLength);

    setFetchedElemsAmount(prevState => prevState - resultsLength);
    if (fetchedElemsAmount - resultsLength <= 50) setHasPrevious(false);
    setHasNext(true);
  };

  useEffect(() => {
    fetchFilteredAdGroups();
  }, []);

  useEffect(() => {
    if (!isFilteredByProductNames) {
      setListSize(skuBoxFiltrationCount);
      setUnselectedSimilarityRows([]);
      setFetchedElemsAmount(filteredAdGroupsBySkuBox.size);
      return dispatch(setFilteredAdGroupsBySimilarity([]));
    }
    fetchFilteredAdGroups();
  }, [isFilteredByProductNames]);

  return {
    confirmFiltration,
    fetchAllAdGroups,
    fetchMoreAdGroups,
    isFilteredByProductNames,
    hasNext,
    hasPrevious,
    isFetching,
    onChangeSelection,
    onFiltrationTypeChange,
    removeLastFetchedAdGroups,
    unselectedRows: isFilteredByProductNames
      ? unselectedSimilarityRows
      : unselectedSkuBoxRows,
    listSize,
  };
};

export default useSkuFilterContent;
