import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const uploadPromotionToGoogleAccountBegin = () => ({
  type: promotionConst.UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_BEGIN,
});

export const uploadPromotionToGoogleAccountSuccess = () => ({
  type: promotionConst.UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_SUCCESS,
});

export const uploadPromotionToGoogleAccountFailed = () => ({
  type: promotionConst.UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_FAILED,
});

export const uploadPromotionToGoogleAccountAction = (
  accountId,
  promotionId,
) => async dispatch => {
  dispatch(uploadPromotionToGoogleAccountBegin());
  try {
    const { data } = await axios.post(
      `/ga/promotion_structure/${accountId}/${promotionId}/upload/`,
    );
    dispatch(uploadPromotionToGoogleAccountSuccess());
    return data;
  } catch (error) {
    dispatch(uploadPromotionToGoogleAccountFailed());
    return handleError(error, 'Upload promotion to Google account');
  }
};
