import { fromJS, Map } from 'immutable';
import { userConstants } from 'store/constants/userConst';

const initialState = Map({
  userAccountDetails: Map({}),
  isLoggingIn: false,
});

const setIsLoggingIn = (state, isLoggingIn) =>
  state.set('isLoggingIn', isLoggingIn);

const loggingInStarted = state => setIsLoggingIn(state, true);

const loggingInEnded = state => setIsLoggingIn(state, false);

const userLoginSuccess = state => loggingInEnded(state, true);

const setUserAccountDetails = (state, { userAccountDetails }) =>
  state.set('userAccountDetails', fromJS(userAccountDetails));

export const userReducer = (state = initialState, action) =>
  ((
    {
      [userConstants.LOGIN_BEGIN]: loggingInStarted,
      [userConstants.LOGIN_SUCCESS]: userLoginSuccess,
      [userConstants.LOGIN_FAILURE]: loggingInEnded,
      [userConstants.SET_USER_ACCOUNT_DETAILS]: setUserAccountDetails,
    }[action.type] || (fn => fn)
  )(state, action));
