import axios from 'axios';
import { adContentConst } from 'store/constants/adContentConst';
import { handleError } from 'utils';

export const deleteAdContentFileActionBegin = () => ({
  type: adContentConst.DELETE_AD_CONTENT_FILE_BEGIN,
});

export const deleteAdContentFileActionSuccess = () => ({
  type: adContentConst.DELETE_AD_CONTENT_FILE_SUCCESS,
});

export const deleteAdContentFileActionFailed = () => ({
  type: adContentConst.DELETE_AD_CONTENT_FILE_FAILED,
});

export const deleteAdContentFileAction = (
  promotionId,
  fileId,
) => async dispatch => {
  dispatch(deleteAdContentFileActionBegin());
  try {
    await axios.delete(`/ga/ad_content_file/${promotionId}/${fileId}/`);
    dispatch(deleteAdContentFileActionSuccess());
  } catch (error) {
    dispatch(deleteAdContentFileActionFailed());
    return handleError(error, 'Delete ad content file');
  }
};
