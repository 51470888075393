import { createSelector } from 'reselect';

export const getModalType = createSelector(
  state => state.modal,
  modal => modal.get('modalType'),
);

export const getModalProps = createSelector(
  state => state.modal,
  modal => modal.get('modalProps'),
);

export const getUploadedFileType = createSelector(
  state => state.modal,
  modal => modal.get('uploadedFileType'),
);
