import axios from 'axios';
import { adContentConst } from 'store/constants/adContentConst';
import { handleError } from 'utils';

const fetchAdContentMatchedBegin = () => ({
  type: adContentConst.FETCH_AD_CONTENT_MATCHED_BEGIN,
});

const fetchAdContentMatchedSuccess = () => ({
  type: adContentConst.FETCH_AD_CONTENT_MATCHED_SUCCESS,
});

const fetchAdContentMatchedFailed = () => ({
  type: adContentConst.FETCH_AD_CONTENT_MATCHED_FAILED,
});

export const fetchAdContentMatched = (
  promotionId,
  fileId,
) => async dispatch => {
  dispatch(fetchAdContentMatchedBegin);
  try {
    const {
      data,
    } = await axios.get(
      `/ga/ad_content_file/${promotionId}/${fileId}/download_matched/`,
      { responseType: 'blob' },
    );
    dispatch(fetchAdContentMatchedSuccess());
    return data;
  } catch (error) {
    dispatch(fetchAdContentMatchedFailed());
    return handleError(error, 'Fetch ad content matched file');
  }
};
