import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';

import { RowCheckbox } from './';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <RowCheckbox type="checkbox" ref={resolvedRef} {...rest} />;
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';
IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.object,
};

export default IndeterminateCheckbox;
