import axios from 'axios';
import { campaignConst } from 'store/constants/campaignsConst';
import { handleError } from 'utils';

export const addNewCampaignToPromotionBegin = () => ({
  type: campaignConst.ADD_NEW_CAMPAIGN_TO_PROMOTION_BEGIN,
});

export const addNewCampaignToPromotionSuccess = () => ({
  type: campaignConst.ADD_NEW_CAMPAIGN_TO_PROMOTION_SUCCESS,
});

export const addNewCampaignToPromotionFailed = () => ({
  type: campaignConst.ADD_NEW_CAMPAIGN_TO_PROMOTION_FAILED,
});

export const addNewCampaignToPromotion = (
  promotionId,
  campaignData,
) => async dispatch => {
  dispatch(addNewCampaignToPromotionBegin());
  try {
    const { data } = await axios.post(
      `/ga/ps_campaign/${promotionId}/`,
      campaignData,
    );
    dispatch(addNewCampaignToPromotionSuccess());
    return data;
  } catch (error) {
    dispatch(addNewCampaignToPromotionFailed());
    return handleError(error, 'Add new campaign to promotion');
  }
};
