import styled from 'styled-components';

// import { buttonVariants } from './index';

const handleButtonTextColor = ({ variant, theme }) => {
  if (variant === 'secondary') return theme.pallete.primary;
  return 'white';
};

const handleButtonBackgroundColor = ({ theme, variant }) => {
  switch (variant) {
    case 'secondary':
      return 'white';
    case 'warning':
      return theme.pallete.warningColor;
    default:
      return theme.pallete.primary;
  }
};

const handleButtonHoverColor = ({ variant, theme }) => {
  switch (variant) {
    case 'secondary':
      return theme.pallete.primaryHover;
    case 'warning':
      return theme.pallete.warningColor;
    default:
      return theme.pallete.primary;
  }
};

const handleButtonHoverBackgroundColor = ({ variant, theme }) => {
  switch (variant) {
    case 'secondary':
      return theme.pallete.primary;
    case 'warning':
      return 'white';
    default:
      return theme.pallete.primaryHover;
  }
};

export const Button = styled.button`
  border: unset;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || '0.8rem'};
  max-height: 56px;
  height: 8vh;
  width: 10.21vw;
  min-height: 25px;
  padding: 6px;
  border-radius: 4px;
  background: ${handleButtonBackgroundColor};
  color: ${handleButtonTextColor};
  border: 2px solid
    ${({ variant, theme }) =>
      variant !== 'warning'
        ? theme.pallete.primary
        : theme.pallete.warningColor};
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${handleButtonHoverColor};
    background: ${handleButtonHoverBackgroundColor};
  }
  transition: 0.6s;
  word-break: break-word;
  white-space: ${({ wrapText }) => (wrapText ? 'unset' : 'nowrap')};

  &:disabled {
    background: ${({ theme }) => theme.pallete.primaryDisabled};
    color: ${() => 'white'};
    border: 2px solid ${({ theme }) => theme.pallete.primaryDisabled};
  }
`;

export const ToolbarButton = styled.button`
  border: ${({ theme }) => `1px solid ${theme.pallete.inputsBorder}`};
  border-radius: 4px;
  height: 2.75rem;
  width: ${({ wide }) => (wide ? '7rem' : '2.75rem')};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);
  background: ${({ theme, settingsButton }) =>
    settingsButton ? theme.pallete.primary : 'white'};
  margin-left: ${({ forwardButton }) => (forwardButton ? '0.20vw' : '0.83vw')};
  transition: 0.6s;
  font-size: 0.8rem;
  line-height: 1.2rem;

  ${wide =>
    wide &&
    `display: flex;
    justify-content: center;
    align-items: center;
    `}

  ${({ settingsButton }) => settingsButton && `path{ stroke: white;}`};

  &:hover {
    background: ${({ theme, settingsButton }) =>
      settingsButton ? theme.pallete.primaryHover : theme.pallete.inputsBorder};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme, settingsButton }) =>
      settingsButton
        ? theme.pallete.inputsBorder
        : theme.pallete.primaryDisabled};

    ${({ theme, settingsButton }) =>
      settingsButton && `path{ stroke: ${theme.pallete.placeholderText};}`};
  }
`;

export const DeleteButton = styled(ToolbarButton)`
  width: 5rem;
  height: 2.75rem;
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0;
  text-align: left;

  &:hover:enabled {
    background: ${({ theme }) => theme.pallete.errorTextColor};
    color: white;
    path {
      transition: 0.8s;
      fill: white;
    }
  }

  &:disabled {
    path {
      fill: ${({ theme }) => theme.pallete.settingsHover};
    }
  }

  @media ${props => props.theme.media.notebook} {
    right: 4.458vw;
  }
`;

export const SmallActionButton = styled(Button)`
  width: 66px;
  text-overflow: unset;
  min-width: 40px;
  height: inherit;
  max-height: unset;
`;
