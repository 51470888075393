import axios from 'axios';
import { userConstants } from 'store/constants/userConst';
import { handleError } from 'utils';

const changeUserPasswordBegin = () => ({
  type: userConstants.CHANGE_PASSWORD_BEGIN,
});

const changeUserPasswordSuccess = () => ({
  type: userConstants.CHANGE_PASSWORD_SUCCESS,
});

const changeUserPasswordFailed = () => ({
  type: userConstants.CHANGE_PASSWORD_FAILED,
});

export const changePasswordAction = credentials => async dispatch => {
  dispatch(changeUserPasswordBegin);
  try {
    const { status } = await axios.post('/user/set_password/', credentials);
    dispatch(changeUserPasswordSuccess);
    return status;
  } catch (error) {
    dispatch(changeUserPasswordFailed);
    return handleError(error, 'Change password action');
  }
};
