import { campaignBoxTypes } from 'components/CampaignBox';
import {
  CampaignFlexButton,
  CampaignTileWrapper,
  CampaignTitle,
} from 'components/CampaignBox/CampaignBox.styled';
import CampaignTileSettings from 'components/CampaignTileSettings';
import { FeatureTileVariants } from 'components/FeatureTile';
import useFeatureTile from 'components/FeatureTile/FeatureTile.hook';
import { CollapseButtonArrow } from 'components/FeatureTile/FeatureTile.styles';
import StartStopButton from 'components/StartStopButton';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getCampaignBoxType } from 'store/selectors/helpersSelectors';

const CampaignTile = ({
  campaignData,
  campaignBoxType,
  isBoxExpanded,
  isTileSearchOpen,
  setIsTileSearchOpen,
  expandCampaignBox,
  isOptionsOpen,
  setIsOptionsOpen,
}) => {
  const { campaign, id: psCampaignId } = campaignData;

  const {
    addTileAction,
    deleteTileAction,
    isTilePaused,
    setIsTilePaused,
  } = useFeatureTile(FeatureTileVariants.campaign);
  return (
    <CampaignTileWrapper>
      <CollapseButtonArrow
        onClick={() => expandCampaignBox(campaign.id)}
        $collapsed={isBoxExpanded}
      />
      <CampaignFlexButton onClick={() => expandCampaignBox(campaign.id)}>
        <CampaignTitle data-tip={campaign.ga_name}>
          <span>{campaign.ga_name}</span>
        </CampaignTitle>
      </CampaignFlexButton>
      {campaignBoxType === campaignBoxTypes.STRUCTURE && (
        <CampaignTileSettings
          setIsTileSearchOpen={setIsTileSearchOpen}
          setIsOptionsOpen={setIsOptionsOpen}
          isOptionsOpen={isOptionsOpen}
          isTileSearchOpen={isTileSearchOpen}
          deleteTileAction={deleteTileAction}
          addTileAction={addTileAction}
          ids={{ parentId: campaign.id, psCampaignId }}
        />
      )}
      {campaignBoxType === campaignBoxTypes.ETIQUETTES && (
        <StartStopButton
          setIsPaused={setIsTilePaused}
          isPaused={isTilePaused}
        />
      )}
    </CampaignTileWrapper>
  );
};

const mapStateToProps = state => ({
  campaignBoxType: getCampaignBoxType(state),
});

CampaignTile.propTypes = {
  campaignData: PropTypes.shape({
    campaign: PropTypes.object,
    id: PropTypes.number,
  }),
  campaignBoxType: PropTypes.string,
  expandCampaignBox: PropTypes.func,
  isOptionsOpen: PropTypes.bool,
  isBoxExpanded: PropTypes.bool,
  isTileSearchOpen: PropTypes.bool,
  setIsTileSearchOpen: PropTypes.func,
  setIsOptionsOpen: PropTypes.func,
};

export default connect(mapStateToProps, null)(CampaignTile);
