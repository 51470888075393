import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const removePromotionKeywordBegin = () => ({
  type: promotionConst.REMOVE_PROMOTION_KEYWORD_BEGIN,
});

export const removePromotionKeywordSuccess = () => ({
  type: promotionConst.REMOVE_PROMOTION_KEYWORD_SUCCESS,
});

export const removePromotionKeywordFailed = () => ({
  type: promotionConst.REMOVE_PROMOTION_KEYWORD_FAILED,
});

export const removePromotionKeyword = (
  promotionId,
  keywordId,
) => async dispatch => {
  dispatch(removePromotionKeywordBegin());
  try {
    await axios.delete(`/ga/ps_keyword/${promotionId}/${keywordId}/`);
    dispatch(removePromotionKeywordSuccess());
  } catch (error) {
    dispatch(removePromotionKeywordFailed());
    return handleError(error, 'Remove promotion keyword');
  }
};
