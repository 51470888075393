import axios from 'axios';
import { sitelinkConst } from 'store/constants/sitelinksConst';
import { handleError } from 'utils';

const fetchSitelinksMatchedFileBegin = () => ({
  type: sitelinkConst.FETCH_SITELINKS_MATCHED_FILE_BEGIN,
});

const fetchSitelinksMatchedFileSuccess = () => ({
  type: sitelinkConst.FETCH_SITELINKS_MATCHED_FILE_SUCCESS,
});

const fetchSitelinksMatchedFileFailed = () => ({
  type: sitelinkConst.FETCH_SITELINKS_MATCHED_FILE_FAILED,
});

export const fetchSitelinksMatchedFile = (
  promotionId,
  fileId,
) => async dispatch => {
  dispatch(fetchSitelinksMatchedFileBegin);
  try {
    const {
      data,
    } = await axios.get(
      `/ga/sitelink_file/${promotionId}/${fileId}/download_matched/`,
      { responseType: 'blob' },
    );
    dispatch(fetchSitelinksMatchedFileSuccess());
    return data;
  } catch (error) {
    dispatch(fetchSitelinksMatchedFileFailed());
    return handleError(error, 'Download matched sitelinks');
  }
};
