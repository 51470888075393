import { useSelector } from 'react-redux';
import {
  getFilteredAdGroupsBySimilarity,
  getFilteredAdGroupsBySkuBox,
  getFilterProductNames,
} from 'store/selectors/promotionsSelectors';

const useFilteredAdgroupTable = () => {
  const filterProductNames = useSelector(getFilterProductNames);
  const filteredAdGroupsBySkuBox = useSelector(getFilteredAdGroupsBySkuBox);
  const filteredAdGroupsBySimilarity = useSelector(
    getFilteredAdGroupsBySimilarity,
  );

  return {
    dataSource: filterProductNames
      ? filteredAdGroupsBySimilarity
      : filteredAdGroupsBySkuBox,
    filterProductNames,
  };
};

export default useFilteredAdgroupTable;
