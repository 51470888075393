import axios from 'axios';
import { adContentConst } from 'store/constants/adContentConst';
import { handleError } from 'utils';

export const deletePromotionSitelinksFileBegin = () => ({
  type: adContentConst.DELETE_PROMOTION_SITELINKS_FILE_BEGIN,
});

export const deletePromotionSitelinksFileSuccess = () => ({
  type: adContentConst.DELETE_PROMOTION_SITELINKS_FILE_SUCCESS,
});

export const deletePromotionSitelinksFileFailed = () => ({
  type: adContentConst.DELETE_PROMOTION_SITELINKS_FILE_FAILED,
});

export const deletePromotionSitelinksFile = (
  promotionId,
  fileId,
) => async dispatch => {
  dispatch(deletePromotionSitelinksFileBegin());
  try {
    await axios.delete(`/ga/sitelink_file/${promotionId}/${fileId}/`);
    dispatch(deletePromotionSitelinksFileSuccess());
  } catch (error) {
    dispatch(deletePromotionSitelinksFileFailed());
    return handleError(error, 'Delete promotion sitelink file');
  }
};
