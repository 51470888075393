import axios from 'axios';
import { skuConst } from 'store/constants/skuConst';
import { handleError } from 'utils';

const options = {
  headers: {
    'Content-Type': 'multipart/form-data;',
  },
};

export const uploadSkuFileBegin = () => ({
  type: skuConst.UPDATE_SKU_FILE_BEGIN,
});

export const uploadSkuFileSuccess = () => ({
  type: skuConst.UPDATE_SKU_FILE_SUCCESS,
});

export const uploadSkuFileFailed = () => ({
  type: skuConst.UPDATE_SKU_FILE_FAILED,
});

export const uploadSkuFileAction = (
  accountId,
  promotionId,
  file,
) => async dispatch => {
  dispatch(uploadSkuFileBegin());

  try {
    const { data: uploadResponse } = await axios.patch(
      `/ga/promotion_structure/${accountId}/${promotionId}/`,
      file,
      options,
    );
    dispatch(uploadSkuFileSuccess());
    return uploadResponse;
  } catch (error) {
    dispatch(uploadSkuFileFailed());
    return { error: handleError(error) };
  }
};
