import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAdGroupLabelAction } from 'store/actions/promotionActions/addAdGroupLabel';
import { deleteAdGroupLabelAction } from 'store/actions/promotionActions/deleteAdGroupLabel';
import { getSelectedPromotionId } from 'store/selectors/promotionsSelectors';

const useCheckButtonsCells = (adGroupId, labels) => {
  const [brand, setBrand] = useState(false);
  const [generic, setGeneric] = useState(false);
  const [brandProduct, setBrandProduct] = useState(false);
  const [addedLabels, setAddedLabels] = useState([]);
  const promotionId = useSelector(getSelectedPromotionId);
  const dispatch = useDispatch();

  useEffect(() => {
    const setInitialLabelsState = () =>
      labels.forEach(({ label }) => labelsSetter(label, true));

    setInitialLabelsState();
  }, []);

  const labelsSetter = (label, value) => {
    switch (label) {
      case 'Brand':
        return setBrand(value);
      case 'Generic':
        return setGeneric(value);
      case 'Brand Product':
        return setBrandProduct(value);
    }
  };

  const onChangeLabelState = async (labelName, value) => {
    let assignedLabel = addedLabels.find(({ label }) => label === labelName);

    if (!addedLabels || !addedLabels.length) {
      assignedLabel = labels.find(({ label }) => label === labelName);
    }
    if (!value && !assignedLabel) return;

    labelsSetter(labelName, value);

    if (!value) {
      setAddedLabels(prevState =>
        prevState.filter(({ label }) => label !== labelName),
      );
      return dispatch(deleteAdGroupLabelAction(promotionId, assignedLabel.id));
    }

    const newLabel = await dispatch(
      addAdGroupLabelAction(promotionId, {
        label: labelName,
        ps_ad_group: adGroupId,
      }),
    );
    setAddedLabels(prevState => [...prevState, newLabel]);
  };

  return {
    brand,
    generic,
    brandProduct,
    onChangeLabelState,
  };
};

export default useCheckButtonsCells;
