import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const addAdGroupLabelActionBegin = () => ({
  type: promotionConst.ADD_ADGROUP_LABEL_BEGIN,
});

export const addAdGroupLabelActionSuccess = () => ({
  type: promotionConst.ADD_ADGROUP_LABEL_SUCCESS,
});

export const addAdGroupLabelActionFailed = () => ({
  type: promotionConst.ADD_ADGROUP_LABEL_FAILED,
});

export const addAdGroupLabelAction = (
  promotionId,
  adgroupData,
) => async dispatch => {
  dispatch(addAdGroupLabelActionBegin());
  try {
    const { data } = await axios.post(`/ga/ad_content_label/${promotionId}/`, {
      ...adgroupData,
    });
    dispatch(addAdGroupLabelActionSuccess());
    return data;
  } catch (error) {
    dispatch(addAdGroupLabelActionFailed());
    return handleError(error, 'Add adgroup label');
  }
};
