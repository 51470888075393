import {
  Button,
  ErrorMessage,
  InformationSpan,
  ValidatedInput,
} from 'components';
import { buttonVariants } from 'components/Button';
import { ButtonLoader } from 'components/Loader/Loader.styled';
import MainContainer from 'containers/MainContainer';
import React from 'react';

import useLoginView from './LoginView.hook';
import { LoginInputsContainer, LoginViewWrapper } from './LoginView.styled';

const LoginView = () => {
  const {
    error,
    isLoggingIn,
    isInputValid,
    signIn,
    validateEmail,
  } = useLoginView();

  return (
    <MainContainer>
      <LoginViewWrapper>
        <h1>Login</h1>
        <InformationSpan>
          A tool for automating the creation of structures for commercial
          actions <br />
          based on the existing customer campaigns.
        </InformationSpan>
        <form id="loginForm" onSubmit={signIn}>
          <LoginInputsContainer>
            <label>
              E-mail
              <ValidatedInput
                autoComplete="username"
                placeholder="Insert your e-mail"
                name="email"
                required
                onChange={validateEmail}
                isValid={isInputValid}
              />
            </label>
            <label>
              Password
              <input
                autoComplete="current-password"
                name="password"
                placeholder="Insert your password"
                type="password"
                required
              />
            </label>
            {error && (
              <ErrorMessage>Oh. There was something wrong :(</ErrorMessage>
            )}
          </LoginInputsContainer>
        </form>
        <Button type="submit" form="loginForm" variant={buttonVariants.PRIMARY}>
          {isLoggingIn ? <ButtonLoader /> : 'Login'}
        </Button>
      </LoginViewWrapper>
    </MainContainer>
  );
};

export default LoginView;
