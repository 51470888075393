import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import sizeMe from 'react-sizeme';

import { DropdownItem, DropdownList } from './Dropdown.styled';
import useDropdown from './useDropdown';

const Dropdown = ({
  onClickAction,
  dataSource,
  loadMoreAction,
  setShowDropdown,
  fetchingData,
}) => {
  const message = fetchingData
    ? 'Loading...'
    : 'All available features has been added...';
  const { dropdownItemRef } = useDropdown(setShowDropdown);
  const [page, setPage] = useState(1);

  const fetchMoreHints = () => {
    const nextPage = page + 1;
    loadMoreAction(nextPage);
    setPage(nextPage);
  };

  if (dataSource && dataSource?.results?.length) {
    const { results, next } = dataSource;

    return (
      <DropdownList id="dropdownListWrapper">
        <InfiniteScroll
          dataLength={dataSource.results.length}
          hasMore={!!next}
          next={fetchMoreHints}
          scrollableTarget="dropdownListWrapper"
        >
          {results.map(
            ({ ga_name, id, ga_text, ga_id, ad_group_ga_name, ad_group }) => (
              <DropdownItem
                onMouseDown={() => onClickAction({ id, adGroupId: ad_group })}
                key={`${ga_id}_${id}`}
                ref={dropdownItemRef}
              >
                {ad_group_ga_name && `[ ${ad_group_ga_name} ] `}
                {`${ga_name || ga_text}`}
              </DropdownItem>
            ),
          )}
        </InfiniteScroll>
      </DropdownList>
    );
  }
  return (
    <DropdownList>
      <DropdownItem>{message}</DropdownItem>
    </DropdownList>
  );
};

Dropdown.propTypes = {
  dataSource: PropTypes.object,
  fetchingData: PropTypes.bool,
  onClickAction: PropTypes.func,
  loadMoreAction: PropTypes.func,
  setShowDropdown: PropTypes.func,
};

export default sizeMe({ monitorHeight: true, refreshMode: 'debounce' })(
  Dropdown,
);
