export const getNewAdGroupData = (psParentId, parentId) => ({
  [psParentId]: {
    ad_group: {
      campaign: parentId,
      ga_name: '',
      ga_id: null,
    },
    id: null,
  },
});

export const getNewKeywordData = (psParentId, parentId) => ({
  [psParentId]: {
    keyword: {
      ad_group: parentId,
      ga_text: '',
      ga_id: null,
    },
    id: null,
  },
});
