import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  clearHistoryStep,
  setHistoryStepIndex,
} from 'store/actions/historyActions/historySetters';
import { getHistorySteps } from 'store/selectors/historySelectors';
import { getSelectedPromotion } from 'store/selectors/promotionsSelectors';

const useAccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const promotionDetails = useSelector(getSelectedPromotion);
  const historySteps = useSelector(getHistorySteps);

  const checkPromotionDetails = () => {
    if (promotionDetails.size) return;
    history.push('/account');
  };

  useEffect(() => {
    checkPromotionDetails();
  }, []);

  useEffect(() => {
    if (!historySteps.length) return;
    dispatch(clearHistoryStep());
    dispatch(setHistoryStepIndex(0));
  }, [promotionDetails]);
};

export default useAccess;
