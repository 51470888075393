import axios from 'axios';
import queryString from 'query-string';
import { skuConst } from 'store/constants/skuConst';
import { handleError } from 'utils';

export const fetchFilteredAdGroupsBySkuBoxBegin = () => ({
  type: skuConst.FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_BEGIN,
});

export const fetchFilteredAdGroupsBySkuBoxSuccess = filteredAdGroupsBySkuBox => ({
  type: skuConst.FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_SUCCESS,
  filteredAdGroupsBySkuBox,
});

export const fetchFilteredAdGroupsBySkuBoxFailed = () => ({
  type: skuConst.FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_FAILED,
});

export const fetchFilteredAdGroupsBySkuBoxAction = (
  promotionId,
  query,
) => async dispatch => {
  const stringifyQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });
  dispatch(fetchFilteredAdGroupsBySkuBoxBegin());

  try {
    const { data } = await axios.post(
      `/ga/sku/${promotionId}/filter_by_box/${
        stringifyQuery && `?${stringifyQuery}`
      }`,
    );
    dispatch(fetchFilteredAdGroupsBySkuBoxSuccess(data.results));
    return {
      hasNext: !!data.next,
      hasPrevious: !!data.previous,
      resultsLength: data.results.length,
      count: data.count,
    };
  } catch (error) {
    dispatch(fetchFilteredAdGroupsBySkuBoxFailed());
    return handleError(error, 'Fetch filtered ad groups by SKU');
  }
};
