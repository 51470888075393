import {
  EllipsisBeginSpan,
  StickyListHeader,
} from 'components/TableItems/TableItems.styled';
import useAdGroupsData from 'hooks/useAdGroupsData';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { ScrollSyncPane } from 'react-scroll-sync';
import { getHasNext, getSearchQuery } from 'store/selectors/helpersSelectors';
import { getAdGroupsListToFilter } from 'store/selectors/promotionsSelectors';

import {
  SitelinksTableCell,
  SitelinksTableRow,
  StaticDataConatiner,
} from './SitelinksTable.styled';

const SitelinksStaticTable = ({
  fetchedAdGroups,
  searchQuery,
  storeHasNext,
}) => {
  const staticColumns = ['Campaign', 'Ad group'];
  const { fetchAdGroupsData } = useAdGroupsData();
  const [page, setPage] = useState(1);

  const fetchAdGroupsAction = async currentPage => {
    await fetchAdGroupsData(
      { page: currentPage, search: searchQuery },
      { showToast: true },
    );
    setPage(currentPage);
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  if (!fetchedAdGroups) return;

  return (
    <StaticDataConatiner>
      <ScrollSyncPane>
        <StickyListHeader>
          {staticColumns.map(columnName => (
            <SitelinksTableCell key={`staticColumn ${columnName}`}>
              {columnName}
            </SitelinksTableCell>
          ))}
        </StickyListHeader>
        <InfiniteScroll
          loader={null}
          dataLength={fetchedAdGroups.length || 0}
          hasMore={storeHasNext}
          next={() => fetchAdGroupsAction(page + 1)}
          scrollableTarget={'infiniteScrollTrigger'}
        >
          {fetchedAdGroups.map(({ campaign_name, ad_group_name, id }) => (
            <SitelinksTableRow key={`staticRow_${id}`}>
              <SitelinksTableCell>
                <EllipsisBeginSpan data-tip={campaign_name}>
                  &#x200E;{campaign_name}&#x200E;
                </EllipsisBeginSpan>
              </SitelinksTableCell>
              <SitelinksTableCell>
                <EllipsisBeginSpan data-tip={ad_group_name}>
                  &#x200E;{ad_group_name}&#x200E;
                </EllipsisBeginSpan>
              </SitelinksTableCell>
            </SitelinksTableRow>
          ))}
        </InfiniteScroll>
      </ScrollSyncPane>
    </StaticDataConatiner>
  );
};

const mapStateToProps = state => ({
  fetchedAdGroups: getAdGroupsListToFilter(state),
  searchQuery: getSearchQuery(state),
  storeHasNext: getHasNext(state),
});

SitelinksStaticTable.propTypes = {
  hasNext: PropTypes.bool,
  searchQuery: PropTypes.string,
  storeHasNext: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  fetchedAdGroups: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.number,
      ad_group: PropTypes.number,
      ad_group_name: PropTypes.string,
      campaign_name: PropTypes.string,
      ad_content_labels: PropTypes.array,
      id: PropTypes.number,
      sitelink_labels: PropTypes.array,
    }),
  ),
  fetchAdGroupsData: PropTypes.func,
};

export default connect(mapStateToProps, null)(SitelinksStaticTable);
