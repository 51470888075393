import 'rc-steps/assets/index.css';

import Steps, { Step } from 'rc-steps';
import styled from 'styled-components';

export const StepsWrapper = styled.div`
  width: ${({ $collapsed }) => ($collapsed ? ' 5.208vw' : '33.75vw')};
  height: inherit;
  transition: 0.6s;
  position: relative;
  background: white;
`;

export const StructureSteps = styled(Steps)`
  top: 19.9vh;
  right: ${({ $collapsed }) => ($collapsed ? '-30%' : '-7.45%')};
  ${({ $collapsed }) => $collapsed && 'width: 3.125vw;'}
  position: absolute;
  transition: 0.6s;
`;

export const CustomStep = styled(Step)`
  z-index: 1;

  &:not(:last-child) {
    margin-bottom: 10.53vh;
  }

  & .rc-steps-item-tail {
    display: none;
  }

  & .rc-steps-item-icon {
    min-width: 3.125vw;
    min-height: 3.125vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.125vw;
    width: 3.125vw;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin:unset;
    border: ${({ theme }) => `2px solid ${theme.pallete.placeholderText}`};
  }

  & .rc-steps-item-icon .rc-steps-icon {
      color: ${({ theme }) => theme.pallete.placeholderText};
    }
  }

  & .rc-steps-item-content {
    height: 6.128vh;
    display: flex;
    align-items: center;
    min-height: unset;
  }

  & .rc-steps-item-title {
    font-size: 2.45vh;
    font-weight: 400;
    line-height: 3.67vh;
    letter-spacing: 0em;
    text-align: left;
    padding-left: ${({ $collapsed }) => ($collapsed ? 'unset' : ' 1.64vw')};
    padding-right: unset;
    transition: 0.6;
  }

  & .rc-steps-item-container {
    display: flex;
    align-items: basline;
  }

  &.rc-steps-item-process {

    & .rc-steps-item-icon {
      background: white;
      border: ${({ theme }) => ` 2px solid ${theme.pallete.primary}`};
    }

    & .rc-steps-item-title, .rc-steps-icon {
      color: ${({ theme }) => theme.pallete.primary};
    }
  }

  &.rc-steps-item-finish .rc-steps-item-icon  {
    background: ${({ theme }) => theme.pallete.successBackgroundColor};
    border: ${({ theme }) => ` 2px solid ${theme.pallete.successBorderColor}`};
    & .rc-steps-item-title, .rc-steps-icon {
      color: ${({ theme }) => theme.pallete.successBorderColor};
    }
  }
`;
