import { ReactComponent as ArrowDash } from 'media/svg/icons/arrowDash.svg';
import { ReactComponent as Dots } from 'media/svg/icons/dots.svg';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const FeatureContentContainer = styled.div`
  display: flex;
`;

export const SettingsButtonWrapper = styled.div`
  padding: unset;
  position: relative;
  display: flex;
  justify-content: ${({ isTileSearchOpen }) =>
    isTileSearchOpen ? 'flex-end' : 'center'};
`;

export const FlexButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: unset;
`;

export const CollapseButtonArrow = styled(ArrowDash)`
  width: 0.625vw;
  min-width: 12px;
  transition: 0.8s;
  transform: ${({ $collapsed }) =>
    $collapsed ? ' rotate(180deg);' : ' rotate(0deg);'};
`;

export const SettingsDots = styled(Dots)`
  height: 3.75px;
  width: 16.25px;
  color: ${({ theme }) => theme.pallete.primary};
`;

export const TileTooltip = styled(ReactTooltip)`
  & .type-dark.place-top {
    font-size: 0.6rem;
    line-height: 0.9rem;
    letter-spacing: 0;
    text-align: center;
  }
`;

export const TileTitleInput = styled.input`
  border-radius: 4px;
  height: 60px;
  width: 350px;
  border: unset;
  font-size: 0.7rem;
  text-align: center;

  @media ${props => props.theme.media.notebook} {
    width: 180px;
    height: 50px;
  }

  @media ${props => props.theme.media.desktop} {
    width: 250px;
    height: 50px;
  }
`;

export const TileTitle = styled.div`
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  height: inherit;
  width: inherit;

  span {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1rem;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    margin: auto 0;
    padding: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
`;
export const TileBlock = styled.div`
  padding: 0 15px;
  min-height: 30px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  width: 350px;
  height: 60px;
  justify-content: center;

  @media ${props => props.theme.media.notebook} {
    width: 180px;
    height: 50px;

    span {
      width: 180px;
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }

  @media ${props => props.theme.media.desktop} {
    width: 250px;
    height: 50px;
  }
`;

export const FeatureTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  padding-bottom: 12px;

  ${({ dropdownHeight, isTileSearchOpen }) =>
    isTileSearchOpen && `min-height: calc(${dropdownHeight}px + 40px)`};
`;

export const GridTilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, max-content));
  justify-content: center;
  padding: ${({ withPadding }) => (withPadding ? '2rem 0' : 'unset')};

  @media ${props => props.theme.media.notebook} {
    grid-template-columns: repeat(auto-fit, minmax(260px, max-content));
  }

  @media ${props => props.theme.media.desktop} {
    grid-template-columns: repeat(auto-fit, minmax(330px, max-content));
  }
`;
