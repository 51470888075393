import { BrandLogoWrapper, LogoImage } from './BrandLogo.styled';

const BrandLogo = () => {
  const image = process.env.REACT_APP_LOGO;

  return (
    <BrandLogoWrapper>
      {image ? (
        <LogoImage src={image} alt="tool logo" />
      ) : (
        <span>Perfomance Media Tool</span>
      )}
    </BrandLogoWrapper>
  );
};

export default BrandLogo;
