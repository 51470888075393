import axios from 'axios';
import queryString from 'query-string';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

const fetchPromotionCampaignsBegin = () => ({
  type: promotionConst.FETCH_PROMOTION_CAMPAIGNS_BEGIN,
});

const fetchPromotionCampaignsSuccess = (fetchedPromotionsCampaigns, query) => ({
  type: promotionConst.FETCH_PROMOTION_CAMPAIGNS_SUCCESS,
  fetchedPromotionsCampaigns,
  query,
});

const fetchPromotionCampaignsFailed = () => ({
  type: promotionConst.FETCH_PROMOTION_CAMPAIGNS_FAILED,
});

export const fetchPromotionCampaigns = (
  promotionId,
  query,
) => async dispatch => {
  const stringifiedQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  dispatch(fetchPromotionCampaignsBegin);
  try {
    const { data: fetchedCampaigns } = await axios.get(
      `/ga/ps_campaign/${promotionId}/${
        stringifiedQuery && `?${stringifiedQuery}`
      }`,
    );
    dispatch(fetchPromotionCampaignsSuccess(fetchedCampaigns.results, query));
    return fetchedCampaigns;
  } catch (error) {
    dispatch(fetchPromotionCampaignsFailed());
    return handleError(error, 'Fetch promotion campaigns');
  }
};
