import { promotionConst } from 'store/constants/promotionConst';
export const setSelectedPromotion = promotion => ({
  type: promotionConst.SET_SELECTED_PROMOTION,
  promotion,
});

export const setAdGroupsListToFilter = adGroups => ({
  type: promotionConst.SET_AD_GROUPS_LIST_TO_FILTER,
  adGroups,
});

export const updateAdGroupsListToFilter = adGroups => ({
  type: promotionConst.UPDATE_AD_GROUPS_LIST_TO_FILTER,
  adGroups,
});

export const clearAdGroupsListToFilter = () => ({
  type: promotionConst.CLEAR_AD_GROUPS_LIST_TO_FILTER,
});

export const setFetchedPromotionAdGroups = (
  fetchedPromotionAdGroups,
  campaignId,
) => ({
  type: promotionConst.SET_FETCHED_PROMOTION_AD_GROUPS,
  fetchedPromotionAdGroups,
  campaignId,
});

export const setFilteredAdGroupsBySkuBox = filteredAdGroupsBySkuBox => ({
  type: promotionConst.SET_FILTERED_AD_GROUPS_BY_SKU_BOX,
  filteredAdGroupsBySkuBox,
});

export const setFilteredAdGroupsBySimilarity = filteredAdGroupsBySimilarity => ({
  type: promotionConst.SET_FILTERED_AD_GROUPS_BY_SIMILARITY,
  filteredAdGroupsBySimilarity,
});

export const setFilterProductNames = filterProductNames => ({
  type: promotionConst.SET_FILTER_PRODUCT_NAMES,
  filterProductNames,
});

export const setHasMoreCampaigns = hasMoreCampaigns => ({
  type: promotionConst.SET_HAS_MORE_CAMPAIGNS,
  hasMoreCampaigns,
});
