export const breakpoints = {
  sm: 0,
  md: 768,
  lg: 992,
  xl: 1400,
};

const generateMediaQuery = (minBreakpoint, maxBreakpoint) =>
  `screen and (min-width: ${minBreakpoint}px)${
    maxBreakpoint && `and (max-width: ${maxBreakpoint}px)`
  }`;

export const theme = {
  pallete: {
    primary: '#0a2756',
    primaryHover: '#00ffb4',
    primaryDisabled: '#D9EEFF',
    inputsBorder: '#DAE4F2',
    placeholderText: '#B1BBCA',
    errorTextColor: '#DB191C',
    labelColor: '#222C3B',
    tableRowsHighlight: '#F0F7FD',
    dropdownTextColor: '#013159',
    logoutColor: '#B71121',
    importantTextColor: '#0B3457',
    warningColor: '#FFA807',
    warningHover: '#B76B03',
    errorButtonColor: '#FF4F4F',
    successBorderColor: '#359921',
    successBackgroundColor: '#D4FFBF',
    settingsHover: '#425673',
    keywordColor: '#FFE8AC',
    keywordTextColor: '#DB8805',
    checkedItem: '#8FE573',
    campaignTileText: '#233BD4',
    campaignTile: '#D6DDFC',
    tableRowHover: '#A9D8FF',
  },
  media: {
    bigDesktop: generateMediaQuery(breakpoints.xl, 9999),
    desktop: generateMediaQuery(breakpoints.lg, breakpoints.xl),
    notebook: generateMediaQuery(breakpoints.md, breakpoints.lg),
    tablet: generateMediaQuery(breakpoints.sm, breakpoints.md),
    smartphone: generateMediaQuery(0, breakpoints.sm),
  },
  customTableFieldSize: '38%',
};
