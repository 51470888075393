import axios from 'axios';
import { templatesConst } from 'store/constants/templatesConst';
import { handleError } from 'utils';

export const downloadTemplateFileBegin = () => ({
  type: templatesConst.DOWNLOAD_TEMPLATE_FILE_BEGIN,
});

export const downloadTemplateFileSuccess = () => ({
  type: templatesConst.DOWNLOAD_TEMPLATE_FILE_SUCCESS,
});

export const downloadTemplateFileFailed = () => ({
  type: templatesConst.DOWNLOAD_TEMPLATE_FILE_FAILED,
});

export const downloadTemplateFile = fileType => async dispatch => {
  dispatch(downloadTemplateFileBegin());

  try {
    const { data } = await axios.get(`/ga/template/${fileType}/`, {
      responseType: 'blob',
    });
    dispatch(downloadTemplateFileSuccess());
    return data;
  } catch (error) {
    dispatch(downloadTemplateFileFailed());
    return handleError(error, 'Download file template');
  }
};
