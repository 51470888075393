import { createSelector } from 'reselect';

export const getUserAccountDetails = createSelector(
  state => state.user,
  user => user.get('userAccountDetails').toJS(),
);

export const getUserId = createSelector(
  state => state.user,
  user => user.getIn(['userAccountDetails', 'userId']),
);

export const getIsLoggingIn = createSelector(
  state => state.user,
  user => user.get('isLoggingIn'),
);
