import { campaignBoxTypes } from 'components/CampaignBox/CampaignBox.variants';
import StartStopButton from 'components/StartStopButton';
import TileOptionBar from 'components/TileOptionBar/TileOptionBar';
import TileSearchInput from 'components/TileSearchInput';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getCampaignBoxType } from 'store/selectors/helpersSelectors';

import useFeatureTile from './FeatureTile.hook';
import {
  CollapseButtonArrow,
  FeatureContentContainer,
  FeatureTileWrapper,
  FlexButton,
  SettingsButtonWrapper,
  SettingsDots,
} from './FeatureTile.styles';
import { FeatureTileVariants } from './FeatureTile.variants';

const FeatureTile = ({
  campaignBoxType,
  children,
  featureBlock,
  fetchChildrenAction,
  showCollapseBtn,
  tileType,
  ids,
}) => {
  const {
    addTileAction,
    dropdownHeight,
    deleteTileAction,
    isBoxExpanded,
    setIsBoxExpanded,
    isOptionsOpen,
    setIsOptionsOpen,
    isTileSearchOpen,
    setIsTileSearchOpen,
    isTilePaused,
    setIsTilePaused,
  } = useFeatureTile(tileType);

  const { psId, parentId, featureId } = ids;
  return (
    <FeatureTileWrapper
      isTileSearchOpen={isTileSearchOpen}
      dropdownHeight={dropdownHeight}
    >
      <FeatureContentContainer>
        <FlexButton
          onClick={() =>
            psId &&
            fetchChildrenAction &&
            fetchChildrenAction(isBoxExpanded, () =>
              setIsBoxExpanded(prevState => !prevState),
            )
          }
        >
          {showCollapseBtn &&
            campaignBoxType !== campaignBoxTypes.ETIQUETTES && (
              <CollapseButtonArrow $collapsed={isBoxExpanded} />
            )}
          {featureBlock}
        </FlexButton>

        {campaignBoxType === campaignBoxTypes.STRUCTURE && (
          <SettingsButtonWrapper isTileSearchOpen={isTileSearchOpen}>
            {isTileSearchOpen && (
              <TileSearchInput
                ids={ids}
                tileType={tileType}
                placeholder={`Find ${
                  tileType === FeatureTileVariants.adGroup &&
                  FeatureTileVariants.keyword
                }`}
                setIsTileSearchOpen={setIsTileSearchOpen}
              />
            )}
            {isOptionsOpen && (
              <TileOptionBar
                addItemAction={() => addTileAction(featureId, psId)}
                deleteItemAction={() =>
                  deleteTileAction(parentId, psId, featureId)
                }
                showSearch={!(tileType === FeatureTileVariants.keyword)}
                setIsTileSearchOpen={setIsTileSearchOpen}
                setIsOptionsOpen={setIsOptionsOpen}
                showAddButton={!(tileType === FeatureTileVariants.keyword)}
              />
            )}

            <FlexButton
              disabled={isTileSearchOpen}
              onClick={() => setIsOptionsOpen(prevState => !prevState)}
            >
              <SettingsDots />
            </FlexButton>
          </SettingsButtonWrapper>
        )}
        {campaignBoxType === campaignBoxTypes.ETIQUETTES && (
          <StartStopButton
            isPaused={isTilePaused}
            setIsPaused={setIsTilePaused}
          />
        )}
      </FeatureContentContainer>
      {isBoxExpanded && children}
    </FeatureTileWrapper>
  );
};

const mapStateToProps = state => ({
  campaignBoxType: getCampaignBoxType(state),
});

FeatureTile.defaultProps = {
  showCollapseBtn: true,
  featureBlockActions: {},
};

FeatureTile.propTypes = {
  campaignBoxType: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  featureBlock: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  featureBlockActions: PropTypes.object,
  fetchChildrenAction: PropTypes.func,
  showCollapseBtn: PropTypes.bool,
  tileType: PropTypes.string,
  ids: PropTypes.object,
  isTilePaused: PropTypes.bool,
  setIsTilePaused: PropTypes.func,
};

export default connect(mapStateToProps, null)(FeatureTile);
