import useEscape from 'hooks/useEscape';
import useOutsideClick from 'hooks/useOutsideClick';
import { ReactComponent as PlusIcon } from 'media/svg/icons/plus.svg';
import { ReactComponent as TrashIconSvg } from 'media/svg/icons/trash.svg';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import {
  CustomMagnifierIcon,
  OptionButton,
  OptionsContainer,
  TileBarOptionsTriangle,
  TileBarOptionsWrapper,
} from './TileOptionBar.styled';

const TileOptionBar = ({
  addItemAction,
  deleteItemAction,
  setIsTileSearchOpen,
  setIsOptionsOpen,
  showSearch,
  showAddButton,
}) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOptionsOpen(false));
  useEscape(() => setIsOptionsOpen(false));

  return (
    <TileBarOptionsWrapper ref={wrapperRef}>
      <OptionsContainer showSearch>
        {showAddButton && (
          <OptionButton onClick={addItemAction}>
            <PlusIcon />
          </OptionButton>
        )}
        {showSearch && (
          <OptionButton onClick={setIsTileSearchOpen}>
            <CustomMagnifierIcon />
          </OptionButton>
        )}
        <OptionButton onClick={deleteItemAction}>
          <TrashIconSvg />
        </OptionButton>
      </OptionsContainer>
      <TileBarOptionsTriangle />
    </TileBarOptionsWrapper>
  );
};

TileOptionBar.defaultProps = {
  showSearch: true,
  showAddButton: true,
};

TileOptionBar.propTypes = {
  addItemAction: PropTypes.func,
  deleteItemAction: PropTypes.func,
  setIsTileSearchOpen: PropTypes.func,
  setIsOptionsOpen: PropTypes.func,
  showAddButton: PropTypes.bool,
  showSearch: PropTypes.bool,
};

export default TileOptionBar;
