import { StyledLoaderContainer } from 'components/Loader/Loader.styled';
import PropTypes from 'prop-types';

import { NoContentMessage, StyledMessageAltError } from './NoContent.styled';

const NoContent = ({ height, message }) => (
  <StyledLoaderContainer height={height}>
    <StyledMessageAltError />
    <NoContentMessage>{message ?? 'No content...'}</NoContentMessage>
  </StyledLoaderContainer>
);

NoContent.propTypes = {
  height: PropTypes.string,
  message: PropTypes.string,
};

export default NoContent;
