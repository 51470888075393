import {
  TileTitle,
  TileTitleInput,
} from 'components/FeatureTile/FeatureTile.styles';
import styled from 'styled-components';

export const AdGroupTitle = styled(TileTitle)`
  background: ${({ theme }) => theme.pallete.primaryDisabled};
  color: ${({ theme }) => theme.pallete.primary};
`;

export const AdGroupTitleInput = styled(TileTitleInput)`
  background: ${({ theme }) => theme.pallete.primaryDisabled};
  color: ${({ theme }) => theme.pallete.primary};
`;
