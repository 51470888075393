import { ReactComponent as LogoutIconSvg } from 'media/svg/icons/logout.svg';
import styled from 'styled-components';

export const UserMenuWrapper = styled.div`
  float: right;
  position: relative;
`;

export const UserMenuDropdown = styled.div`
  position: absolute;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 140%;
  right: 1%;
  background: white;
`;

export const UserMenuDropdownItem = styled.button`
  border: unset;
  width: 19vw;
  height: 5.8vh;
  background: unset;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0;
  padding: 4%;
  transition: 0.6s;
  overflow: hidden;

  color: ${({ theme, logout }) =>
    logout ? theme.pallete.logoutColor : theme.pallete.dropdownTextColor};

  &:hover {
    background: ${({ theme }) => theme.pallete.primary};
    color: white;
  }
`;

export const LogoutIcon = styled(LogoutIconSvg)`
  height: 1rem;
  margin-right: 3%;
`;
