import { FeatureTile, FeatureTileVariants } from 'components/FeatureTile';
import { TileBlock } from 'components/FeatureTile/FeatureTile.styles';
import { TileChildrenContainer } from 'containers';
import useNewFeature from 'hooks/useNewFeature';
import PropTypes from 'prop-types';
import React from 'react';

import { KeywordTitle, KeywordTitleInput } from './KeywordBox.styled';

const KeywordBox = ({ keywordData }) => {
  const { keyword, psId } = keywordData;
  const { id: keywordId, ad_group: adGroupId, ga_text } = keyword;

  const { onInputBlur, inputRef, inputValue, setInputValue } = useNewFeature(
    adGroupId,
    FeatureTileVariants.keyword,
  );

  return (
    <FeatureTile
      ids={{ psId, parentId: adGroupId, featureId: keywordId }}
      tileType={FeatureTileVariants.keyword}
      showCollapseBtn={false}
      featureBlock={
        <TileBlock data-tip={ga_text}>
          {ga_text ? (
            <KeywordTitle>
              <span>{ga_text || inputValue}</span>
            </KeywordTitle>
          ) : (
            <KeywordTitleInput
              onBlur={onInputBlur}
              ref={inputRef}
              readOnly={Boolean(ga_text)}
              onChange={e => setInputValue(e.target.value)}
              placeholder="Enter keyword"
            />
          )}
        </TileBlock>
      }
      childrenContainer={TileChildrenContainer}
    />
  );
};

KeywordBox.propTypes = {
  keywordData: PropTypes.object,
};

export default KeywordBox;
