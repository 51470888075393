import styled from 'styled-components';

export const RowCheckbox = styled.input`
  width: 16px;
  height: 16px;
  appearance: none;

  &:checked {
    background-color: ${({ theme }) => theme.pallete.primary};
    border: none;
  }
`;
