import { FeatureTileVariants } from 'components/FeatureTile';
import useAdGroups from 'hooks/useAdGroups';
import useKeywords from 'hooks/useKeywords';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDropdownHeightAction } from 'store/actions/helpersActions/helpersSetters';
import { setHistoryStep } from 'store/actions/historyActions/historySetters';

const useSearchInput = (ids, tileType) => {
  const { parentId, featureId } = ids;
  const [dataSource, setDataSource] = useState(null);
  const [searchQuery, setSearchQuery] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const {
    addAdGroupToPromotion,
    fetchAdGroupsHints,
    fetchCampaignsAdGroups,
  } = useAdGroups();
  const {
    fetchKeywordsHints,
    addKeywordToPromotion,
    fetchAdGroupKeywords,
  } = useKeywords();
  const dispatch = useDispatch();

  const fetchSearchHints = async page => {
    let data, results;
    if (tileType === FeatureTileVariants.campaign) {
      data = await fetchAdGroupsHints(searchQuery, page, parentId);
    }

    if (tileType === FeatureTileVariants.adGroup) {
      data = await fetchKeywordsHints(searchQuery, page, featureId);
    }

    if (!data) return setDataSource(null);

    results = data.results;

    if (page > 1) results = [...dataSource.results, ...data.results];

    setDataSource({ results, next: data.next });
    setFetchingData(false);
  };

  useEffect(() => {
    setFetchingData(true);
    const fetchingTimer = setTimeout(() => {
      fetchSearchHints();
    }, 600);

    return () => {
      clearTimeout(fetchingTimer);
    };
  }, [searchQuery]);

  const onClickAction = async ({ id, adGroupId }) => {
    setShowDropdown(false);
    if (tileType === FeatureTileVariants.campaign) {
      const addedAdGroupData = await addAdGroupToPromotion(adGroupId);
      dispatch(
        setHistoryStep({
          ...addedAdGroupData,
          action: 'add',
          featureType: 'adGroup',
          parentId,
        }),
      );
      return fetchCampaignsAdGroups({
        campaignId: parentId,
        addToHistory: true,
      });
    }
    const addedKeywordData = await addKeywordToPromotion(id);
    dispatch(
      setHistoryStep({
        ...addedKeywordData,
        action: 'add',
        featureType: 'keyword',
        parentId: adGroupId,
      }),
    );
    await fetchAdGroupKeywords(adGroupId, {
      addToHistory: true,
    });
  };

  const setDropdownHeight = height => {
    dispatch(setDropdownHeightAction(height));
  };

  useEffect(() => {
    setShowDropdown(true);
  }, [dataSource]);

  return {
    searchQuery,
    dataSource,
    fetchingData,
    fetchSearchHints,
    setSearchQuery,
    showDropdown,
    setShowDropdown,
    onClickAction,
    setDropdownHeight,
  };
};

export default useSearchInput;
