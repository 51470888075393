import { buttonVariants } from 'components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/actions/modalActions/ModalVisibility';

import useLogoutConfirm from './LogoutConfirm.hook';
import {
  ConfirmButtonsWrapper,
  LogoutConfirmButton,
  LogoutConfirmText,
  LogoutConfirmWrapper,
} from './LogoutConfirm.styled';

const LogoutConfirm = () => {
  const dispatch = useDispatch();
  const logout = useLogoutConfirm();

  return (
    <LogoutConfirmWrapper>
      <LogoutConfirmText>
        Are you sure you want <br />
        to logout?
      </LogoutConfirmText>
      <ConfirmButtonsWrapper>
        <LogoutConfirmButton
          variant={buttonVariants.SECONDARY}
          onClick={logout}
        >
          Yes
        </LogoutConfirmButton>
        <LogoutConfirmButton onClick={() => dispatch(hideModal())}>
          Cancel
        </LogoutConfirmButton>
      </ConfirmButtonsWrapper>
    </LogoutConfirmWrapper>
  );
};

LogoutConfirm.propTypes = {
  logout: PropTypes.func,
};

export default LogoutConfirm;
