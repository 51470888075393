import Dropdown from 'components/Dropdown/Dropdown';
import { ButtonLoader } from 'components/Loader/Loader.styled';
import useEscape from 'hooks/useEscape';
import { ReactComponent as MagnifierIcon } from 'media/svg/icons/magnifier.svg';
import React from 'react';

import useCampaignSearch from './CampaignSearch.hook';
import {
  CampaignSearchInputWrapper,
  CampaignSearchWrapper,
  IconWrapper,
  SearcherInput,
} from './CampaignSearch.styled';

const CampaignSearch = () => {
  const {
    addCampaignToPromotion,
    fetchedCampaigns,
    searchQuery,
    setSearchQuery,
    showDropdown,
    setShowDropdown,
    isLoading,
    fetchCampaignsHints,
  } = useCampaignSearch();

  useEscape(() => setShowDropdown(false));

  return (
    <CampaignSearchWrapper
      onClick={() => !isLoading && setShowDropdown(prevState => !prevState)}
    >
      <CampaignSearchInputWrapper>
        <IconWrapper>
          {isLoading ? <ButtonLoader /> : <MagnifierIcon />}
        </IconWrapper>
        <SearcherInput
          placeholder={
            isLoading ? 'Please wait...' : 'Add campaign to account structure'
          }
          disabled={isLoading}
          onChange={e => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
      </CampaignSearchInputWrapper>
      {showDropdown && (
        <Dropdown
          dataSource={fetchedCampaigns}
          loadMoreAction={fetchCampaignsHints}
          onClickAction={addCampaignToPromotion}
          setShowDropdown={setShowDropdown}
        />
      )}
    </CampaignSearchWrapper>
  );
};
export default CampaignSearch;
