import { Button } from 'components/Button';
import styled from 'styled-components';

export const LogoutConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4.9vh 3.541vw;
`;

export const LogoutConfirmText = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 3.26vh;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10%;
  color: ${({ theme }) => theme.pallete.importantTextColor};
`;

export const LogoutConfirmButton = styled(Button)`
  height: 5.7vh;
  width: 9.2vw;
  &:last-child {
    margin-left: 1vw;
  }
`;

export const ConfirmButtonsWrapper = styled.div`
  text-align: center;
`;
