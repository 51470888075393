import { Map } from 'immutable';
import { helpersConst } from 'store/constants/helpersConst';
const initialState = Map({
  dropdownHeight: null,
  campaignBoxType: 'structures',
  hasNext: false,
  isLoading: false,
  searchQuery: null,
});

const setDropdownHeight = (state, { dropdownHeight }) =>
  state.set('dropdownHeight', dropdownHeight);

const setCampaignBoxType = (state, { campaignBoxType }) =>
  state.set('campaignBoxType', campaignBoxType);

const setIsLoading = (state, { isLoading }) =>
  state.set('isLoading', isLoading);

const setHasNext = (state, { hasNext }) => state.set('hasNext', hasNext);

const setSearchQuery = (state, { searchQuery }) =>
  state.set('searchQuery', searchQuery);

export const helpersReducer = (state = initialState, action) =>
  ((
    {
      [helpersConst.SET_DROPDOWN_HEIGHT]: setDropdownHeight,
      [helpersConst.SET_CAMPAIGN_BOX_TYPE]: setCampaignBoxType,
      [helpersConst.SET_HAS_NEXT]: setHasNext,
      [helpersConst.SET_IS_LOADING]: setIsLoading,
      [helpersConst.SET_SEARCH_QUERY]: setSearchQuery,
    }[action.type] || (fn => fn)
  )(state, action));
