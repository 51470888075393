import { TrashIcon } from 'components/StructuresToolbar/StructuresToolbar.styled';
import styled from 'styled-components';

export const PromotionListContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.pallete.primary};
  max-height: 33.4vh;
  padding: 0 0.625vw 0.625vw 0.625vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
`;

export const TableWrapper = styled.div`
  display: flex;
  height: inherit;
  margin-bottom: 0.5%;
  overflow: hidden;
`;
export const CenteredDiv = styled.div`
  margin: auto;
  display: flex;
  flex-basis: 7.7%;
  justify-content: center;
`;

export const PromotionListWrapper = styled.div`
  overflow: auto;
  height: inherit;
`;

export const InvisibleListColumnName = styled.span`
  display: flex;
  flex-basis: 7.7%;
  flex-grow: 0;
`;

export const DeletePromotionIcon = styled(TrashIcon)`
  path {
    fill: ${({ theme }) => theme.pallete.primary};
  }
`;
