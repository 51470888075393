import { Button, buttonVariants } from 'components/Button';
import {
  FileNameSpan,
  FileTrayStyledIcon,
  LoadedFileWrapper,
} from 'components/ImportAdContentModal/LoadedFileComponent.styled';
import PropTypes from 'prop-types';

const LoadedFileComponent = ({
  children,
  headerMessage,
  contentMessage,
  onButtonClick,
  buttonText,
}) => (
  <LoadedFileWrapper>
    <>
      <span>{headerMessage} </span>
      <FileNameSpan>{contentMessage}</FileNameSpan>
    </>
    {children}
    <FileTrayStyledIcon />
    <Button variant={buttonVariants.PRIMARY} onClick={onButtonClick}>
      {buttonText}
    </Button>
  </LoadedFileWrapper>
);

LoadedFileComponent.propTypes = {
  children: PropTypes.object,
  headerMessage: PropTypes.string,
  contentMessage: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
};

export default LoadedFileComponent;
