import { Play as PlayIcon } from '@styled-icons/bootstrap/Play';
import { Pause as PauseIcon } from '@styled-icons/feather/Pause';
import { buttonVariants } from 'components/Button';
import { SmallActionButton } from 'components/Button/Button.styled';
import PropTypes from 'prop-types';
import React from 'react';

const StartStopButton = ({ isPaused, setIsPaused }) => (
  <SmallActionButton
    onClick={() => setIsPaused(prevState => !prevState)}
    variant={isPaused ? buttonVariants.PRIMARY : buttonVariants.WARNING}
  >
    {isPaused ? <PlayIcon width="25px" /> : <PauseIcon width="25px" />}
  </SmallActionButton>
);

StartStopButton.propTypes = {
  isPaused: PropTypes.bool,
  setIsPaused: PropTypes.func,
};

export default StartStopButton;
