import { CampaignSearch, StructuresToolbar } from 'components';
import { CampaignBoxWrapper } from 'components/CampaignBox';
import { StructuresContainer } from 'containers';
import {
  CampaignSearchWrapper,
  ContainerTitle,
  PromotionTitle,
} from 'containers/StructuresContainer/StrucuresContainer.styled';
import useAccess from 'hooks/useAccess';
import React from 'react';

import useStructureView from './useStructureView.hook';

const StructuresView = () => {
  useAccess();
  const {
    downloadPromotionStructure,
    promotionName,
    skuFile,
  } = useStructureView();
  return (
    <StructuresContainer currentStep={0}>
      <StructuresToolbar
        showUndoRedo
        saveBtnAction={downloadPromotionStructure}
        isSaveBtnDisabled={!skuFile}
      />
      <ContainerTitle>
        Promotion structure:
        <PromotionTitle>{promotionName}</PromotionTitle>
      </ContainerTitle>
      <CampaignSearchWrapper>
        <CampaignSearch />
      </CampaignSearchWrapper>
      <CampaignBoxWrapper />
    </StructuresContainer>
  );
};

export default StructuresView;
