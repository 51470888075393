import { campaignBoxTypes } from 'components/CampaignBox/CampaignBox.variants';
import fileDownload from 'js-file-download';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setCampaignBoxTypeAction } from 'store/actions/helpersActions/helpersSetters';
import { downloadPromotionStructureAction } from 'store/actions/skuFilesActions/downloadPromotionStructure';
import {
  getSelectedPromotionId,
  getSelectedPromotionName,
  getSelectedPromotionSkuFile,
} from 'store/selectors/promotionsSelectors';

const useStructureView = () => {
  const dispatch = useDispatch();
  const promotionId = useSelector(getSelectedPromotionId);
  const promotionName = useSelector(getSelectedPromotionName);
  const skuFile = useSelector(getSelectedPromotionSkuFile);

  useEffect(() => {
    dispatch(setCampaignBoxTypeAction(campaignBoxTypes.STRUCTURE));
  }, []);

  const downloadPromotionStructure = async () => {
    const file = await dispatch(downloadPromotionStructureAction(promotionId));
    if (!file) return toast.error('To download data, load SKU file first...');
    fileDownload(file, 'skuData.xlsx');
  };

  return { downloadPromotionStructure, promotionName, skuFile };
};

export default useStructureView;
