import { useDispatch, useSelector } from 'react-redux';
import { setFilteredAdGroupsBySkuBox } from 'store/actions/promotionActions/promotionSetters';
import { fetchFilteredAdGroupsBySkuBoxAction } from 'store/actions/skuFilesActions/fetchFilteredAdGroupBySkuBox';
import {
  getFilteredAdGroupsBySkuBox,
  getSelectedPromotionId,
} from 'store/selectors/promotionsSelectors';

const useSkuBoxFiltration = () => {
  const dispatch = useDispatch();
  const promotionId = useSelector(getSelectedPromotionId);
  const filteredAdGroupsBySkuBox = useSelector(getFilteredAdGroupsBySkuBox);

  const clearFilteredAdGroupsFromSkuBox = () =>
    dispatch(setFilteredAdGroupsBySkuBox([]));

  const fetchFilteredAdGroupsBySkuBox = async query =>
    dispatch(fetchFilteredAdGroupsBySkuBoxAction(promotionId, query));

  const removeLastFetchedSkuBoxResults = amount =>
    dispatch(
      setFilteredAdGroupsBySkuBox(filteredAdGroupsBySkuBox.slice(0, -amount)),
    );

  return {
    clearFilteredAdGroupsFromSkuBox,
    fetchFilteredAdGroupsBySkuBox,
    filteredAdGroupsBySkuBox,
    removeLastFetchedSkuBoxResults,
  };
};
export default useSkuBoxFiltration;
