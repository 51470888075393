import { useDispatch } from 'react-redux';
import { hideModal } from 'store/actions/modalActions/ModalVisibility';

const useLogoutConfirm = () => {
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem('jwt');
    window.location.href = '/';
    dispatch(hideModal());
  };
  return logout;
};

export default useLogoutConfirm;
