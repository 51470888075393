import { CampaignBox, NoContent } from 'components';
import {
  GridTilesWrapper,
  TileTooltip,
} from 'components/FeatureTile/FeatureTile.styles';
import {
  Loader,
  StyledLoaderContainer,
  StyledLoaderMessage,
} from 'components/Loader';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withTheme } from 'styled-components';

import useCampaignBoxWrapper from './CampaignBoxWrapper.hook';

const CampaignBoxWrapper = ({ theme, withPadding, useLoadStateFromStore }) => {
  const {
    campaigns,
    fetchMoreCampaigns,
    hasNext,
    isLoading,
    loadStateFromStore,
  } = useCampaignBoxWrapper();

  if (useLoadStateFromStore ? loadStateFromStore : isLoading) {
    return (
      <StyledLoaderContainer height="60vh">
        <Loader />
        <StyledLoaderMessage>
          Fetching promotion structure...
        </StyledLoaderMessage>
      </StyledLoaderContainer>
    );
  }

  return (
    <InfiniteScroll
      loader={null}
      dataLength={campaigns?.length}
      next={fetchMoreCampaigns}
      hasMore={hasNext}
    >
      <GridTilesWrapper withPadding={withPadding}>
        {!(campaigns.length > 0) ? (
          <StyledLoaderContainer height="60vh">
            <NoContent />
          </StyledLoaderContainer>
        ) : (
          campaigns.map(campaignData => (
            <CampaignBox campaignData={campaignData} key={campaignData.id} />
          ))
        )}
        {campaigns.length > 0 && (
          <TileTooltip
            textColor={theme.pallete.primary}
            backgroundColor={theme.pallete.primaryDisabled}
          />
        )}
      </GridTilesWrapper>
    </InfiniteScroll>
  );
};

CampaignBoxWrapper.defaultProps = {
  withPadding: true,
};

CampaignBoxWrapper.propTypes = {
  theme: PropTypes.object,
  withPadding: PropTypes.bool,
  useLoadStateFromStore: PropTypes.bool,
};

export default memo(withTheme(CampaignBoxWrapper));
