import { createSelector } from 'reselect';

export const getHistorySteps = createSelector(
  state => state.history,
  history => history.get('historySteps').toJS(),
);

export const getHistoryStepIndex = createSelector(
  state => state.history,
  history => history.get('historyStepIndex'),
);
