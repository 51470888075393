import SitelinkCheck from 'components/SitelinkCheck';
import { StickyListHeader } from 'components/TableItems/TableItems.styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScrollSyncPane } from 'react-scroll-sync';
import { getAdGroupsListToFilter } from 'store/selectors/promotionsSelectors';

import {
  DynamicDataContainer,
  DynamicScrollableDiv,
  DynamicTableWrapper,
  SitelinksCheckCell,
  SitelinksTableRow,
} from './SitelinksTable.styled';

const SitelinksDynamicTable = ({ fetchedAdGroups }) => {
  // Mocki do wypełnienia widoku
  const renderDynamicColumnsMock = () => {
    const cellsList = [];
    for (let i = 0; i < 15; i++) {
      cellsList.push(`Sitelink ${i + 1}`);
    }
    return cellsList;
  };

  const rednerCheckCellsMock = () => {
    const cellsList = [];
    for (let i = 0; i < 15; i++) {
      cellsList.push(<SitelinkCheck key={`id_${i}`} />);
    }
    return cellsList;
  };

  return (
    <DynamicTableWrapper>
      <ScrollSyncPane>
        <DynamicDataContainer id="infiniteScrollTrigger">
          <StickyListHeader>
            {renderDynamicColumnsMock().map(columnName => (
              <SitelinksCheckCell key={columnName}>
                {columnName}
              </SitelinksCheckCell>
            ))}
          </StickyListHeader>
          <DynamicScrollableDiv>
            {fetchedAdGroups?.map(({ id }) => (
              <SitelinksTableRow key={`dynamicRow_${id}`}>
                {rednerCheckCellsMock()}
              </SitelinksTableRow>
            ))}
          </DynamicScrollableDiv>
        </DynamicDataContainer>
      </ScrollSyncPane>
    </DynamicTableWrapper>
  );
};

const mapStateToProps = state => ({
  fetchedAdGroups: getAdGroupsListToFilter(state),
});

SitelinksDynamicTable.propTypes = {
  fetchedAdGroups: PropTypes.arrayOf(
    PropTypes.shape({
      ad_group: PropTypes.number,
      ad_group_name: PropTypes.string,
      campaign_name: PropTypes.string,
      ad_content_labels: PropTypes.array,
      id: PropTypes.number,
      sitelink_labels: PropTypes.array,
    }),
  ),
};
export default connect(mapStateToProps, null)(SitelinksDynamicTable);
