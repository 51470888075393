export const skuConst = {
  UPDATE_SKU_FILE_BEGIN: 'UPDATE_SKU_FILE_BEGIN',
  UPDATE_SKU_FILE_SUCCESS: 'UPDATE_SKU_FILE_SUCCESS',
  UPDATE_SKU_FILE_FAILED: 'UPDATE_SKU_FILE_FAILED',
  FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_BEGIN:
    'FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_BEGIN',
  FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_FAILED:
    'FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_FAILED',
  FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_SUCCESS:
    'FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_SUCCESS',
  FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_BEGIN:
    'FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_BEGIN',
  FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_SUCCESS:
    'FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_SUCCESS',
  FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_FAILED:
    'FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_FAILED',
  DOWNLOAD_PROMOTION_STRUCTURE_BEGIN: 'DOWNLOAD_PROMOTION_STRUCTURE_BEGIN',
  DOWNLOAD_PROMOTION_STRUCTURE_SUCCESS: 'DOWNLOAD_PROMOTION_STRUCTURE_SUCCESS',
  DOWNLOAD_PROMOTION_STRUCTURE_FAILED: 'DOWNLOAD_PROMOTION_STRUCTURE_FAILED',
};
