import {
  DragAndDropFileUpload,
  LoadedFileComponent,
  StructuresToolbar,
} from 'components';
import useImportSitelinksFile from 'components/ImportSitelinksFileModal/useImportSitelinksFile';
import { StructuresContainer } from 'containers';
import { ContainerTitle } from 'containers/StructuresContainer/StrucuresContainer.styled';

import useSitelinksView from './SitelinksView.hook';
import {
  StyledSitelinkMessageSpan,
  StyledSitelinksContainer,
} from './SitelinksView.styled';

const SitelinksView = () => {
  const { dowloadSitelinksMatchedFile } = useSitelinksView();
  const {
    showUploadField,
    setShowUploadField,
    sendSitelinkFile,
    sitelinkFileData,
  } = useImportSitelinksFile();

  return (
    <StructuresContainer currentStep={2}>
      <StructuresToolbar
        isSaveBtnDisabled={!sitelinkFileData}
        saveBtnAction={dowloadSitelinksMatchedFile}
      />
      <ContainerTitle>Sitelinks</ContainerTitle>
      <StyledSitelinksContainer>
        {showUploadField ? (
          <DragAndDropFileUpload onDropAction={sendSitelinkFile}>
            Load sitelinks file
          </DragAndDropFileUpload>
        ) : (
          <LoadedFileComponent
            headerMessage={`Loaded sitelinks file: `}
            contentMessage={sitelinkFileData?.file}
            onButtonClick={() => setShowUploadField(prevState => !prevState)}
            buttonText={`Load new sitelinks file`}
          >
            <StyledSitelinkMessageSpan>
              Sitelinks has been added based on uploaded file!
            </StyledSitelinkMessageSpan>
          </LoadedFileComponent>
        )}
      </StyledSitelinksContainer>
    </StructuresContainer>
  );
};
export default SitelinksView;
