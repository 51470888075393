import { Loader, NoContent, PromotionList } from 'components';
import { StyledLoaderContainer } from 'components/Loader';
import { TableWrapper } from 'components/PromotionList/PromotionList.styled';
import PropTypes from 'prop-types';
import React from 'react';

import usePromotionTable from './PromotionTable.hook';

const PromotionTable = () => {
  const {
    hasMore,
    isFetching,
    loadMoreAction,
    promotions,
  } = usePromotionTable();

  if (isFetching) {
    return (
      <TableWrapper>
        <StyledLoaderContainer>
          <Loader />
        </StyledLoaderContainer>
      </TableWrapper>
    );
  }

  if (!promotions?.length)
    return (
      <NoContent
        height="100%"
        message="This account doesn't have any promotions..."
      />
    );

  return (
    <PromotionList
      dataSource={promotions}
      loadMoreAction={loadMoreAction}
      hasMore={hasMore}
    />
  );
};

PromotionTable.propTypes = {
  promotions: PropTypes.object,
  state: PropTypes.object,
};

export default PromotionTable;
