export const promotionConst = {
  ADD_KEYWORD_TO_PROMOTION_BEGIN: 'ADD_KEYWORD_TO_PROMOTION_BEGIN',
  ADD_KEYWORD_TO_PROMOTION_SUCCESS: 'ADD_KEYWORD_TO_PROMOTION_SUCCESS',
  ADD_KEYWORD_TO_PROMOTION_FAILED: 'ADD_KEYWORD_TO_PROMOTION_FAILED',
  ADD_ADGROUP_LABEL_BEGIN: 'ADD_ADGROUP_LABEL_BEGIN',
  ADD_ADGROUP_LABEL_SUCCESS: 'ADD_ADGROUP_LABEL_SUCCESS',
  ADD_ADGROUP_LABEL_FAILED: 'ADD_ADGROUP_LABEL_FAILED',
  BATCH_PROMOTION_ADGROUPS_BEGIN: 'BATCH_PROMOTION_ADGROUPS_BEGIN',
  BATCH_PROMOTION_ADGROUPS_SUCCESS: 'BATCH_PROMOTION_ADGROUPS_SUCCESS',
  BATCH_PROMOTION_ADGROUPS_FAILED: 'BATCH_PROMOTION_ADGROUPS_FAILED',
  CLEAR_PROMOTION_STRUCTURE_BEGIN: 'CLEAR_PROMOTION_STRUCTURE_BEGIN',
  CLEAR_PROMOTION_STRUCTURE_SUCCESS: 'CLEAR_PROMOTION_STRUCTURE_SUCCESS',
  CLEAR_PROMOTION_STRUCTURE_FAILED: 'CLEAR_PROMOTION_STRUCTURE_FAILED',
  CLEAR_AD_GROUPS_LIST_TO_FILTER: 'CLEAR_AD_GROUPS_LIST_TO_FILTER',
  DELETE_ADGROUP_LABEL_BEGIN: 'ADD_ADGROUP_LABEL_BEGIN',
  DELETE_ADGROUP_LABEL_SUCCESS: 'ADD_ADGROUP_LABEL_SUCCESS',
  DELETE_ADGROUP_LABEL_FAILED: 'ADD_ADGROUP_LABEL_FAILED',
  DELETE_PROMOTION_BEGIN: 'DELETE_PROMOTION_BEGIN',
  DELETE_PROMOTION_SUCCESS: 'DELETE_PROMOTION_SUCCESS',
  DELETE_PROMOTION_FAILED: 'DELETE_PROMOTION_FAILED',
  SET_SELECTED_PROMOTION: 'SET_SELECTED_PROMOTION',
  FETCH_PROMOTION_STRUCTURE_BEGIN: 'FETCH_PROMOTION_STRUCTURE_BEGIN',
  FETCH_PROMOTION_STRUCTURE_SUCCESS: 'FETCH_PROMOTION_STRUCTURE_SUCCESS',
  FETCH_PROMOTION_STRUCTURE_FAILED: 'FETCH_PROMOTION_STRUCTURE_FAILED',
  CREATE_NEW_PROMOTION_BEGIN: 'CREATE_NEW_PROMOTION_BEGIN',
  FETCH_PROMOTION_CAMPAIGNS_BEGIN: 'FETCH_PROMOTION_CAMPAIGNS_BEGIN',
  FETCH_PROMOTION_CAMPAIGNS_SUCCESS: 'FETCH_PROMOTION_CAMPAIGNS_SUCCESS',
  FETCH_PROMOTION_CAMPAIGNS_FAILED: 'FETCH_PROMOTION_CAMPAIGNS_FAILED',
  FETCH_PROMOTION_ADGROUP_BEGIN: 'FETCH_PROMOTION_ADGROUP_BEGIN',
  FETCH_PROMOTION_ADGROUP_SUCCESS: 'FETCH_PROMOTION_ADGROUP_SUCCESS',
  FETCH_PROMOTION_ADGROUP_FAILED: 'FETCH_PROMOTION_ADGROUP_FAILED',
  FETCH_PROMOTION_KEYWORDS_BEGIN: 'FETCH_PROMOTION_KEYWORDS_BEGIN',
  FETCH_PROMOTION_KEYWORDS_SUCCESS: 'FETCH_PROMOTION_KEYWORDS_SUCCESS',
  FETCH_PROMOTION_KEYWORDS_FAILED: 'FETCH_PROMOTION_KEYWORDS_FAILED',
  FETCH_ACCOUNT_PROMOTIONS_BEGIN: 'FETCH_ACCOUNT_PROMOTIONS_BEGIN',
  FETCH_ACCOUNT_PROMOTIONS_SUCCESS: 'FETCH_ACCOUNT_PROMOTIONS_SUCCESS',
  FETCH_ACCOUNT_PROMOTIONS_FAILED: 'FETCH_ACCOUNT_PROMOTIONS_FAILED',
  INIT_LABELS_BEGIN: 'INIT_LABELS_BEGIN',
  INIT_LABELS_SUCCESS: 'INIT_LABELS_SUCCESS',
  INIT_LABELS_FAILED: 'INIT_LABELS_FAILED',
  REMOVE_PROMOTION_CAMPAIGN_BEGIN: 'REMOVE_PROMOTION_CAMPAIGN_BEGIN',
  REMOVE_PROMOTION_CAMPAIGN_SUCCESS: 'REMOVE_PROMOTION_CAMPAIGN_SUCCESS',
  REMOVE_PROMOTION_CAMPAIGN_FAILED: 'REMOVE_PROMOTION_CAMPAIGN_FAILED',
  REMOVE_PROMOTION_KEYWORD_BEGIN: 'REMOVE_PROMOTION_KEYWORD_BEGIN',
  REMOVE_PROMOTION_KEYWORD_SUCCESS: 'REMOVE_PROMOTION_KEYWORD_SUCCESS',
  REMOVE_PROMOTION_KEYWORD_FAILED: 'REMOVE_PROMOTION_KEYWORD_FAILED',
  CREATE_NEW_PROMOTION_SUCCESS: 'CREATE_NEW_PROMOTION_SUCCESS',
  CREATE_NEW_PROMOTION_FAILED: 'CREATE_NEW_PROMOTION_FAILED',
  SET_FILTERED_AD_GROUPS_BY_SKU_BOX: 'SET_FILTERED_AD_GROUPS_BY_SKU_BOX',
  SET_FILTER_PRODUCT_NAMES: 'SET_FILTER_PRODUCT_NAMES',
  SET_IS_FETCHING_CAMPAIGNS: 'SET_IS_FETCHING_CAMPAIGNS',
  SET_HAS_MORE_CAMPAIGNS: 'SET_HAS_MORE_CAMPAIGNS',
  SET_AD_GROUPS_LIST_TO_FILTER: 'SET_AD_GROUPS_LIST_TO_FILTER',
  SET_FETCHED_PROMOTION_AD_GROUPS: 'SET_FETCHED_PROMOTION_AD_GROUPS',
  SET_FILTERED_AD_GROUPS_BY_SIMILARITY: 'SET_FILTERED_AD_GROUPS_BY_SIMILARITY',
  UPDATE_AD_GROUPS_LIST_TO_FILTER: 'UPDATE_AD_GROUPS_LIST_TO_FILTER',
  UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_BEGIN:
    'UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_BEGIN',
  UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_SUCCESS:
    'UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_SUCCESS',
  UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_FAILED:
    'UPLOAD_PROMOTION_TO_GOOGLE_ACCOUNT_FAILED',
};
