import { Button } from 'components/Button';
import styled from 'styled-components';

export const FilterToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.22vh 0;
`;

export const FilterToolbarButton = styled(Button)`
  height: 4.902vh;
  width: 10.729vw;
  word-break: break-word;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0;
  text-align: center;
  margin-left: 20px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  text-align: center;
`;

export const SwitchLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-self: center;
  font-size: 0.8rem;

  div,
  span {
    margin: auto;
  }

  span {
    margin-right: 10px;
  }
`;
