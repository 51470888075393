export const userConstants = {
  CHANGE_PASSWORD_BEGIN: 'CHANGE_PASSWORD_BEGIN',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
  LOGIN_BEGIN: 'USER_LOGIN_BEGIN',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  LOGOUT: 'USER_LOGOUT',
  SET_USER_ACCOUNT_DETAILS: 'SET_USER_ACCOUNT_DETAILS',
  FETCH_GOOGLE_ADS_ACCOUNT_BEGIN: 'FETCH_GOOGLE_ADS_ACCOUNT_BEGIN',
  FETCH_GOOGLE_ADS_ACCOUNT_SUCCESS: 'FETCH_GOOGLE_ADS_ACCOUNT_SUCCESS',
  FETCH_GOOGLE_ADS_ACCOUNT_FAILED: 'FETCH_GOOGLE_ADS_ACCOUNT_FAILED',
};
