import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

const deletePromotionBegin = () => ({
  type: promotionConst.DELETE_PROMOTION_BEGIN,
});

const deletePromotionSuccess = () => ({
  type: promotionConst.DELETE_PROMOTION_SUCCESS,
});

const deletePromotionFailed = () => ({
  type: promotionConst.DELETE_PROMOTION_FAILED,
});

export const deletePromotionAction = ({
  accountId,
  promotionId,
}) => async dispatch => {
  dispatch(deletePromotionBegin());
  try {
    await axios.delete(`/ga/promotion_structure/${accountId}/${promotionId}/`);
    dispatch(deletePromotionSuccess());
  } catch (error) {
    dispatch(deletePromotionFailed());
    return handleError(error, 'Delete promotion');
  }
};
