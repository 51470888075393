import { Filter } from '@styled-icons/feather/Filter';
import styled from 'styled-components';

export const FilterInputWrapper = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid ${({ theme }) => theme.pallete.inputsBorder};
  border-radius: 4px;
  height: ${({ height }) => height || '1.75rem'};
  width: 40%;
  min-width: 235px;
  margin: 1.2% 0;

  input {
    width: 100%;
    height: inherit;
    padding: unset;
    text-overflow: ellipsis;
    font-size: 0.8rem;
  }
`;

export const FilterIcon = styled(Filter)`
  color: ${({ theme }) => theme.pallete.primary};
  height: 60%;
  margin: 0 0.5rem;
`;

export const FilterCustomInput = styled.input`
  border: unset;
`;
