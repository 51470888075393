import styled from 'styled-components';

export const PaginationButton = styled.button`
  background: ${({ theme, disabled }) =>
    disabled ? theme.pallete.primaryDisabled : theme.pallete.primary};
  border: ${({ theme, disabled }) =>
    disabled ? theme.pallete.inputsBorder : 'unset'};
  border-radius: 4px;
  margin: 1%;
  ${({ disabled }) => !disabled && 'color: white'}
`;
