import axios from 'axios';
import { adGroupConst } from 'store/constants/adGroupConst';
import { handleError } from 'utils';

const addNewAdGroupBegin = () => ({
  type: adGroupConst.ADD_NEW_ADGROUP_BEGIN,
});

const addNewAdGroupSuccess = () => ({
  type: adGroupConst.ADD_NEW_ADGROUP_SUCCESS,
});

const addNewAdGroupFailed = () => ({
  type: adGroupConst.ADD_NEW_ADGROUP_FAILED,
});

export const addNewAdGroupAction = (
  accountId,
  adgroupData,
) => async dispatch => {
  dispatch(addNewAdGroupBegin);
  try {
    const { data } = await axios.post(`ga/ad_group/${accountId}/`, adgroupData);
    dispatch(addNewAdGroupSuccess());
    return data;
  } catch (error) {
    dispatch(addNewAdGroupFailed());
    handleError(error, 'Add new adgroup');
  }
};
