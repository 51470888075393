import { NoContent } from 'components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { getAdGroupsListToFilterLength } from 'store/selectors/promotionsSelectors';

import SitelinksDynamicTable from './SitelinksDynamicTable';
import SitelinksStaticTable from './SitelinksStaticTable';
import { SitelinksTablesWrapper } from './SitelinksTable.styled';

const SitelinksTable = ({ fetchedAdGroupsLength }) => {
  if (!fetchedAdGroupsLength) return <NoContent />;

  return (
    <ScrollSync>
      <SitelinksTablesWrapper>
        <ScrollSyncPane>
          <SitelinksStaticTable />
          <SitelinksDynamicTable />
        </ScrollSyncPane>
      </SitelinksTablesWrapper>
    </ScrollSync>
  );
};
const mapStateToProps = state => ({
  fetchedAdGroupsLength: getAdGroupsListToFilterLength(state),
});

SitelinksTable.propTypes = {
  fetchedAdGroupsLength: PropTypes.number,
};
export default connect(mapStateToProps, null)(SitelinksTable);
