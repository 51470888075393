import styled from 'styled-components';

export const DropdownList = styled.div`
  position: absolute;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 140%;
  left: 0;
  width: 100%;
  background: white;
  max-height: 40vh;
  overflow-x: hidden;
`;

export const DropdownItem = styled.button`
  height: 6.8vh;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.pallete.primary};
  padding: 0 2.9%;
  background: white;
  border: unset;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.pallete.tableRowsHighlight};
    white-space: unset;
  }
`;

export const HighlightedSpan = styled.span`
  color: ${({ theme }) => theme.pallete.placeholderText};
`;
