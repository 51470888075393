import { campaignBoxTypes } from 'components/CampaignBox';
import useCampaign from 'hooks/useCampaign';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setCampaignBoxTypeAction,
  setIsLoading,
  setSearchQuery as searchQuerySetter,
} from 'store/actions/helpersActions/helpersSetters';

const useSummaryView = () => {
  const dispatch = useDispatch();
  const { fetchAddedPromotionCampaigns } = useCampaign();
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    dispatch(setCampaignBoxTypeAction(campaignBoxTypes.SUMMARY));
    dispatch(searchQuerySetter(null));
  }, []);

  const fetchPromotionCampaigns = async () => {
    dispatch(setIsLoading(true));
    dispatch(searchQuerySetter(searchQuery));
    await fetchAddedPromotionCampaigns({
      page: 1,
      search: searchQuery,
    });
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (searchQuery === null) return;
    const timer = setTimeout(fetchPromotionCampaigns, 600);
    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);

  return {
    setSearchQuery,
  };
};

export default useSummaryView;
