import useAccess from 'hooks/useAccess';
import useAdGroupsData from 'hooks/useAdGroupsData';
import fileDownload from 'js-file-download';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsLoading,
  setSearchQuery as searchQuerySetter,
} from 'store/actions/helpersActions/helpersSetters';
import { fetchSitelinksMatchedFile } from 'store/actions/sitelinksActions/fetchMatchedSitelinksFile';
import { fetchPromotionSitelinksFile } from 'store/actions/sitelinksActions/fetchPromotionSitelinksFiles';
import {
  getSelectedPromotionId,
  getSitelinksFileData,
} from 'store/selectors/promotionsSelectors';

const useSitelinksView = () => {
  useAccess();
  const dispatch = useDispatch();
  const { fetchAdGroupsData } = useAdGroupsData();
  const [searchQuery, setSearchQuery] = useState(null);
  const promotionId = useSelector(getSelectedPromotionId);
  const sitelinksFileData = useSelector(getSitelinksFileData);

  const dowloadSitelinksMatchedFile = useCallback(async () => {
    const matchedFile = await dispatch(
      fetchSitelinksMatchedFile(promotionId, sitelinksFileData.id),
    );
    if (!matchedFile) return;
    fileDownload(matchedFile, 'sitelinksMatched.xlsx');
  }, [sitelinksFileData]);

  useEffect(() => {
    if (!promotionId) return;
    dispatch(setIsLoading(true));
    dispatch(fetchPromotionSitelinksFile(promotionId));
  }, []);

  useEffect(() => {
    if (searchQuery === null) return;
    const timer = setTimeout(async () => {
      dispatch(setIsLoading(true));
      dispatch(searchQuerySetter(searchQuery));
      await fetchAdGroupsData({
        page: 1,
        search: searchQuery,
      });
    }, 600);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  return { setSearchQuery, dowloadSitelinksMatchedFile };
};

export default useSitelinksView;
