import { PromotionTable } from 'components';
import { buttonVariants } from 'components/Button';
import MainContainer from 'containers/MainContainer';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getSelectedPromotionId } from 'store/selectors/promotionsSelectors';

import { useChooseAccount } from './ChooseAccount.hook';
import {
  Dropdown,
  DropdownArrow,
  DropdownContent,
  DropdownHint,
  DropdownItem,
  GhostButton,
  PromotionContainer,
  StructureBtnsContainer,
  StyledNextViewButton,
  ViewDescription,
  ViewTitle,
  Wrapper,
} from './ChooseAccount.styled';
import SelectedAccount from './SelectedAccount';

const ChooseAccountView = ({ selectedPromotionId }) => {
  const {
    isDropdownCollapsed,
    setIsDropdownCollapsed,
    googleAdsAccounts,
    isFetchingAccounts,
    setSelectedAccount,
    setShowPromotionTable,
    showPromotionTable,
    goToStructureView,
    createNewPromotion,
    isFinishScreen,
  } = useChooseAccount();

  return (
    <MainContainer>
      <ViewTitle>
        {isFinishScreen() ? 'Promotion has been updated!' : 'Choose account'}
      </ViewTitle>
      <ViewDescription>Choose Google Ads account</ViewDescription>
      <Wrapper>
        <Dropdown
          onBlur={() => setIsDropdownCollapsed(false)}
          onClick={() => setIsDropdownCollapsed(!isDropdownCollapsed)}
        >
          <SelectedAccount isFetchingAccounts={isFetchingAccounts} />
          {Array.isArray(googleAdsAccounts) && (
            <DropdownContent collapsed={isDropdownCollapsed}>
              {googleAdsAccounts.map(({ name, client_customer_id, id }) => (
                <DropdownItem
                  key={client_customer_id}
                  onClick={() =>
                    setSelectedAccount({ name, client_customer_id, id })
                  }
                >
                  {name}
                  <DropdownHint>{client_customer_id}</DropdownHint>
                </DropdownItem>
              ))}
            </DropdownContent>
          )}
          <DropdownArrow $collapsed={isDropdownCollapsed} />
        </Dropdown>
        <StructureBtnsContainer>
          <GhostButton
            hover={!showPromotionTable}
            onClick={createNewPromotion}
            variant={buttonVariants.SECONDARY}
          >
            Create new promotion
          </GhostButton>
          <GhostButton
            hover={showPromotionTable}
            onClick={() => setShowPromotionTable(true)}
            variant={buttonVariants.SECONDARY}
          >
            Choose saved promotion
          </GhostButton>
        </StructureBtnsContainer>
        <form id="promotionForm" onSubmit={goToStructureView}>
          <PromotionContainer>
            {showPromotionTable ? (
              <PromotionTable />
            ) : (
              <label>
                New promotion
                <input
                  required
                  name="newPromotionName"
                  placeholder="Insert the name of the promotion"
                />
              </label>
            )}
          </PromotionContainer>
          <StyledNextViewButton
            variant={buttonVariants.PRIMARY}
            disabled={showPromotionTable && !selectedPromotionId}
            type="submit"
            form="promotionForm"
          >
            {showPromotionTable ? 'Next' : 'Create'}
          </StyledNextViewButton>
        </form>
      </Wrapper>
    </MainContainer>
  );
};

const mapStateToProps = state => ({
  selectedPromotionId: getSelectedPromotionId(state),
});

ChooseAccountView.propTypes = {
  selectedPromotionId: PropTypes.number,
};

export default connect(mapStateToProps, null)(ChooseAccountView);
