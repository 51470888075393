import { ErrorMessage, InformationSpan } from 'components';
import MainContainer from 'containers/MainContainer';
import { ReactComponent as Arrow } from 'media/svg/icons/arrow.svg';
import React from 'react';

import { useChangePassword } from './ChangePassword.hook';
import {
  BackButton,
  RedirectTimeSpan,
  ResetButton,
  ResetPasswordContentWrapper,
  ResetPasswordInfoWrapper,
  ResetPasswordInput,
  ResetPasswordWrapper,
} from './ChangePassword.styled';

const ChangePasswordView = () => {
  const {
    changePassword,
    error,
    passwordChanged,
    history,
    redirectTime,
  } = useChangePassword();

  if (passwordChanged) {
    return (
      <MainContainer>
        <ResetPasswordInfoWrapper>
          <h1>Password has been changed!</h1>
          <InformationSpan>
            A tool for automating the creation of structures for commercial
            actions <br />
            based on the existing customer campaigns.
          </InformationSpan>
          <RedirectTimeSpan>
            Automatically redirecting in {redirectTime}
          </RedirectTimeSpan>
        </ResetPasswordInfoWrapper>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <ResetPasswordWrapper>
        <BackButton
          onClick={() =>
            history.push(
              (history.location.state && history.location.state.from) || '/',
            )
          }
        >
          <Arrow />
        </BackButton>
        <form onSubmit={changePassword}>
          <ResetPasswordContentWrapper>
            <h1>Change password</h1>
            <InformationSpan>
              A tool for automating the creation of structures for commercial
              actions <br />
              based on the existing customer campaigns.
            </InformationSpan>
            <label>
              Current password
              <ResetPasswordInput
                name="currentPassword"
                placeholder="Enter current password"
                type="password"
                required
              />
            </label>
            <label>
              New Password
              <ResetPasswordInput
                name="newPassword"
                placeholder="Enter new password"
                type="password"
                required
              />
            </label>
            <label>
              Repeat new password
              <ResetPasswordInput
                name="repeatPassword"
                placeholder="Enter new password again"
                type="password"
                required
              />
            </label>
            {error && (
              <ErrorMessage> Oh. There was something wrong :( </ErrorMessage>
            )}
            <ResetButton type="submit">Change</ResetButton>
          </ResetPasswordContentWrapper>
        </form>
      </ResetPasswordWrapper>
    </MainContainer>
  );
};

export default ChangePasswordView;
