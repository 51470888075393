import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changePasswordAction } from 'store/actions/userActions/changePassword';

export const useChangePassword = () => {
  let history = useHistory();
  const [redirectTime, setRedirectTime] = useState(3);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!passwordChanged) return;

    localStorage.removeItem('jwt');
    const intervalId = setInterval(() => {
      setRedirectTime(prevState => prevState - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [passwordChanged]);

  useEffect(() => {
    if (redirectTime <= 0) {
      return history.push('/');
    }
  }, [redirectTime, history]);

  const changePassword = async event => {
    const { currentPassword, newPassword, repeatPassword } = event.target;

    event.preventDefault();

    const status = await dispatch(
      changePasswordAction({
        old_password: currentPassword.value,
        new_password: newPassword.value,
        new_password_retyped: repeatPassword.value,
      }),
    );

    if (status !== 200) return setError(true);
    return setPasswordChanged(true);
  };

  return {
    changePassword,
    error,
    history,
    passwordChanged,
    redirectTime,
  };
};
