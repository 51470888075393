import { FileTrayFull } from '@styled-icons/ionicons-outline/FileTrayFull';
import styled from 'styled-components';

export const LoadedFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.pallete.placeholderText};
  overflow: hidden;
  font-size: 1rem;
`;

export const FileTrayStyledIcon = styled(FileTrayFull)`
  color: ${({ theme }) => theme.pallete.placeholderText};
  height: 10rem;
  margin: 2rem;
`;

export const FileNameSpan = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.pallete.primary};
`;
