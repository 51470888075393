import BrandLogo from 'components/BrandLogo/BrandLogo';
import styled from 'styled-components';

export const StructuresHeaderWrapper = styled.div`
  width: 100%;
  height: 10.241vh;
  min-height: 51px;
  display: flex;
`;
export const LogoWrapper = styled.div`
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  padding-left: 6.51vw;
`;

export const HeaderLogo = styled(BrandLogo)`
  height: 2.96vh;
  min-width: 109px;
  min-height: 14px;
`;

export const HeaderUserMenuWrapper = styled.div`
  padding-right: 6.45vw;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const HeaderButtonsWrapper = styled.div`
  width: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1.05vw;

  & button:not(:first-child) {
    margin-left: 1.05vw;
  }
`;
