import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hideModal } from 'store/actions/modalActions/ModalVisibility';
import { fetchPromotionStructureAction } from 'store/actions/promotionActions/fetchPromotionStructureAction';
import {
  setFilteredAdGroupsBySimilarity,
  setFilteredAdGroupsBySkuBox,
  setFilterProductNames,
  setSelectedPromotion,
} from 'store/actions/promotionActions/promotionSetters';
import { uploadSkuFileAction } from 'store/actions/skuFilesActions/uploadSKUFile';
import {
  getSelectedPromotionId,
  getSelectedPromotionSkuFile,
} from 'store/selectors/promotionsSelectors';
import { getUserId } from 'store/selectors/userSelectors';

import useToasts from './useToasts';

const useSku = () => {
  const dispatch = useDispatch();
  const promotionId = useSelector(getSelectedPromotionId);
  const skuFile = useSelector(getSelectedPromotionSkuFile);
  const accountId = useSelector(getUserId);
  const [fileLoaded, setFileLoaded] = useState(!!skuFile);
  const [isProcessing, setIsProcessing] = useState(false);
  const [timeOutCount, setTimeOutCount] = useState(600);
  const {
    closeStaticToast,
    showStaticToast,
    showRequestResultsToast,
  } = useToasts();

  const closeSkuModal = () => {
    dispatch(hideModal());
    dispatch(setFilteredAdGroupsBySkuBox([]));
    dispatch(setFilteredAdGroupsBySimilarity([]));
    dispatch(setFilterProductNames(false));
  };

  const uploadSkuFile = async file =>
    dispatch(uploadSkuFileAction(accountId, promotionId, file));

  const sendSkuFile = useCallback(async files => {
    showStaticToast('Uploading SKU file...');
    dispatch(setFilteredAdGroupsBySkuBox([]));

    const fileToUpload = files[0];
    const formData = new FormData();

    formData.append('sku_file', fileToUpload, fileToUpload.name);

    const result = await uploadSkuFile(formData);
    if (result?.id) dispatch(setSelectedPromotion(result));

    if (result?.error === 'resolved') return closeStaticToast();

    setIsProcessing(result?.processing_sku);

    showRequestResultsToast(
      result?.error,
      `Import SKU file: ${result?.error?.sku_file || result?.error}`,
      'SKU file has been imported successfully!',
      () => setFileLoaded(true),
    );
  });

  useEffect(() => {
    if (!isProcessing) return;

    const intervalId = setInterval(async () => {
      const { processing_sku } = await dispatch(
        fetchPromotionStructureAction(accountId, promotionId),
      );
      setIsProcessing(processing_sku);
      setTimeOutCount(prevState => prevState - 2);
    }, 2000);

    if (timeOutCount <= 0) {
      setIsProcessing(false);
      toast.error('SKU processing connection timeout');
      return () => clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isProcessing, timeOutCount]);

  return {
    closeSkuModal,
    fileLoaded,
    isProcessing,
    sendSkuFile,
    setFileLoaded,
    uploadSkuFile,
  };
};

export default useSku;
