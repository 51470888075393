import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setSelectedPromotion } from 'store/actions/promotionActions/promotionSetters';
import { fetchGoogleAdsAccounts } from 'store/actions/userActions/account';
import { setUserAccountDetails } from 'store/actions/userActions/account/accountDataSetters';
import { createNewPromotionAction } from 'store/actions/userActions/promotions/createNewPromotion';
import { getUserAccountDetails } from 'store/selectors/userSelectors';

export const useChooseAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAccountDetails = useSelector(state => getUserAccountDetails(state));
  const [isDropdownCollapsed, setIsDropdownCollapsed] = useState(false);
  const [googleAdsAccounts, setGoogleAdsAccounts] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [showPromotionTable, setShowPromotionTable] = useState(true);

  const prepareUserData = ({ name, client_customer_id, id }) => ({
    userName: name,
    clientCustomerId: client_customer_id,
    userId: id,
  });
  const isFinishScreen = () => window.location.search.includes('finish=true');

  const createAccountErrorHandler = errorResponse => {
    if (errorResponse?.data?.name)
      return toast.error(
        'This promotion name is taken ... Please enter another one',
      );
    return toast.error(`Error ${errorResponse?.status}`);
  };

  const goToStructureView = async event => {
    const { newPromotionName } = event.target;
    event.preventDefault();
    if (!showPromotionTable) {
      const newPromotionData = await dispatch(
        createNewPromotionAction({
          accountId: userAccountDetails.userId,
          name: newPromotionName.value,
        }),
      );
      if (newPromotionData?.error)
        return createAccountErrorHandler(newPromotionData.error);

      dispatch(setSelectedPromotion(newPromotionData));
    }

    history.push('/structure');
  };

  const createNewPromotion = () => {
    setShowPromotionTable(false);
    dispatch(setSelectedPromotion({}));
  };

  useEffect(() => {
    const fetchGoogleAccounts = async () => {
      setIsFetchingAccounts(true);
      const data = await dispatch(fetchGoogleAdsAccounts());

      if (data) {
        setGoogleAdsAccounts(data);
      }

      return setIsFetchingAccounts(false);
    };

    if (!googleAdsAccounts) {
      fetchGoogleAccounts();
    }
  }, [googleAdsAccounts]);

  useEffect(() => {
    if (!Object.keys(userAccountDetails).length && googleAdsAccounts) {
      setSelectedAccount(googleAdsAccounts[0]);
    }
  }, [googleAdsAccounts, userAccountDetails]);

  useEffect(() => {
    if (!selectedAccount) return;
    const setAccountDetails = userData =>
      dispatch(setUserAccountDetails(prepareUserData(userData)));

    return setAccountDetails(selectedAccount);
  }, [selectedAccount]);

  return {
    isDropdownCollapsed,
    setIsDropdownCollapsed,
    googleAdsAccounts,
    isFetchingAccounts,
    setSelectedAccount,
    showPromotionTable,
    setShowPromotionTable,
    goToStructureView,
    createNewPromotion,
    isFinishScreen,
  };
};
