import { StructuresToolbar } from 'components';
import { campaignBoxTypes } from 'components/CampaignBox/CampaignBox.variants';
import CampaignBoxWrapper from 'components/CampaignBox/CampaignBoxWrapper';
import { StructuresContainer } from 'containers';
import { ContainerTitle } from 'containers/StructuresContainer/StrucuresContainer.styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCampaignBoxTypeAction } from 'store/actions/helpersActions/helpersSetters';

const EtiquettesView = ({ setCampaignBoxTypeAction }) => {
  setCampaignBoxTypeAction(campaignBoxTypes.ETIQUETTES);
  return (
    <StructuresContainer currentStep={3}>
      <StructuresToolbar />
      <ContainerTitle> Etiquettes</ContainerTitle>
      <CampaignBoxWrapper />
    </StructuresContainer>
  );
};

EtiquettesView.propTypes = {
  setCampaignBoxTypeAction: PropTypes.func,
};

const mapDispatchToProps = {
  setCampaignBoxTypeAction,
};

export default connect(null, mapDispatchToProps)(EtiquettesView);
