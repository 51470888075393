import { BrandTableWrapper, StructuresToolbar } from 'components';
import { StructuresContainer } from 'containers';
import { ContainerTitle } from 'containers/StructuresContainer/StrucuresContainer.styled';
import useAccess from 'hooks/useAccess';
import useAdGroupsData from 'hooks/useAdGroupsData';
import React, { useEffect } from 'react';

import useContentImplementation from './ContentImplementation.hook';
import { BrandContainer } from './ContentImplementation.styled';
import ContentImplementationSaveButtons from './ContentImplemetationSaveButtons';

const ContentImplementationView = () => {
  useAccess();

  const { initAdGroupFetching } = useAdGroupsData();
  const {
    downloadMatchedAdContentFile,
    fetchPromotionAdContentFileData,
  } = useContentImplementation();

  useEffect(() => {
    initAdGroupFetching();
    fetchPromotionAdContentFileData();
  }, []);

  return (
    <StructuresContainer currentStep={1}>
      <StructuresToolbar
        saveBtnAction={downloadMatchedAdContentFile}
        customSaveBtns={
          <ContentImplementationSaveButtons
            onClickAction={downloadMatchedAdContentFile}
          />
        }
      />
      <ContainerTitle>Content implementation</ContainerTitle>
      <BrandContainer id="infiniteScrollBrandTable">
        <BrandTableWrapper />
      </BrandContainer>
    </StructuresContainer>
  );
};

export default ContentImplementationView;
