import { fromJS, List, Map } from 'immutable';
import { draftConst } from 'store/constants/draftConst';

const initialState = Map({
  adGroupsDraft: List([]),
  keywordsDraft: List([]),
  newFeaturesDraft: Map({}),
});

const updateAdGroupsDraft = (state, { adGroup }) =>
  state.update('adGroupsDraft', adGroupsDraft => adGroupsDraft.push(adGroup));

const updateKeywordsDraft = (state, { keyword }) =>
  state.update('keywordsDraft', keywordsDraft => keywordsDraft.push(keyword));

const updateNewFeaturesDraft = (state, { newFeature }) =>
  state.set('newFeaturesDraft', fromJS(newFeature));

export const draftReducer = (state = initialState, action) =>
  ((
    {
      [draftConst.UPDATE_AD_GROUP_DRAFT]: updateAdGroupsDraft,
      [draftConst.UPDATE_KEYWORDS_DRAFT]: updateKeywordsDraft,
      [draftConst.UPDATE_NEW_FEATURES_DRAFT]: updateNewFeaturesDraft,
    }[action.type] || (fn => fn)
  )(state, action));
