import axios from 'axios';
import queryString from 'query-string';
import { campaignConst } from 'store/constants/campaignsConst';
import { handleError } from 'utils';

const fetchCampaignsBegin = () => ({
  type: campaignConst.FETCH_CAMPAIGN_BEGIN,
});

const fetchCampaignsSuccess = () => ({
  type: campaignConst.FETCH_CAMPAIGN_SUCCESS,
});

const fetchCampaignsFailed = () => ({
  type: campaignConst.FETCH_CAMPAIGN_FAILED,
});

export const fetchCampaigns = (userId, query) => async dispatch => {
  const stringifiedQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  dispatch(fetchCampaignsBegin);

  try {
    const { data } = await axios.get(
      `ga/campaign/${userId}/${stringifiedQuery && `?${stringifiedQuery}`}`,
    );
    dispatch(fetchCampaignsSuccess());
    return data;
  } catch (error) {
    dispatch(fetchCampaignsFailed());
    return handleError(error, 'Fetch campaigns');
  }
};
