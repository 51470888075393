import axios from 'axios';

const options = {
  headers: {
    'Content-Type': 'multipart/form-data;',
  },
};

import { adContentConst } from 'store/constants/adContentConst';
import { handleError } from 'utils';

export const uploadAdContentFileActionBegin = () => ({
  type: adContentConst.UPLOAD_AD_CONTENT_FILE_BEGIN,
});

export const uploadAdContentFileActionSuccess = () => ({
  type: adContentConst.UPLOAD_AD_CONTENT_FILE_SUCCESS,
});

export const uploadAdContentFileActionFailed = () => ({
  type: adContentConst.UPLOAD_AD_CONTENT_FILE_FAILED,
});

export const uploadAdContentFileAction = (
  promotionId,
  file,
) => async dispatch => {
  dispatch(uploadAdContentFileActionBegin());
  try {
    await axios.post(`/ga/ad_content_file/${promotionId}/`, file, options);
    dispatch(uploadAdContentFileActionSuccess());
  } catch (error) {
    dispatch(uploadAdContentFileActionFailed());
    return { error: handleError(error, 'Upload ad content file') };
  }
};
