import {
  Loader,
  StyledLoaderContainer,
  StyledLoaderMessage,
} from 'components/Loader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsLoading } from 'store/selectors/helpersSelectors';

import BrandTable from './BrandTable';

const BrandTableWrapper = ({ isLoading }) => {
  if (isLoading) {
    return (
      <StyledLoaderContainer>
        <Loader />
        <StyledLoaderMessage>Fetching data...</StyledLoaderMessage>
      </StyledLoaderContainer>
    );
  }
  return <BrandTable />;
};

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
});

BrandTableWrapper.propTypes = {
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, null)(BrandTableWrapper);
