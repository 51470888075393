import { RowCheckbox } from 'components/IndeterminateCheckbox';
import {
  ListHeader,
  TableCell,
  TableColumnHeader,
  TableRow,
} from 'components/TableItems/TableItems.styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { usePromotionList } from './PromotionList.hook';
import {
  CenteredDiv,
  DeletePromotionIcon,
  InvisibleListColumnName,
  PromotionListContainer,
  PromotionListWrapper,
} from './PromotionList.styled';

export const PromotionList = ({ loadMoreAction, dataSource, hasMore }) => {
  const { deletePromotion, selectedRow, setSelectedRow } = usePromotionList(
    dataSource,
  );
  const columnNames = ['Name', 'User', 'Date'];
  const [page, setPage] = useState(1);

  const fetchMorePromotions = () => {
    const nextPage = page + 1;
    loadMoreAction(nextPage);
    setPage(nextPage);
  };

  const deletePromotionFn = async promotionId => {
    await deletePromotion(promotionId);
    await loadMoreAction(1);
    setPage(1);
  };

  return (
    <PromotionListContainer>
      <ListHeader>
        <InvisibleListColumnName />
        {columnNames.map(columnName => (
          <TableColumnHeader key={columnName}>{columnName}</TableColumnHeader>
        ))}
      </ListHeader>
      <PromotionListWrapper id={'promotionListWrapper'}>
        <InfiniteScroll
          dataLength={dataSource.length || 0}
          hasMore={hasMore}
          next={fetchMorePromotions}
          scrollableTarget="promotionListWrapper"
        >
          {dataSource.map(({ id, name, author, created }) => (
            <>
              <TableRow key={id}>
                <CenteredDiv>
                  <RowCheckbox
                    checked={id === selectedRow}
                    onChange={() => setSelectedRow(id)}
                    type="checkbox"
                  />
                </CenteredDiv>
                <TableCell onClick={() => setSelectedRow(id)}>{name}</TableCell>
                <TableCell onClick={() => setSelectedRow(id)}>
                  {author}
                </TableCell>
                <TableCell onClick={() => setSelectedRow(id)}>
                  {new Date(created).toLocaleDateString('es-CL')}
                </TableCell>
                <TableCell
                  flexBasis="15%"
                  onClick={() => deletePromotionFn(id)}
                  center
                >
                  <DeletePromotionIcon />
                </TableCell>
              </TableRow>
            </>
          ))}
        </InfiniteScroll>
      </PromotionListWrapper>
    </PromotionListContainer>
  );
};

PromotionList.propTypes = {
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loadMoreAction: PropTypes.func,
  hasMore: PropTypes.bool,
};

export default PromotionList;
