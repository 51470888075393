import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uploadPromotionToGoogleAccountAction } from 'store/actions/promotionActions/uploadPromotionToGoogleAccount';
import { getSelectedPromotionId } from 'store/selectors/promotionsSelectors';
import { getUserId } from 'store/selectors/userSelectors';

const useStructuresHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const steps = [
    'structure',
    'content-implementation',
    'sitelinks',
    'summary',
    `account/?finish=true`,
  ];
  const currentStepPath = window.location.href.split('/').pop();
  const stepIndex = steps.indexOf(currentStepPath);
  const nextButtonVisibility = stepIndex < steps.length;
  const goBackButtonVisibility = stepIndex > 0;
  const goBackToPromotionsVisibility = stepIndex === 0;
  const isFinishButton = stepIndex === steps.length - 2;
  const accountId = useSelector(getUserId);
  const promotionId = useSelector(getSelectedPromotionId);

  const buttonsList = [
    { stepIdx: 0, label: 'Import SKU', modalType: 'skuFilterModal' },
    {
      stepIdx: 1,
      label: 'Import ETA file',
      modalType: 'adContentModal',
      uploadedFileType: 'ETA',
    },
    {
      stepIdx: 1,
      label: 'Import RSA file',
      modalType: 'adContentModal',
      uploadedFileType: 'RSA',
    },
    {
      stepIdx: 4,
      label: 'Import etiquettes',
      modalType: 'importEtiquettesModal',
    },
  ];
  const backButtonAction = () => history.push(`/${steps[stepIndex - 1]}`);

  const backToPromotionAction = () => history.push(`/account`);

  const nextButtonAction = async () => {
    if (isFinishButton) {
      await dispatch(
        uploadPromotionToGoogleAccountAction(accountId, promotionId),
      );
    }
    history.push(`/${steps[stepIndex + 1]}`);
  };

  const buttonData = buttonsList.filter(({ stepIdx }) => stepIdx === stepIndex);

  return {
    backButtonAction,
    backToPromotionAction,
    buttonData,
    goBackButtonVisibility,
    goBackToPromotionsVisibility,
    isFinishButton,
    nextButtonAction,
    nextButtonVisibility,
  };
};

export default useStructuresHeader;
