export const sitelinkConst = {
  FETCH_PROMOTION_SITELINKS_FILE_BEGIN: 'FETCH_PROMOTION_SITELINKS_FILES_BEGIN',
  FETCH_PROMOTION_SITELINKS_FILE_SUCCESS:
    'FETCH_PROMOTION_SITELINKS_FILE_SUCCESS',
  FETCH_PROMOTION_SITELINKS_FILE_FAILED:
    'FETCH_PROMOTION_SITELINKS_FILE_FAILED',
  FETCH_SITELINKS_MATCHED_FILE_BEGIN: 'FETCH_SITELINKS_MATCHED_FILE_BEGIN',
  FETCH_SITELINKS_MATCHED_FILE_SUCCESS: 'FETCH_SITELINKS_MATCHED_FILE_SUCCESS',
  FETCH_SITELINKS_MATCHED_FILE_FAILED: 'FETCH_SITELINKS_MATCHED_FILE_FAILED',
  UPLOAD_PROMOTION_SITELINKS_FILE_BEGIN:
    'UPLOAD_PROMOTION_SITELINKS_FILE_BEGIN',
  UPLOAD_PROMOTION_SITELINKS_FILE_SUCCESS:
    'UPLOAD_PROMOTION_SITELINKS_FILE_SUCCESS',
  UPLOAD_PROMOTION_SITELINKS_FILE_FAILED:
    'UPLOAD_PROMOTION_SITELINKS_FILE_FAILED',
};
