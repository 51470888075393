export const adContentConst = {
  DELETE_AD_CONTENT_FILE_BEGIN: 'DELETE_AD_CONTENT_FILE_BEGIN',
  DELETE_AD_CONTENT_FILE_SUCCESS: 'DELETE_AD_CONTENT_FILE_SUCCESS',
  DELETE_AD_CONTENT_FILE_FAILED: 'DELETE_AD_CONTENT_FILE_FAILED',
  DELETE_PROMOTION_SITELINKS_FILE_BEGIN:
    'DELETE_PROMOTION_SITELINKS_FILE_BEGIN',
  DELETE_PROMOTION_SITELINKS_FILE_SUCCESS:
    'DELETE_PROMOTION_SITELINKS_FILE_SUCCESS',
  DELETE_PROMOTION_SITELINKS_FILE_FAILED:
    'DELETE_PROMOTION_SITELINKS_FILE_FAILED',
  FETCH_AD_CONTENT_FILE_BEGIN: 'FETCH_AD_CONTENT_FILE_BEGIN',
  FETCH_AD_CONTENT_FILE_SUCCESS: 'FETCH_AD_CONTENT_FILE_SUCCESS',
  FETCH_AD_CONTENT_FILE_FAILED: 'FETCH_AD_CONTENT_FILE_FAILED',
  FETCH_AD_CONTENT_MATCHED_BEGIN: 'FETCH_AD_CONTENT_MATCHED_BEGIN',
  FETCH_AD_CONTENT_MATCHED_SUCCESS: 'FETCH_AD_CONTENT_MATCHED_SUCCESS',
  FETCH_AD_CONTENT_MATCHED_FAILED: 'FETCH_AD_CONTENT_MATCHED_FAILED',
  UPLOAD_AD_CONTENT_FILE_BEGIN: 'UPLOAD_AD_CONTENT_FILE_BEGIN',
  UPLOAD_AD_CONTENT_FILE_SUCCESS: 'UPLOAD_AD_CONTENT_FILE_SUCCESS',
  UPLOAD_AD_CONTENT_FILE_FAILED: 'UPLOAD_AD_CONTENT_FILE_FAILED',
};
