import CampaignAdGroups from 'components/CampaignAdGroups';
import CampaignTile from 'components/CampaignTile';
import { FeatureTileVariants } from 'components/FeatureTile';
import useFeatureTile from 'components/FeatureTile/FeatureTile.hook';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { CustomFeatureTileWrapper } from './CampaignBox.styled';

const CampaignBox = ({ campaignData }) => {
  const {
    dropdownHeight,
    expandCampaignBox,
    isBoxExpanded,
    isTileSearchOpen,
    setIsTileSearchOpen,
    isOptionsOpen,
    setIsOptionsOpen,
  } = useFeatureTile(FeatureTileVariants.campaign);

  if (!campaignData) return;

  return (
    <CustomFeatureTileWrapper
      isTileSearchOpen={isTileSearchOpen}
      dropdownHeight={dropdownHeight}
    >
      <CampaignTile
        campaignData={campaignData}
        expandCampaignBox={expandCampaignBox}
        isBoxExpanded={isBoxExpanded}
        isTileSearchOpen={isTileSearchOpen}
        setIsTileSearchOpen={setIsTileSearchOpen}
        isOptionsOpen={isOptionsOpen}
        setIsOptionsOpen={setIsOptionsOpen}
      />
      <CampaignAdGroups
        campaignData={campaignData}
        isBoxExpanded={isBoxExpanded}
      />
    </CustomFeatureTileWrapper>
  );
};

CampaignBox.propTypes = {
  campaignBoxType: PropTypes.string,
  campaignData: PropTypes.object,
};

export default memo(CampaignBox);
