import { Check } from '@styled-icons/boxicons-regular/Check';
import { ListHeader } from 'components/TableItems/TableItems.styled';
import {
  TableCell,
  TableColumnHeader,
} from 'components/TableItems/TableItems.styled';
import styled, { css } from 'styled-components';

export const SkuTableRow = styled.div`
  display: flex;
  height: 4.0858vh;
  background: ${({ theme }) => theme.pallete.tableRowsHighlight};
  font-size: 0.6rem;
  line-height: 0.9rem;
  letter-spacing: 0;
  font-weight: 400;

  &:nth-child(odd) {
    background: ${({ theme }) => theme.pallete.primaryDisabled};
  }

  &:hover {
    background-color: ${({ theme }) => theme.pallete.tableRowHover};
  }

  span {
    color: ${({ theme }) => theme.pallete.dropdownTextColor};
    font-weight: 500;
  }
`;

export const DisableCheck = styled(Check)`
  height: 3.064vh;
  color: ${({ theme, disabledRow }) =>
    disabledRow ? theme.pallete.placeholderText : theme.pallete.primary};
`;

export const SkuTableColumnHeader = styled(TableColumnHeader)`
  min-width: 80px;
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
      text-align: center;
    `};
`;

export const RowsContainer = styled.div`
  display: flex;
  height: 80vh;
  overflow: auto;
  flex-direction: column;

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.pallete.primaryHover};
  }
`;

export const FilterListHeader = styled(ListHeader)`
  max-height: 40px;
  padding: 5px 0;
  border-bottom: 2px solid ${({ theme }) => theme.pallete.inputsBorder};
  color: #000;
`;

export const SkuTableCell = styled(TableCell)`
  min-width: 80px;
  padding: 0 2px;
  ${({ center }) => center && 'justify-content: center'};
`;
