import { LoadFileModal } from 'components';
import { LoadedFileComponent } from 'components/LoadedFileComponent';
import useToasts from 'hooks/useToasts';
import { useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdContentFileAction } from 'store/actions/adContent/deleteAdContentFile';
import { uploadAdContentFileAction } from 'store/actions/adContent/uploadAdContentFile';
import { hideModal } from 'store/actions/modalActions/ModalVisibility';
import { getUploadedFileType } from 'store/selectors/modalSelectors';
import { getAdContentFile } from 'store/selectors/promotionsSelectors';
import useContentImplementation from 'views/ContentImplementation/ContentImplementation.hook';

const ImportAdContentModal = () => {
  const dispatch = useDispatch();
  const adContentFile = useSelector(getAdContentFile);
  const uploadedFileType = useSelector(getUploadedFileType);
  const {
    fetchPromotionAdContentFileData,
    promotionId,
  } = useContentImplementation();
  const {
    closeStaticToast,
    showRequestResultsToast,
    showStaticToast,
  } = useToasts();
  const [showUploadField, setShowUploadField] = useState(!adContentFile);

  const sendAdContentFile = useCallback(async files => {
    showStaticToast('Uploading ad content file...');
    const fileToUpload = files[0];
    const formData = new FormData();

    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('type', uploadedFileType);

    if (adContentFile) {
      await dispatch(deleteAdContentFileAction(promotionId, adContentFile.id));
    }

    const results = await dispatch(
      uploadAdContentFileAction(promotionId, formData),
    );

    if (results?.error === 'resolved') return closeStaticToast();

    showRequestResultsToast(
      results,
      `Upload failed! ${results?.error?.non_field_errors}`,
      'Ad content file has been uploaded!',
      fetchPromotionAdContentFileData,
    );
    dispatch(hideModal());
  });

  return (
    <LoadFileModal
      onDropAction={sendAdContentFile}
      loadedFileComponent={
        <LoadedFileComponent
          headerMessage={`Loaded ${uploadedFileType} file: `}
          contentMessage={adContentFile?.file}
          onButtonClick={() => setShowUploadField(prevState => !prevState)}
          buttonText={`Load new ${uploadedFileType} file`}
        />
      }
      showUploadField={showUploadField}
    >
      Import file
    </LoadFileModal>
  );
};

export default ImportAdContentModal;
