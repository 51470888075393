import { StyledFileIcon } from 'components/Modal/Modal.styled';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import {
  DragAndDropContainer,
  DragAndDropContentWrapper,
} from './DragAndDropContainer.styled';

const DragAndDropFileUpload = ({ onDropAction, children }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropAction });
  return (
    <DragAndDropContainer {...getRootProps({ className: 'dropzone' })}>
      <DragAndDropContentWrapper>
        <StyledFileIcon />
        <input {...getInputProps()} />
        <span>{children}</span>
      </DragAndDropContentWrapper>
    </DragAndDropContainer>
  );
};

DragAndDropFileUpload.propTypes = {
  onDropAction: PropTypes.func,
  children: PropTypes.string,
};

export default DragAndDropFileUpload;
