import { StructuresHeader, StructuresSteps } from 'components';
import useAccess from 'hooks/useAccess';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'theme/theme';

import {
  CollapseArrow,
  CollapseButton,
  StructureContentWrapper,
  StructuresContainerBackground,
  StructuresContainerWrapper,
} from './StrucuresContainer.styled';

const StructuresContainer = ({ children, currentStep }) => {
  useAccess();
  const [collapseSteps, setCollapsedSteps] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: `${breakpoints.lg}px` });

  return (
    <>
      <StructuresHeader />
      <StructuresContainerWrapper>
        {!isMobile && (
          <StructuresSteps
            currentStep={currentStep}
            collapseSteps={collapseSteps}
          />
        )}
        <StructureContentWrapper collapsed={collapseSteps}>
          {!isMobile && (
            <CollapseButton onClick={() => setCollapsedSteps(!collapseSteps)}>
              <CollapseArrow $collapsed={collapseSteps} />
            </CollapseButton>
          )}
          {children}
        </StructureContentWrapper>
        <StructuresContainerBackground />
      </StructuresContainerWrapper>
    </>
  );
};

StructuresContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentStep: PropTypes.number,
};

export default StructuresContainer;
