import { fromJS, List, Map } from 'immutable';
import { historyConst } from 'store/constants/historyConst';

const initialState = Map({
  historySteps: List([]),
  historyStepIndex: 0,
});

const setHistorySteps = (state, { historyStep }) => {
  const currentHistorySteps = state.get('historySteps').toJS();
  currentHistorySteps?.push(historyStep);

  if (currentHistorySteps?.length === 11) {
    const slicedHistory = currentHistorySteps.slice(1, 11);
    return state.set('historySteps', fromJS(slicedHistory));
  }
  return state
    .set('historySteps', fromJS(currentHistorySteps))
    .set('historyStepIndex', currentHistorySteps.length);
};

const clearHistorySteps = state => state.set('historySteps', fromJS([]));

const updateHistorySteps = (state, { historyStep }) => {
  const currentHistorySteps = state.get('historySteps').toJS();
  const updatedHistorySteps = currentHistorySteps.map(step => {
    if (
      (step.campaign && step.campaign === historyStep?.campaign) ||
      (step.ad_group && step.ad_group === historyStep?.ad_group) ||
      (step.keyword && step.keyword === historyStep?.keyword)
    ) {
      step.id = historyStep.id;
    }
    return step;
  });
  return state.set('historySteps', fromJS(updatedHistorySteps));
};

const setHistoryStepIndex = (state, { historyStepIndex }) =>
  state.set('historyStepIndex', historyStepIndex);

export const historyReducer = (state = initialState, action) =>
  ((
    {
      [historyConst.CLEAR_HISTORY_STEPS]: clearHistorySteps,
      [historyConst.SET_HISTORY_STEPS]: setHistorySteps,
      [historyConst.UPDATE_HISTORY_STEPS]: updateHistorySteps,
      [historyConst.SET_HISTORY_STEP_INDEX]: setHistoryStepIndex,
    }[action.type] || (fn => fn)
  )(state, action));
