import { Button } from 'components';
import { ReactComponent as ArrowDash } from 'media/svg/icons/arrowDash.svg';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 58.8%;
  margin-top: 0.8%;
  position: relative;
  height: 100%;
`;

export const StructureBtnsContainer = styled.div`
  display: flex;
  margin-top: 2.8%;
  margin-bottom: 2.55vh;

  & button:not(:first-child) {
    margin-left: 2%;
  }
`;

export const Dropdown = styled.button`
  background: white;
  padding: 0;
  text-align: unset;
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.pallete.primary};
  border-radius: 4px;
  width: 99.8%;
  height: 9vh;

  &:hover {
    background: ${({ theme }) => theme.pallete.primaryDisabled};
    border-color: ${({ theme }) => theme.pallete.primary};
  }

  @media screen and (max-height: 950px) {
    span {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media screen and (max-height: 660px) {
    height: 7vh;
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }

  @media screen and (max-height: 550px) {
    span {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

export const DropdownContent = styled.div`
  display: ${({ collapsed }) => (collapsed ? 'block' : 'none')};
  position: absolute;
  border: 1px solid black;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 105%;
  background: white;
  border: 1px solid ${({ theme }) => theme.pallete.primary};
`;

export const DropdownItem = styled.span`
  padding: 2% 2.5%;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  margin: auto 0;
  overflow: hidden;

  &:hover {
    background: ${({ theme }) => theme.pallete.primaryDisabled};
  }
`;

export const DropdownHint = styled.span`
  display: block;
  color: ${({ theme }) => theme.pallete.placeholderText};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;

  @media ${props => props.theme.media.desktop} {
    font-size: 10px;
    line-height: 18px;
  }
`;

export const DropdownArrow = styled(ArrowDash)`
  height: 10%;
  width: 5%;
  padding: 0 2.2%;
  margin: auto 0;
  transition: 0.5s;
  ${({ $collapsed }) => $collapsed && 'transform: rotate(180deg);'}
`;

export const GhostButton = styled(Button)`
  width: 100%;
  border-color: ${({ theme, hover }) =>
    hover ? theme.pallete.primary : theme.pallete.primary};
  color: ${({ theme, hover }) => (hover ? theme.pallete.primary : 'black')};
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0;
  text-align: center;
  height: 9vh;
  max-height: 72px;
  ${({ theme, hover }) =>
    hover &&
    css`
      background: ${theme.pallete.primary}; 
      color ${theme.pallete.primaryHover};
    `};

  &:hover {
    color: ${({ theme }) => theme.pallete.primary};
    background: ${({ theme }) => theme.pallete.primaryHover};
  }

  @media screen and (max-height: 950px) {
    height: 6vh;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ViewTitle = styled.span`
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.25rem;
  letter-spacing: 0;
  text-align: left;
  margin-top: 2%;
  margin-bottom: 3%;
`;

export const ViewDescription = styled.span`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-align: left;
`;

export const PromotionContainer = styled.div`
  height: 38.4vh;
`;

export const StyledNextViewButton = styled(Button)`
  @media screen and (max-height: 920px) {
    height: 5vh;
    width: 10.21vw;
  }
`;
