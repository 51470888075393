import { SitelinksCheckCell } from 'components/SitelinksTable/SitelinksTable.styled';
import { DisableCheck } from 'components/SkuFilterModal/FilteredAdGroupTable/FilteredAdGroupTable.styled';
import { useState } from 'react';

const SitelinkCheck = () => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <SitelinksCheckCell onClick={() => setIsChecked(prevState => !prevState)}>
      <DisableCheck disabledRow={isChecked} />
    </SitelinksCheckCell>
  );
};

export default SitelinkCheck;
