import { SkuTableRow } from 'components/SkuFilterModal/FilteredAdGroupTable/FilteredAdGroupTable.styled';
import styled from 'styled-components';

export const ScrollableDiv = styled.div`
  overflow: auto;
  width: fit-content;
`;

export const SitelinksTableCell = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  color: #000000;
  padding: 0 3px;
  min-width: 60px;
`;

export const SitelinksCheckCell = styled(SitelinksTableCell)`
  max-width: 60px;
  text-overflow: ellipsis;
  text-align: center;
  justify-content: center;
`;

export const StaticDataConatiner = styled(ScrollableDiv)`
  overflow-y: auto;
  flex-basis: 45%;
  margin-right: 9px;
  box-shadow: 9px 0 4px -2px rgba(176, 176, 176, 0.56);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DynamicTableWrapper = styled(ScrollableDiv)`
  display: flex;
  flex-basis: 55%;
`;

export const DynamicDataContainer = styled(ScrollableDiv)`
  padding: 0 1%;
  width: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
`;

export const SitelinksTableRow = styled(SkuTableRow)`
  overflow: auto;
  justify-content: space-between;
`;

export const SitelinksTablesWrapper = styled(ScrollableDiv)`
  display: flex;
  overflow-x: auto;
  width: inherit;
`;

export const DynamicScrollableDiv = styled(ScrollableDiv)`
  min-width: 100%;
`;
