import axios from 'axios';
import { adGroupConst } from 'store/constants/adGroupConst';
import { handleError } from 'utils';

export const removePromotionAdGroupBegin = () => ({
  type: adGroupConst.REMOVE_PROMOTION_ADGROUP_BEGIN,
});

export const removePromotionAdGroupSuccess = () => ({
  type: adGroupConst.REMOVE_PROMOTION_ADGROUP_SUCCESS,
});

export const removePromotionAdGroupFailed = () => ({
  type: adGroupConst.REMOVE_PROMOTION_ADGROUP_FAILED,
});

export const removePromotionAdGroup = (
  promotionId,
  adGroupId,
) => async dispatch => {
  dispatch(removePromotionAdGroupBegin());
  try {
    await axios.delete(`/ga/ps_ad_group/${promotionId}/${adGroupId}/`);
    dispatch(removePromotionAdGroupSuccess());
  } catch (error) {
    dispatch(removePromotionAdGroupFailed());
    return handleError(error, 'Remove promotion ad group');
  }
};
