export const adGroupConst = {
  ADD_NEW_ADGROUP_BEGIN: 'ADD_NEW_ADGROUP_BEGIN',
  ADD_NEW_ADGROUP_SUCCESS: 'ADD_NEW_ADGROUP_SUCCESS',
  ADD_NEW_ADGROUP_FAILED: 'ADD_NEW_ADGROUP_FAILED',
  ADD_ADGROUP_TO_PROMOTION_BEGIN: 'ADD_ADGROUP_TO_PROMOTION_BEGIN',
  ADD_ADGROUP_TO_PROMOTION_SUCCESS: 'ADD_ADGROUP_TO_PROMOTION_SUCCESS',
  ADD_ADGROUP_TO_PROMOTION_FAILED: 'ADD_ADGROUP_TO_PROMOTION_FAILED',
  FETCH_CAMPAIGN_ADGROUPS_BEGIN: 'FETCH_CAMPAIGN_ADGROUPS_BEGIN',
  FETCH_CAMPAIGN_ADGROUPS_SUCCESS: 'FETCH_CAMPAIGN_ADGROUPS_SUCCESS',
  FETCH_CAMPAIGN_ADGROUPS_FAILED: 'FETCH_CAMPAIGN_ADGROUPS_FAILED',
  REMOVE_PROMOTION_ADGROUP_BEGIN: 'REMOVE_PROMOTION_ADGROUP_BEGIN',
  REMOVE_PROMOTION_ADGROUP_SUCCESS: 'REMOVE_PROMOTION_ADGROUP_SUCCESS',
  REMOVE_PROMOTION_ADGROUP_FAILED: 'REMOVE_PROMOTION_ADGROUP_FAILED',
  SET_ADDED_ADGROUPS: 'SET_ADDED_ADGROUPS',
};
