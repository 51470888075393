import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const deleteAdGroupLabelActionBegin = () => ({
  type: promotionConst.DELETE_ADGROUP_LABEL_BEGIN,
});

export const deleteAdGroupLabelActionSuccess = () => ({
  type: promotionConst.DELETE_ADGROUP_LABEL_SUCCESS,
});

export const deleteAdGroupLabelActionFailed = () => ({
  type: promotionConst.DELETE_ADGROUP_LABEL_FAILED,
});

export const deleteAdGroupLabelAction = (
  promotionId,
  labelId,
) => async dispatch => {
  dispatch(deleteAdGroupLabelActionBegin());
  try {
    await axios.delete(`/ga/ad_content_label/${promotionId}/${labelId}/`);
    dispatch(deleteAdGroupLabelActionSuccess());
  } catch (error) {
    dispatch(deleteAdGroupLabelActionFailed());
    return handleError(error, 'Delete Ad group label');
  }
};
