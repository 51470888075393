import { ReactComponent as ArrowDash } from 'media/svg/icons/arrowDash.svg';
import styled from 'styled-components';
import { breakpoints } from 'theme/theme';

export const StructuresContainerWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StructureContentWrapper = styled.div`
  background: ${({ theme }) => theme.pallete.tableRowsHighlight};
  position: relative;
  transition: 0.6s;
  padding: 0 6.458vw;
  height: fit-content;
  min-height: 89.759vh;
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: ${({ collapsed }) => (collapsed ? '94.792vw' : '66.25vw')};
  }
`;

export const CollapseArrow = styled(ArrowDash)`
  height: 12px;
  width: 12px;
  transition: 0.5s;
  transform: ${({ $collapsed }) =>
    $collapsed ? ' rotate(-90deg);' : ' rotate(90deg);'};
`;

export const CollapseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5.1vh;
  left: -16px;
  height: 32px;
  width: 32px;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.04);
  border: unset;
  border-radius: 50%;
  background: white;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  font-size: 1.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.pallete.importantTextColor};
`;

export const PromotionTitle = styled.span`
  text-decoration: underline;
  font-weight: 600;
  margin-left: 0.5rem;
`;

export const CampaignSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.lg}px) {
    justify-content: unset;
  }
`;

export const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5.1072vh;
  margin-top: 1%;
  min-height: 100%;
`;

export const StructuresContainerBackground = styled.div`
  background: aquamarine;
`;
