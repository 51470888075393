import styled from 'styled-components';

export const StyledSitelinksContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 93vw;
  height: 70vh;
  overflow: hidden;
  font-size: 1rem;
  flex-direction: column;
`;

export const StyledSitelinkMessageSpan = styled.span`
  margin: 20px;
  text-decoration: underline;
  font-size: 1.3rem;
`;
