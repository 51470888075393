import { useDispatch, useSelector } from 'react-redux';
import { addNewKeywordAction } from 'store/actions/keywordsActions/addNewKeywordAction';
import { fetchKeywords } from 'store/actions/keywordsActions/fetchKeywords';
import { addKeywordToPromotionAction } from 'store/actions/promotionActions/addKeywordToPromotion';
import { fetchPromotionKeywords } from 'store/actions/promotionActions/fetchPromotionKeywords';
import { removePromotionKeyword } from 'store/actions/promotionActions/removePromotionKeywordAction';
import { getSelectedPromotionId } from 'store/selectors/promotionsSelectors';
import { getUserAccountDetails } from 'store/selectors/userSelectors';

const useKeywords = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector(getUserAccountDetails);
  const promotionId = useSelector(getSelectedPromotionId);

  const addNewKeyword = async keywordData =>
    dispatch(addNewKeywordAction(userId, keywordData));

  const addKeywordToPromotion = async keywordId =>
    dispatch(addKeywordToPromotionAction(promotionId, { keyword: keywordId }));

  const deleteKeywordFromPromotion = async keywordId =>
    dispatch(removePromotionKeyword(promotionId, keywordId));

  const fetchKeywordsHints = async (search, page, ad_group) =>
    dispatch(
      fetchKeywords(userId, {
        search,
        page,
        ad_group,
        exclude_promotion: promotionId,
      }),
    );

  const fetchAdGroupKeywords = async keyword__ad_group =>
    dispatch(fetchPromotionKeywords(promotionId, { keyword__ad_group }));

  return {
    addNewKeyword,
    fetchKeywordsHints,
    fetchAdGroupKeywords,
    deleteKeywordFromPromotion,
    addKeywordToPromotion,
  };
};

export default useKeywords;
