import useAdGroups from 'hooks/useAdGroups';
import useCampaign from 'hooks/useCampaign';
import useEscape from 'hooks/useEscape';
import useKeywords from 'hooks/useKeywords';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNewFeaturesDraftAction } from 'store/actions/draftActions/updateDraftsActions';
import { setHistoryStep } from 'store/actions/historyActions/historySetters';
import { getDropdownHeight } from 'store/selectors/helpersSelectors';

import { FeatureTileVariants } from './FeatureTile.variants';
import { getNewAdGroupData, getNewKeywordData } from './FeatureTileConst';

const useFeatureTile = tileType => {
  useEscape(() => setIsTileSearchOpen(false));

  const [isBoxExpanded, setIsBoxExpanded] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isTileSearchOpen, setIsTileSearchOpen] = useState(false);
  const [isTilePaused, setIsTilePaused] = useState(false);
  const dropdownHeight = useSelector(getDropdownHeight);

  const {
    deleteCampaignFromPromotion,
    fetchAddedPromotionCampaigns,
  } = useCampaign();
  const { deletePromotionAdGroup, fetchCampaignsAdGroups } = useAdGroups();
  const { deleteKeywordFromPromotion, fetchAdGroupKeywords } = useKeywords();
  const dispatch = useDispatch();

  const addNewTileToDraft = async newTileData =>
    dispatch(updateNewFeaturesDraftAction(newTileData));

  const addTileAction = async (parentId, psParentId) => {
    let newTileData = getNewAdGroupData(psParentId, parentId);
    if (tileType === FeatureTileVariants.adGroup)
      newTileData = getNewKeywordData(psParentId, parentId);
    await addNewTileToDraft(newTileData);
    if (!isBoxExpanded) {
      expandCampaignBox(parentId);
    }
  };

  const deleteTileAction = async (parentId, childId, featureId) => {
    if (tileType === FeatureTileVariants.campaign) {
      await deleteCampaignFromPromotion(childId);
      dispatch(
        setHistoryStep({
          id: childId,
          campaign: parentId,
          action: 'delete',
          featureType: 'campaign',
        }),
      );
      return fetchAddedPromotionCampaigns();
    }
    if (tileType === FeatureTileVariants.adGroup) {
      await deletePromotionAdGroup(childId, featureId);
      dispatch(
        setHistoryStep({
          id: childId,
          ad_group: featureId,
          action: 'delete',
          featureType: 'adGroup',
          parentId,
        }),
      );
      return fetchCampaignsAdGroups({
        campaignId: parentId,
        addToHistory: true,
      });
    }
    if (tileType === FeatureTileVariants.keyword) {
      await deleteKeywordFromPromotion(childId);
      dispatch(
        setHistoryStep({
          id: childId,
          keyword: featureId,
          action: 'delete',
          featureType: 'keyword',
          parentId: parentId,
        }),
      );
      return fetchAdGroupKeywords(parentId, { addToHistory: true });
    }
  };

  const expandCampaignBox = async campaignId => {
    if (isBoxExpanded) return setIsBoxExpanded(false);
    await fetchCampaignsAdGroups({ campaignId });
    setIsBoxExpanded(true);
  };

  useEffect(() => {
    if (isOptionsOpen) setIsOptionsOpen(false);
  }, [isTileSearchOpen]);

  return {
    addTileAction,
    dropdownHeight,
    deleteTileAction,
    isBoxExpanded,
    setIsBoxExpanded,
    isOptionsOpen,
    setIsOptionsOpen,
    isTileSearchOpen,
    setIsTileSearchOpen,
    isTilePaused,
    setIsTilePaused,
    expandCampaignBox,
  };
};

export default useFeatureTile;
