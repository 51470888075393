import useCampaign from 'hooks/useCampaign';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCampaignToPromotion } from 'store/actions/campaignActions/addCampaignToPromotionAction';
import { fetchCampaigns } from 'store/actions/campaignActions/fetchCampaignsAction';
import { setHistoryStep } from 'store/actions/historyActions/historySetters';
import { getSelectedPromotionId } from 'store/selectors/promotionsSelectors';
import { getUserId } from 'store/selectors/userSelectors';

const useCampaignSearch = () => {
  const dispatch = useDispatch();

  const userId = useSelector(getUserId);
  const promotionId = useSelector(getSelectedPromotionId);
  const { fetchAddedPromotionCampaigns } = useCampaign();

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [fetchedCampaigns, setFetchedCampaigns] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addCampaignToPromotion = async ({ id: campaignId }) => {
    setShowDropdown(false);
    setIsLoading(true);
    const response = await dispatch(
      addNewCampaignToPromotion(promotionId, {
        campaign: campaignId,
        with_contents: true,
      }),
    );
    dispatch(
      setHistoryStep({
        ...response,
        action: 'add',
        featureType: 'campaign',
      }),
    );
    await fetchAddedPromotionCampaigns();
    await fetchCampaignsHints(1);
    setIsLoading(false);
    setSearchQuery('');
  };

  const fetchCampaignsHints = async page => {
    const data = await dispatch(
      fetchCampaigns(userId, {
        search: searchQuery,
        page,
        exclude_promotion: promotionId,
      }),
    );

    if (!data) return;

    let { results } = data;

    if (page > 1) {
      results = [...fetchedCampaigns.results, ...data.results];
    }

    setFetchedCampaigns({
      next: data.next,
      results,
    });
  };

  useEffect(() => {
    const timer = setTimeout(async () => fetchCampaignsHints(), 600);
    if (searchQuery) setShowDropdown(true);
    return () => clearTimeout(timer);
  }, [searchQuery, userId]);

  return {
    addCampaignToPromotion,
    fetchedCampaigns,
    showDropdown,
    setShowDropdown,
    searchQuery,
    setSearchQuery,
    fetchCampaignsHints,
    isLoading,
  };
};

export default useCampaignSearch;
