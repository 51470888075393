import { KeyboardArrowLeft } from '@styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from '@styled-icons/material/KeyboardArrowRight';
import { buttonVariants } from 'components/Button';
import {
  Loader,
  StyledLoaderContainer,
  StyledLoaderMessage,
} from 'components/Loader';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { withTheme } from 'styled-components';

import FilteredAdgroupTable from '../FilteredAdGroupTable/FilteredAdgroupTable';
import {
  ActionButtonsContainer,
  FilterToolbar,
  FilterToolbarButton,
  SwitchLabel,
} from '../SkuFilterModal.styled';
import useSkuFilterContent from './SkuFilterContent.hook';

const SkuFilterContent = ({ theme, setFileLoaded }) => {
  const {
    confirmFiltration,
    hasNext,
    hasPrevious,
    fetchAllAdGroups,
    fetchMoreAdGroups,
    onChangeSelection,
    onFiltrationTypeChange,
    removeLastFetchedAdGroups,
    unselectedRows,
    isFetching,
    listSize,
    isFilteredByProductNames,
  } = useSkuFilterContent();

  return (
    <>
      <FilterToolbar>
        <SwitchLabel>
          <span>Filter by product names similarity</span>
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={theme.pallete.primary}
            onHandleColor={'#FFF'}
            handleDiameter={15}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={30}
            onChange={onFiltrationTypeChange}
            checked={isFilteredByProductNames}
          />
        </SwitchLabel>
        <FilterToolbarButton
          variant={buttonVariants.SECONDARY}
          onClick={() => setFileLoaded(false)}
        >
          Load new file
        </FilterToolbarButton>
        <FilterToolbarButton onClick={confirmFiltration}>
          Submit
        </FilterToolbarButton>
      </FilterToolbar>
      {isFetching ? (
        <StyledLoaderContainer height="100%">
          <Loader />
          <StyledLoaderMessage>Fetching data...</StyledLoaderMessage>
        </StyledLoaderContainer>
      ) : (
        <FilteredAdgroupTable
          filterProductNames={isFilteredByProductNames}
          unselectedRows={unselectedRows}
          onChangeSelection={onChangeSelection}
        />
      )}
      <ActionButtonsContainer>
        <FilterToolbarButton
          disabled={!hasPrevious}
          onClick={removeLastFetchedAdGroups}
          variant={buttonVariants.SECONDARY}
        >
          <KeyboardArrowLeft height="55%" />
          <span>Remove added </span>
        </FilterToolbarButton>
        <FilterToolbarButton
          onClick={fetchAllAdGroups}
          variant={buttonVariants.SECONDARY}
          disabled={!listSize || listSize < 50}
        >
          <span>{`Add all (${listSize})`} </span>
        </FilterToolbarButton>
        <FilterToolbarButton
          disabled={!hasNext || !listSize || listSize < 50}
          onClick={fetchMoreAdGroups}
          variant={buttonVariants.SECONDARY}
        >
          <span>Add more</span>
          <KeyboardArrowRight height="55%" />
        </FilterToolbarButton>
      </ActionButtonsContainer>
    </>
  );
};

SkuFilterContent.propTypes = {
  theme: PropTypes.object,
  isProcessing: PropTypes.bool,
  setFileLoaded: PropTypes.func,
};

export default withTheme(SkuFilterContent);
