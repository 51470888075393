import axios from 'axios';
import queryString from 'query-string';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

const fetchPromotionAdGroupsBegin = () => ({
  type: promotionConst.FETCH_PROMOTION_ADGROUP_BEGIN,
});

const fetchPromotionAdGroupsSuccess = () => ({
  type: promotionConst.FETCH_PROMOTION_ADGROUP_SUCCESS,
});

const fetchPromotionAdGroupsFailed = () => ({
  type: promotionConst.FETCH_PROMOTION_ADGROUP_FAILED,
});

export const fetchPromotionAdGroups = (
  promotionId,
  query,
) => async dispatch => {
  const stringifiedQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  dispatch(fetchPromotionAdGroupsBegin);

  try {
    const { data } = await axios.get(
      `/ga/ps_ad_group/${promotionId}/${
        stringifiedQuery && `?${stringifiedQuery}`
      }`,
    );
    dispatch(fetchPromotionAdGroupsSuccess());
    return data;
  } catch (error) {
    dispatch(fetchPromotionAdGroupsFailed());
    return handleError(error, 'Fetching promotion ad groups');
  }
};
