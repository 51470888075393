import styled from 'styled-components';

export const LoginInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.5% 0;
`;

export const LoginViewWrapper = styled.div`
  overflow: hidden;
  min-height: 450px;
`;
