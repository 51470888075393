import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { getModalProps } from 'store/selectors/modalSelectors';

import { useModal } from './Modal.hook';
import { StyledReactModal } from './Modal.styled';

const Modal = ({ modalProps }) => {
  const { hideModalHandler, modalChild: ModalChild } = useModal();

  const { isModalOpen } = modalProps.toJS();
  if (!isModalOpen) return null;

  return (
    <StyledReactModal isOpen={isModalOpen} onRequestClose={hideModalHandler}>
      {ModalChild}
    </StyledReactModal>
  );
};

const mapStateToProps = state => ({
  modalProps: getModalProps(state),
});

Modal.propTypes = {
  modalProps: ImmutablePropTypes.map,
};

export default connect(mapStateToProps, null)(Modal);
