import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPromotion } from 'store/actions/promotionActions/promotionSetters';
import { deletePromotionAction } from 'store/actions/userActions/promotions/deletePromotion';
import { getSelectedPromotion } from 'store/selectors/promotionsSelectors';
import { getUserId } from 'store/selectors/userSelectors';

export const usePromotionList = dataSource => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPromotionDetails, setCurrentPromotionDetails] = useState(null);
  const selectedPromotion = useSelector(getSelectedPromotion);
  const userId = useSelector(getUserId);

  const deletePromotion = async promotionId =>
    dispatch(deletePromotionAction({ accountId: userId, promotionId }));

  useEffect(() => {
    const selectedPromotionId = selectedPromotion.get('id');
    if (!selectedPromotionId) return;
    setSelectedRow(selectedPromotionId);
  }, [selectedPromotion]);

  useEffect(() => {
    if (!dataSource) return;
    setCurrentPromotionDetails(dataSource.find(({ id }) => id === selectedRow));
  }, [selectedRow]);

  useEffect(() => {
    if (!currentPromotionDetails) return;
    dispatch(setSelectedPromotion(currentPromotionDetails));
  }, [currentPromotionDetails]);

  return {
    deletePromotion,
    selectedRow,
    setSelectedRow,
  };
};
