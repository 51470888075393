import { useDispatch, useSelector } from 'react-redux';
import {
  setHasNext,
  setIsLoading,
} from 'store/actions/helpersActions/helpersSetters';
import { initLabelsAction } from 'store/actions/promotionActions/initLabels';
import { clearAdGroupsListToFilter } from 'store/actions/promotionActions/promotionSetters';
import {
  getAdGroupsListToFilterLength,
  getSelectedPromotionId,
} from 'store/selectors/promotionsSelectors';
import { getUserId } from 'store/selectors/userSelectors';

import useAdGroups from './useAdGroups';
import useToasts from './useToasts';

const useAdGroupsData = () => {
  const { fetchAdGroupsToFilter } = useAdGroups();
  const { closeStaticToast, showFetchingToast } = useToasts();
  const promotionId = useSelector(getSelectedPromotionId);
  const accountId = useSelector(getUserId);
  const filteredAdGroupListSize = useSelector(getAdGroupsListToFilterLength);

  const dispatch = useDispatch();

  const fetchAdGroupsData = async (query, { showToast = false } = {}) => {
    if (showToast && query?.page !== 1) showFetchingToast();
    const response = await fetchAdGroupsToFilter({ query });
    dispatch(setIsLoading(false));
    closeStaticToast();
    if (!response) return;
    dispatch(setHasNext(!!response.next));
    return response;
  };

  const initAdGroupFetching = async () => {
    if (!promotionId) return;
    await dispatch(initLabelsAction(promotionId, accountId));
    dispatch(setIsLoading(true));
    if (filteredAdGroupListSize) dispatch(clearAdGroupsListToFilter());
    await fetchAdGroupsData({ page: 1 });
  };

  return {
    fetchAdGroupsData,
    initAdGroupFetching,
    showFetchingToast,
  };
};

export default useAdGroupsData;
