import { useEffect } from 'react';

const useEscape = callback => {
  const escFunction = event => {
    if (event.keyCode === 27) callback();
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction, false);
  });
};

export default useEscape;
