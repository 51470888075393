import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const addKeywordToPromotionBegin = () => ({
  type: promotionConst.ADD_KEYWORD_TO_PROMOTION_BEGIN,
});

export const addKeywordToPromotionSuccess = () => ({
  type: promotionConst.ADD_KEYWORD_TO_PROMOTION_SUCCESS,
});

export const addKeywordToPromotionFailed = () => ({
  type: promotionConst.ADD_KEYWORD_TO_PROMOTION_FAILED,
});

export const addKeywordToPromotionAction = (
  promotionId,
  keywordData,
) => async dispatch => {
  dispatch(addKeywordToPromotionBegin());
  try {
    const { data } = await axios.post(`/ga/ps_keyword/${promotionId}/`, {
      ...keywordData,
    });
    dispatch(addKeywordToPromotionSuccess());
    return data;
  } catch (error) {
    dispatch(addKeywordToPromotionFailed());
    return handleError(error, 'Add keyword to promotion');
  }
};
