import styled from 'styled-components';

export const ValidatedInput = styled.input`
  border-color: ${({ isValid, theme }) =>
    isValid ? theme.pallete.inputsBorder : theme.pallete.errorTextColor}};
  color: ${({ isValid, theme }) =>
    isValid ? 'black' : theme.pallete.errorTextColor};

  &::placeholder {
    color: ${({ isValid, theme }) =>
      isValid ? theme.pallete.placeholderText : theme.pallete.errorTextColor};
  }
`;
