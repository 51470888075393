import styled from 'styled-components';

export const BrandLogoWrapper = styled.div`
  display: flex;
`;

export const LogoImage = styled.img`
  max-width: 160px;
  max-height: 90px;
  width: auto;
`;
