import { helpersConst } from 'store/constants/helpersConst';

export const setDropdownHeightAction = dropdownHeight => ({
  type: helpersConst.SET_DROPDOWN_HEIGHT,
  dropdownHeight,
});

export const setCampaignBoxTypeAction = campaignBoxType => ({
  type: helpersConst.SET_CAMPAIGN_BOX_TYPE,
  campaignBoxType,
});

export const setHasNext = hasNext => ({
  type: helpersConst.SET_HAS_NEXT,
  hasNext,
});

export const setIsLoading = isLoading => ({
  type: helpersConst.SET_IS_LOADING,
  isLoading,
});

export const setSearchQuery = searchQuery => ({
  type: helpersConst.SET_SEARCH_QUERY,
  searchQuery,
});
