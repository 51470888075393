import { useDispatch, useSelector } from 'react-redux';
import { addAdGroupToPromotionAction } from 'store/actions/adGroupActions/addAdGroupToPromotionAction';
import { addNewAdGroupAction } from 'store/actions/adGroupActions/addNewAdGroupAction';
import { fetchKeywords as fetchAdGroupsByKeyword } from 'store/actions/keywordsActions/fetchKeywords';
import { fetchPromotionAdGroups } from 'store/actions/promotionActions/fetchPromotionAdGroups';
import {
  setAdGroupsListToFilter,
  setFetchedPromotionAdGroups,
  updateAdGroupsListToFilter,
} from 'store/actions/promotionActions/promotionSetters';
import { removePromotionAdGroup } from 'store/actions/promotionActions/removePromotionAdGroupAction';
import { getSelectedPromotionId } from 'store/selectors/promotionsSelectors';
import { getUserAccountDetails } from 'store/selectors/userSelectors';

const useAdGroups = () => {
  const dispatch = useDispatch();
  const promotionId = useSelector(getSelectedPromotionId);
  const { userId } = useSelector(getUserAccountDetails);

  const fetchAdGroupsHints = async (search, page, ad_group__campaign) =>
    await dispatch(
      fetchAdGroupsByKeyword(userId, {
        page,
        ad_group__campaign,
        search,
        exclude_promotion: promotionId,
      }),
    );

  const fetchCampaignsAdGroupsAction = async ({
    campaignId,
    query = {},
  } = {}) =>
    dispatch(
      fetchPromotionAdGroups(promotionId, {
        ad_group__campaign: campaignId,
        ...query,
      }),
    );

  const fetchCampaignsAdGroups = async ({ campaignId, query }) => {
    const fetchedAdGroups = await fetchCampaignsAdGroupsAction({
      campaignId,
      query,
    });
    dispatch(setFetchedPromotionAdGroups(fetchedAdGroups?.results, campaignId));
  };

  const fetchAdGroupsToFilter = async ({ query }) => {
    const fetchedAdGroups = await fetchCampaignsAdGroupsAction({ query });
    if (query.page === 1) {
      await dispatch(setAdGroupsListToFilter(fetchedAdGroups?.results));
    } else {
      await dispatch(updateAdGroupsListToFilter(fetchedAdGroups?.results));
    }
    return fetchedAdGroups;
  };

  const addNewAdGroup = async adgroupData =>
    await dispatch(addNewAdGroupAction(userId, adgroupData));

  const addAdGroupToPromotion = async adGroupId =>
    await dispatch(
      addAdGroupToPromotionAction(promotionId, { ad_group: adGroupId }),
    );

  const deletePromotionAdGroup = async adGroupId =>
    await dispatch(removePromotionAdGroup(promotionId, adGroupId));

  return {
    addNewAdGroup,
    fetchCampaignsAdGroups,
    addAdGroupToPromotion,
    deletePromotionAdGroup,
    fetchAdGroupsHints,
    fetchAdGroupsToFilter,
  };
};

export default useAdGroups;
