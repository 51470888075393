import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const clearPromotionStructureBegin = () => ({
  type: promotionConst.CLEAR_PROMOTION_STRUCTURE_BEGIN,
});

export const clearPromotionStructureSuccess = () => ({
  type: promotionConst.CLEAR_PROMOTION_STRUCTURE_SUCCESS,
});

export const clearPromotionStructureFailed = () => ({
  type: promotionConst.CLEAR_PROMOTION_STRUCTURE_FAILED,
});

export const clearPromotionStructureAction = (
  accountId,
  promotionId,
) => async dispatch => {
  dispatch(clearPromotionStructureBegin());
  try {
    await axios.delete(
      `/ga/promotion_structure/${accountId}/${promotionId}/clear/`,
    );
    dispatch(clearPromotionStructureSuccess());
  } catch (error) {
    dispatch(clearPromotionStructureFailed());
    return handleError(error, 'Clear promotion structure');
  }
};
