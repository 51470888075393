import axios from 'axios';

const options = {
  headers: {
    'Content-Type': 'multipart/form-data;',
  },
};

import { sitelinkConst } from 'store/constants/sitelinksConst';
import { handleError } from 'utils';

const uploadPromotionSitelinksFileBegin = () => ({
  type: sitelinkConst.UPLOAD_PROMOTION_SITELINKS_FILE_BEGIN,
});

const uploadPromotionSitelinksFileSuccess = () => ({
  type: sitelinkConst.UPLOAD_PROMOTION_SITELINKS_FILE_SUCCESS,
});

const uploadPromotionSitelinksFileFailed = () => ({
  type: sitelinkConst.UPLOAD_PROMOTION_SITELINKS_FILE_FAILED,
});

export const uploadPromotionSitelinksFile = (
  promotionId,
  file,
) => async dispatch => {
  dispatch(uploadPromotionSitelinksFileBegin);
  try {
    await axios.post(`/ga/sitelink_file/${promotionId}/`, file, options);
    dispatch(uploadPromotionSitelinksFileSuccess());
  } catch (error) {
    dispatch(uploadPromotionSitelinksFileFailed());
    return { error: handleError(error, 'Upload promotion sitelinks file') };
  }
};
