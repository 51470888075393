import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearHistoryStep } from 'store/actions/historyActions/historySetters';
import { clearPromotionStructureAction } from 'store/actions/promotionActions/ClearPromotionStructureAction';
import { fetchPromotionCampaigns } from 'store/actions/promotionActions/fetchPromotionCampaigns';
import { getFetchedPromotionCampaigns } from 'store/selectors/promotionsSelectors';
import { getSelectedPromotion } from 'store/selectors/promotionsSelectors';
import { getUserAccountDetails } from 'store/selectors/userSelectors';

const useDeleteAllCampaigns = () => {
  const dispatch = useDispatch();
  const promotionCampaigns = useSelector(state =>
    getFetchedPromotionCampaigns(state),
  );
  const selectedPromotion = useSelector(state => getSelectedPromotion(state));
  const accountDetails = useSelector(getUserAccountDetails);
  const { userId } = accountDetails;
  const [isLoading, setIsLoading] = useState(false);

  const deleteAllPromotionsCampaigns = async () => {
    setIsLoading(true);
    const promotionId = selectedPromotion.get('id');
    await dispatch(clearPromotionStructureAction(userId, promotionId));
    await dispatch(fetchPromotionCampaigns(promotionId));
    dispatch(clearHistoryStep());
    setIsLoading(false);
  };

  const deleteButtonDisability = Boolean(promotionCampaigns.size);
  const deleteButtonVisibility =
    window.location.href.split('/').pop() === 'structure';

  return {
    deleteButtonDisability,
    deleteButtonVisibility,
    deleteAllPromotionsCampaigns,
    isLoading,
  };
};

export default useDeleteAllCampaigns;
