import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

export const removePromotionCampaignBegin = () => ({
  type: promotionConst.REMOVE_PROMOTION_CAMPAIGN_BEGIN,
});

export const removePromotionCampaignnSuccess = () => ({
  type: promotionConst.REMOVE_PROMOTION_CAMPAIGN_SUCCESS,
});

export const removePromotionCampaignFailed = () => ({
  type: promotionConst.REMOVE_PROMOTION_CAMPAIGN_FAILED,
});

export const removePromotionCampaign = (
  promotionId,
  capmaignId,
) => async dispatch => {
  dispatch(removePromotionCampaignBegin());
  try {
    await axios.delete(`/ga/ps_campaign/${promotionId}/${capmaignId}/`);
    dispatch(removePromotionCampaignnSuccess());
  } catch (error) {
    dispatch(removePromotionCampaignFailed());
    return handleError(error, 'Remove promotion campaign');
  }
};
