import {
  TileBlock,
  TileTitle,
  TileTitleInput,
} from 'components/FeatureTile/FeatureTile.styles';
import styled from 'styled-components';

export const KeywordBlock = styled(TileBlock)`
  background: ${({ theme }) => theme.pallete.keywordColor};
`;

export const KeywordTitle = styled(TileTitle)`
  background: ${({ theme }) => theme.pallete.keywordColor};
  color: ${({ theme }) => theme.pallete.keywordTextColor};
`;

export const KeywordTitleInput = styled(TileTitleInput)`
  background: ${({ theme }) => theme.pallete.keywordColor};
  color: ${({ theme }) => theme.pallete.keywordTextColor};
`;
