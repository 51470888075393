import { DeleteButton, ToolbarButton } from 'components/Button/Button.styled';
import { ButtonLoader } from 'components/Loader/Loader.styled';
import useDeleteAllCampaigns from 'hooks/deleteAllCampaigns.hook';
import useHistorySteps from 'hooks/useHistorySteps';
import { ReactComponent as LeftCurvedArrow } from 'media/svg/icons/curvedArrow.svg';
import PropTypes from 'prop-types';
import React from 'react';

import {
  CustomDownloadIcon,
  RightCurvedArrow,
  StructuresToolbarWrappper,
  TrashIcon,
} from './StructuresToolbar.styled';

const StructuresToolbar = ({
  customSaveBtns,
  showUndoRedo,
  saveBtnAction,
  isSaveBtnDisabled,
}) => {
  const {
    deleteButtonDisability,
    deleteButtonVisibility,
    deleteAllPromotionsCampaigns,
    isLoading,
  } = useDeleteAllCampaigns();
  const {
    redoAction,
    redoButtonDisability,
    isLoadingHistory,
    undoAction,
    undoButtonDisability,
  } = useHistorySteps();

  return (
    <StructuresToolbarWrappper>
      {deleteButtonVisibility && (
        <DeleteButton
          onClick={deleteAllPromotionsCampaigns}
          disabled={!deleteButtonDisability || isLoading}
        >
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <>
              <TrashIcon />
              <span>Clear</span>
            </>
          )}
        </DeleteButton>
      )}
      {showUndoRedo && (
        <>
          <ToolbarButton
            disabled={!undoButtonDisability || isLoadingHistory}
            onClick={undoAction}
          >
            {isLoadingHistory ? <ButtonLoader /> : <LeftCurvedArrow />}
          </ToolbarButton>
          <ToolbarButton
            disabled={!redoButtonDisability || isLoadingHistory}
            forwardButton
            onClick={redoAction}
          >
            {isLoadingHistory ? <ButtonLoader /> : <RightCurvedArrow />}
          </ToolbarButton>
        </>
      )}
      {customSaveBtns || (
        <ToolbarButton
          disabled={!saveBtnAction || isSaveBtnDisabled}
          onClick={saveBtnAction}
          wide
        >
          <CustomDownloadIcon />
          Save to XLSX
        </ToolbarButton>
      )}
    </StructuresToolbarWrappper>
  );
};

StructuresToolbar.propTypes = {
  customSaveBtns: PropTypes.object,
  showUndoRedo: PropTypes.bool,
  saveBtnAction: PropTypes.func,
  isSaveBtnDisabled: PropTypes.bool,
};

export default StructuresToolbar;
