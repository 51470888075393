import axios from 'axios';
import queryString from 'query-string';
import { adContentConst } from 'store/constants/adContentConst';
import { handleError } from 'utils';

const fetchAdContentFileBegin = () => ({
  type: adContentConst.FETCH_AD_CONTENT_FILE_BEGIN,
});

const fetchAdContentFileSuccess = adContentFiles => ({
  type: adContentConst.FETCH_AD_CONTENT_FILE_SUCCESS,
  adContentFiles,
});

const fetchAdContentFileFailed = () => ({
  type: adContentConst.FETCH_AD_CONTENT_FILE_FAILED,
});

export const fetchAdContentFileAction = (
  promotionId,
  query,
) => async dispatch => {
  const stringifiedQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  dispatch(fetchAdContentFileBegin);

  try {
    const { data: fetchedAdContentFiles } = await axios.get(
      `ga/ad_content_file/${promotionId}/${
        stringifiedQuery && `?${stringifiedQuery}`
      }`,
    );
    dispatch(fetchAdContentFileSuccess(fetchedAdContentFiles));
    return fetchedAdContentFiles;
  } catch (error) {
    dispatch(fetchAdContentFileFailed());
    handleError(error, 'Fetch ad content file');
  }
};
