import axios from 'axios';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

const createNewPromotionBegin = () => ({
  type: promotionConst.CREATE_NEW_PROMOTION_BEGIN,
});

const createNewPromotionSuccess = () => ({
  type: promotionConst.CREATE_NEW_PROMOTION_SUCCESS,
});

const createNewPromotionFailed = () => ({
  type: promotionConst.CREATE_NEW_PROMOTION_FAILED,
});

export const createNewPromotionAction = ({
  accountId,
  name,
}) => async dispatch => {
  dispatch(createNewPromotionBegin());
  try {
    const { data } = await axios.post(`/ga/promotion_structure/${accountId}/`, {
      name,
    });
    dispatch(createNewPromotionSuccess());
    return data;
  } catch (error) {
    dispatch(createNewPromotionFailed());
    handleError(error, 'Create new promotion');
    return { error: error.response };
  }
};
