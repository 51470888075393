import {
  ImportAdContentModal,
  ImportSitelinksFileModal,
  LogoutModal,
  SkuFilterModal,
} from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from 'store/actions/modalActions/ModalVisibility';
import { getModalType } from 'store/selectors/modalSelectors';
import { getSelectedPromotionProcessingSku } from 'store/selectors/promotionsSelectors';

export const useModal = () => {
  const isProcessingSku = useSelector(getSelectedPromotionProcessingSku);
  const modalType = useSelector(state => getModalType(state));
  const [modalChild, setModalChild] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const findModalComponent = () => {
      switch (modalType) {
        case 'skuFilterModal':
          return setModalChild(<SkuFilterModal />);
        case 'adContentModal':
          return setModalChild(<ImportAdContentModal />);
        case 'sitelinksModal':
          return setModalChild(<ImportSitelinksFileModal />);
        default:
          setModalChild(<LogoutModal />);
      }
    };
    findModalComponent();
  }, [modalType]);

  const hideModalHandler = () => {
    if (isProcessingSku) return;
    dispatch(hideModal());
  };

  return {
    hideModalHandler,
    modalChild,
  };
};
