import axios from 'axios';
import { sitelinkConst } from 'store/constants/sitelinksConst';
import { handleError } from 'utils';

const fetchPromotionSitelinksFileBegin = () => ({
  type: sitelinkConst.FETCH_PROMOTION_SITELINKS_FILE_BEGIN,
});

const fetchPromotionSitelinksFileSuccess = sitelinksFileData => ({
  type: sitelinkConst.FETCH_PROMOTION_SITELINKS_FILE_SUCCESS,
  sitelinksFileData,
});

const fetchPromotionSitelinksFileFailed = () => ({
  type: sitelinkConst.FETCH_PROMOTION_SITELINKS_FILE_FAILED,
});

export const fetchPromotionSitelinksFile = promotionId => async dispatch => {
  dispatch(fetchPromotionSitelinksFileBegin);
  try {
    const { data } = await axios.get(`/ga/sitelink_file/${promotionId}/`);
    dispatch(fetchPromotionSitelinksFileSuccess(data[0]));
  } catch (error) {
    dispatch(fetchPromotionSitelinksFileFailed());
    return handleError(error, 'Fetch promotion sitelinks file');
  }
};
