import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  
  @media ${props => props.theme.media.desktop} {
    font-size: 16px;
  }
  
  @media ${props => props.theme.media.notebook} {
    font-size: 15px;
  }  
  
  @media ${props => props.theme.media.tablet} {
    font-size: 14px;
  }  
  
  @media ${props => props.theme.media.smartphone} {
    font-size: 12px;
  }
}

*,
*:focus,
*:hover {
  outline:none;
}

body, button, input, textarea, span {
  font-family: 'Roboto',sans-serif;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: ${({ theme }) => theme.pallete.primary};
}

::-webkit-scrollbar-thumb {
  background: ${({ theme }) => theme.pallete.primaryHover}
}

::-webkit-scrollbar-thumb:hover {
  background: ${({ theme }) => theme.pallete.settingsHover};
}

input {
  background: white;
  border: 1px solid ${({ theme }) => theme.pallete.primary};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 4%;
  height: 7.354vh;
  width: 29.5vw;
  font-size: 1rem;
  line-height: 1.5rem;
  
  margin: 3% 0;
}

input:hover {
  transition: .6s;
  border-color: ${({ theme }) => theme.pallete.primary};
}

input::placeholder{
  color: ${({ theme }) => theme.pallete.placeholderText};
}

label {
  width:fit-content;
  display: flex;
  flex-direction: column;
  color:${({ theme }) => theme.pallete.labelColor};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

button {
  border: unset;
  background: unset;
}

h1 {
  font-size: 2em;
}
`;
