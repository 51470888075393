import { modalConst } from 'store/constants/modalConst';

export const showModal = ({ modalProps, modalType, uploadedFileType }) => ({
  type: modalConst.SHOW_MODAL,
  modalType,
  modalProps,
  uploadedFileType,
});

export const hideModal = () => ({
  type: modalConst.HIDE_MODAL,
});
