import { createSelector } from 'reselect';

export const getDropdownHeight = createSelector(
  state => state.helpers,
  helpers => helpers.get('dropdownHeight'),
);

export const getCampaignBoxType = createSelector(
  state => state.helpers,
  helpers => helpers.get('campaignBoxType'),
);
export const getCurrentAdGroupsDataPage = createSelector(
  state => state.helpers,
  helpers => helpers.get('currentAdGroupsDataPage'),
);
export const getIsLoading = createSelector(
  state => state.helpers,
  helpers => helpers.get('isLoading'),
);
export const getHasNext = createSelector(
  state => state.helpers,
  helpers => helpers.get('hasNext'),
);
export const getSearchQuery = createSelector(
  state => state.helpers,
  helpers => helpers.get('searchQuery'),
);
