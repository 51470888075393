import { toast } from 'react-toastify';

export const handleError = (error, extraMessage) => {
  if (error?.response && error?.response?.status === 401) {
    localStorage.removeItem('jwt');
    window.location.href = '/';
  }
  if (error?.response?.data instanceof Object) return error?.response?.data;

  toast.error(
    `Something went wrong... ${extraMessage} (${
      error?.response?.status || error
    })`,
  );
  return 'resolved';
};
