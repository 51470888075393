import useToasts from 'hooks/useToasts';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from 'store/actions/modalActions/ModalVisibility';
import { deletePromotionSitelinksFile } from 'store/actions/sitelinksActions/deletePromotionSitelinksFile';
import { fetchPromotionSitelinksFile } from 'store/actions/sitelinksActions/fetchPromotionSitelinksFiles';
import { uploadPromotionSitelinksFile } from 'store/actions/sitelinksActions/uploadPromotionSitelinksFiles';
import {
  getSelectedPromotion,
  getSitelinksFileData,
} from 'store/selectors/promotionsSelectors';

const useImportSitelinksFile = () => {
  const {
    closeStaticToast,
    showRequestResultsToast,
    showStaticToast,
  } = useToasts();
  const sitelinkFileData = useSelector(getSitelinksFileData);
  const promotionDetails = useSelector(getSelectedPromotion);
  const dispatch = useDispatch();
  const [showUploadField, setShowUploadField] = useState(!sitelinkFileData);
  const promotionId = promotionDetails.get('id');

  const sendSitelinkFile = useCallback(
    async files => {
      showStaticToast('Uploading ad content file...');
      const fileToUpload = files[0];
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);

      if (sitelinkFileData) {
        await dispatch(
          deletePromotionSitelinksFile(promotionId, sitelinkFileData?.id),
        );
      }
      const results = await dispatch(
        uploadPromotionSitelinksFile(promotionId, formData),
      );

      if (results?.error === 'resolved') return closeStaticToast();

      showRequestResultsToast(
        results,
        `Upload failed! ${results?.error && results?.error?.non_field_errors}`,
        'Sitelinks file has been uploaded!',
        () => dispatch(fetchPromotionSitelinksFile(promotionId)),
      );

      dispatch(hideModal());
    },
    [sitelinkFileData],
  );

  useEffect(() => {
    setShowUploadField(!sitelinkFileData);
  }, [sitelinkFileData]);

  return {
    showUploadField,
    setShowUploadField,
    sendSitelinkFile,
    sitelinkFileData,
  };
};

export default useImportSitelinksFile;
