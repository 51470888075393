import { regexes } from 'constant/regexes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginUser } from 'store/actions/userActions/login';
import { getIsLoggingIn } from 'store/selectors/userSelectors';
import { setAuthorization } from 'utils/axiosConfig';

const useLoginView = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [isInputValid, setIsInputValid] = useState(true);
  const isLoggingIn = useSelector(state => getIsLoggingIn(state));

  useEffect(() => {
    if (localStorage.getItem('jwt')) {
      history.push('/account');
    }
  });

  const signIn = async event => {
    event.preventDefault();

    const { token, error } = await dispatch(
      loginUser({
        email: event.target.email.value,
        password: event.target.password.value,
      }),
    );

    if (error) return setError(true);

    localStorage.setItem('jwt', token);
    setAuthorization();
    history.push('/account');
  };

  const validateEmail = event => {
    setIsInputValid(regexes.emailRegex.test(event.target.value));
  };

  return {
    error,
    isLoggingIn,
    isInputValid,
    signIn,
    validateEmail,
  };
};

export default useLoginView;
