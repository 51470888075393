import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHistoryStepIndex,
  updateHistorySteps,
} from 'store/actions/historyActions/historySetters';
import {
  getHistoryStepIndex,
  getHistorySteps,
} from 'store/selectors/historySelectors';

import useAdGroups from './useAdGroups';
import useCampaign from './useCampaign';
import useKeywords from './useKeywords';

const useHistorySteps = () => {
  const dispatch = useDispatch();
  const stepIndex = useSelector(getHistoryStepIndex);
  const historySteps = useSelector(getHistorySteps);
  const undoButtonDisability = historySteps.length && stepIndex > 0;
  const redoButtonDisability =
    historySteps.length && stepIndex < historySteps.length;
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const {
    addCampaignToPromotion,
    deleteCampaignFromPromotion,
    fetchAddedPromotionCampaigns,
  } = useCampaign();

  const {
    addAdGroupToPromotion,
    deletePromotionAdGroup,
    fetchCampaignsAdGroups,
  } = useAdGroups();

  const {
    addKeywordToPromotion,
    deleteKeywordFromPromotion,
    fetchAdGroupKeywords,
  } = useKeywords();

  const redoAction = async () => {
    setIsLoadingHistory(true);
    const historyData = historySteps[stepIndex];
    await recognizeRedoActionType(historyData);
    dispatch(setHistoryStepIndex(stepIndex + 1));
    setIsLoadingHistory(false);
  };

  const undoAction = async () => {
    setIsLoadingHistory(true);
    const historyData = historySteps[stepIndex - 1];
    await recognizeUndoActionType(historyData);
    dispatch(setHistoryStepIndex(stepIndex - 1));
    setIsLoadingHistory(false);
  };

  const recognizeUndoActionType = historyData => {
    if (historyData.action === 'delete') {
      return addFeatureAction(historyData);
    }
    return deleteFeatureAction(historyData);
  };

  const recognizeRedoActionType = historyData => {
    if (historyData.action === 'delete') {
      return deleteFeatureAction(historyData);
    }
    return addFeatureAction(historyData);
  };

  const deleteFeatureAction = async featureData => {
    switch (featureData.featureType) {
      case 'campaign':
        await deleteCampaignFromPromotion(featureData.id);
        return await fetchAddedPromotionCampaigns();
      case 'adGroup':
        await deletePromotionAdGroup(featureData.id, featureData.ad_group);
        return await fetchCampaignsAdGroups({
          campaignId: featureData.parentId,
        });
      case 'keyword':
        await deleteKeywordFromPromotion(featureData.id, featureData.keyword);
        return await fetchAdGroupKeywords(featureData.parentId);
    }
  };

  const addCampaignAction = async campaign => {
    const addedCampaignData = await addCampaignToPromotion(campaign);
    dispatch(updateHistorySteps(addedCampaignData));
    return await fetchAddedPromotionCampaigns();
  };

  const addAdGroupAction = async adGroup => {
    const addedAdGroupData = await addAdGroupToPromotion(adGroup.ad_group);
    dispatch(updateHistorySteps(addedAdGroupData));
    return fetchCampaignsAdGroups({ campaignId: adGroup.parentId });
  };

  const addKeywordAction = async keyword => {
    const addedKeywordData = await addKeywordToPromotion(keyword.keyword);
    dispatch(updateHistorySteps(addedKeywordData));
    return fetchAdGroupKeywords(keyword.parentId);
  };

  const addFeatureAction = async featureData => {
    switch (featureData.featureType) {
      case 'campaign':
        return addCampaignAction(featureData.campaign);
      case 'adGroup':
        return addAdGroupAction(featureData);
      case 'keyword':
        return addKeywordAction(featureData);
    }
  };

  return {
    redoAction,
    redoButtonDisability,
    isLoadingHistory,
    undoAction,
    undoButtonDisability,
  };
};

export default useHistorySteps;
