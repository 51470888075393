import { UserMenu } from 'components';
import { buttonVariants } from 'components/Button';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { downloadTemplateFile } from 'store/actions/templates/downloadFIleTemplate';

import BrandLogo from '../../components/BrandLogo/BrandLogo';
import {
  MainContainerContentWrapper,
  MainContainerHeader,
  MainContainerLeftSide,
  MainContainerRightSide,
  MainContainerWrapper,
  MainUserMenuWrapper,
  SampleFileButton,
  SiteLinksContainer,
  TextWrapper,
} from './MainContainer.styled';

const MainContainer = ({ children, downloadTemplateFile }) => {
  const btnNamesList = ['ETA', 'RSA', 'SKU', 'Sitelinks'];

  const onSampleButtonClick = async fileType => {
    const file = await downloadTemplateFile(fileType.toLowerCase());
    if (!file) return;
    fileDownload(file, `${fileType}_sample.xlsx`);
  };

  return (
    <MainContainerWrapper>
      <MainContainerLeftSide>
        <MainContainerHeader>
          <BrandLogo />
        </MainContainerHeader>
        <MainContainerContentWrapper>{children}</MainContainerContentWrapper>
      </MainContainerLeftSide>
      <MainContainerRightSide>
        {localStorage.getItem('jwt') && (
          <MainUserMenuWrapper>
            <UserMenu variant={buttonVariants.SECONDARY} />
          </MainUserMenuWrapper>
        )}
        <TextWrapper>We make advertising work better for people</TextWrapper>
        <SiteLinksContainer>
          {btnNamesList.map(btnName => (
            <li key={btnName}>
              <SampleFileButton
                onClick={() => onSampleButtonClick(btnName)}
                variant={buttonVariants.SECONDARY}
              >
                {btnName}
              </SampleFileButton>
            </li>
          ))}
        </SiteLinksContainer>
      </MainContainerRightSide>
    </MainContainerWrapper>
  );
};

MainContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  downloadTemplateFile: PropTypes.func,
};

const mapDispatchToProps = {
  downloadTemplateFile,
};

export default connect(null, mapDispatchToProps)(MainContainer);
