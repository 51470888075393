import { FeatureTile, FeatureTileVariants } from 'components/FeatureTile';
import { TileBlock } from 'components/FeatureTile/FeatureTile.styles';
import KeywordBox from 'components/KeywordBox';
import { TileChildrenContainer } from 'containers';
import useNewFeature from 'hooks/useNewFeature';
import PropTypes from 'prop-types';
import React from 'react';

import { AdGroupTitle, AdGroupTitleInput } from './AdGroupBox.styled';
import useAdGroupBox from './useAdGroupBox.hook';

const AdGroupBox = ({ adGroupData }) => {
  const { id, ad_group_name, ad_group: adGroupId, campaignId } = adGroupData;
  const { promotionKeywords, fetchExpandedAdGroupChildren } = useAdGroupBox(
    adGroupData,
  );
  const { onInputBlur, inputRef, inputValue, setInputValue } = useNewFeature(
    campaignId,
    FeatureTileVariants.adGroup,
  );

  return (
    <FeatureTile
      fetchChildrenAction={fetchExpandedAdGroupChildren}
      ids={{ psId: id, parentId: campaignId, featureId: adGroupId }}
      tileType={FeatureTileVariants.adGroup}
      featureBlock={
        <TileBlock data-tip={ad_group_name}>
          {ad_group_name ? (
            <AdGroupTitle>
              <span>{ad_group_name || inputValue}</span>
            </AdGroupTitle>
          ) : (
            <AdGroupTitleInput
              onBlur={onInputBlur}
              ref={inputRef}
              contentEditable={!ad_group_name}
              onChange={e => setInputValue(e.target.value)}
              placeholder="Enter adgroup name"
            />
          )}
        </TileBlock>
      }
    >
      <TileChildrenContainer>
        {promotionKeywords &&
          promotionKeywords.map(({ id: psId, keyword }) => (
            <KeywordBox
              key={keyword.ga_id || keyword.id}
              keywordData={{ psId, keyword }}
            />
          ))}
      </TileChildrenContainer>
    </FeatureTile>
  );
};

AdGroupBox.propTypes = {
  adGroupData: PropTypes.shape({
    id: PropTypes.number,
    ad_group_name: PropTypes.string,
    ad_group: PropTypes.number,
    campaignId: PropTypes.number,
  }),
  expandParentBox: PropTypes.func,
};

export default AdGroupBox;
