import axios from 'axios';
import queryString from 'query-string';
import { promotionConst } from 'store/constants/promotionConst';
import { handleError } from 'utils';

const fetchPromotionKeywordsBegin = () => ({
  type: promotionConst.FETCH_PROMOTION_KEYWORDS_BEGIN,
});

const fetchPromotionKeywordsSuccess = (
  fetchedPromotionKeywords,
  adGroupId,
) => ({
  type: promotionConst.FETCH_PROMOTION_KEYWORDS_SUCCESS,
  fetchedPromotionKeywords,
  adGroupId,
});

const fetchPromotionKeywordsFailed = () => ({
  type: promotionConst.FETCH_PROMOTION_KEYWORDS_FAILED,
});

export const fetchPromotionKeywords = (
  promotionId,
  query,
) => async dispatch => {
  const stringifiedQuery = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  dispatch(fetchPromotionKeywordsBegin);
  try {
    const { data } = await axios.get(
      `/ga/ps_keyword/${promotionId}/${
        stringifiedQuery && `?${stringifiedQuery}`
      }`,
    );
    dispatch(
      fetchPromotionKeywordsSuccess(data.results, query.keyword__ad_group),
    );
    return data;
  } catch (error) {
    dispatch(fetchPromotionKeywordsFailed());
    return handleError(error, 'Fetch promotion keywords');
  }
};
