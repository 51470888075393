import { Button } from 'components';
import styled from 'styled-components';

export const ResetPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 5px 0;
`;

export const BackButton = styled.button`
  background: none;
  width: 28px;
  padding: 0;
  border: none;
`;

export const ResetPasswordInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RedirectTimeSpan = styled.span`
  margin-top: 4%;
  font-weight: 600;
`;

export const ResetButton = styled(Button)`
  margin-top: 3.5%;
`;

export const ResetPasswordInput = styled.input`
  margin-top: 2%;
  margin-bottom: 1.1%;
  color: var(--primary-blue-60);
  height: 5vh;
  font-size: 0.7rem;
`;

export const ResetPasswordContentWrapper = styled.div`
  label {
    color: black;
    margin: unset;
    margin-top: 2.9%;
  }
`;
