import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountPromotions } from 'store/actions/userActions/promotions/fetchAccountPromotions';
import { getUserAccountDetails } from 'store/selectors/userSelectors';

export const usePromotionTable = () => {
  const accountDetails = useSelector(getUserAccountDetails);
  const [promotions, setPromotions] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const { userId } = accountDetails;

  const asyncFetchPromotions = async page =>
    dispatch(fetchAccountPromotions(userId, page));

  const fetchPromotions = async page => {
    let results;
    const data = await asyncFetchPromotions({ page });
    setIsFetching(false);
    if (!data) return setPromotions(null);

    results = data.results;

    if (page > 1) results = [...promotions, ...data.results];
    setPromotions(results);
    setHasMore(Boolean(data.next));
  };

  const loadMoreAction = page => {
    setHasMore(false);
    fetchPromotions(page);
  };

  useEffect(() => {
    if (!userId) return;
    setIsFetching(true);
    setHasMore(false);
    fetchPromotions();
  }, [userId]);

  return { hasMore, isFetching, loadMoreAction, promotions };
};

export default usePromotionTable;
