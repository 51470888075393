import styled from 'styled-components';

export const TableColumnHeader = styled.span`
  align-items: center;
  display: flex;
  flex-basis: ${({ flexBasis }) => flexBasis ?? '26%'};
  flex-grow: 0;
  max-width: ${({ maxWidth }) => maxWidth ?? '26%'};
  word-break: break-word;
  ${({ center }) => center && `justify-content: center;`}

  @media ${props => props.theme.media.notebook} {
    font-size: 10px;
    line-height: 14px;
  }
`;

export const TableCell = styled.div`
  padding: 0 5px;
  align-items: center;
  display: flex;
  flex-basis: ${({ flexBasis }) => flexBasis ?? '30%'};
  min-height: inherit;
  max-width: ${({ maxWidth }) => maxWidth ?? '30%'};
  flex-grow: 0;
  word-break: break-all;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  cursor: default;
  ${({ center }) =>
    center &&
    `justify-content: center; 
     text-align:center`};

  &:nth-child(odd) {
    color: ${({ theme }) => theme.pallete.placeholderText};
  }

  @media ${props => props.theme.media.notebook} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const TableRow = styled.div`
  display: flex;
  font-size: 0.8rem;
  min-height: 5.6vh;
  line-height: 1.2rem;

  :first-child {
    align-items: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    justify-content: center;
    width:100%;
  }

  :last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

  border-radius: 4px;

  &:nth-child(odd) {
    background: ${({ theme }) => theme.pallete.tableRowsHighlight};
  }

  &:hover {
    background-color: ${({ theme }) => theme.pallete.primaryDisabled};
  }
}
`;

export const EllipsisBeginSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
`;

export const ListHeader = styled.div`
  display: flex;
  color: ${({ theme }) => theme.pallete.placeholderText};
  font-weight: 400;
  font-size: 12px;
  max-height: 40px;
  height: 4.085vh;

  @media ${props => props.theme.media.notebook} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const StickyListHeader = styled(ListHeader)`
  background: ${({ theme }) => theme.pallete.tableRowsHighlight};
  color: #000;
  border-bottom: 2px solid ${({ theme }) => theme.pallete.inputsBorder};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: fit-content;
  min-width: 100%;
  justify-content: space-between;
`;
