import { MessageAltError } from '@styled-icons/boxicons-regular/MessageAltError';
import styled from 'styled-components';

export const StyledMessageAltError = styled(MessageAltError)`
  color: ${({ theme }) => theme.pallete.placeholderText};
  height: 10vh;
`;

export const NoContentMessage = styled.span`
  color: ${({ theme }) => theme.pallete.placeholderText};
`;
