import AdGroupBox from 'components/AdGroupBox/AdGroupBox';
import { TileChildrenContainer } from 'containers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import useCampaignAdGroups from './CampainAdGroups.hook';

const CampaignAdGroups = ({ campaignData, isBoxExpanded }) => {
  const { campaign, id: psCampaignId } = campaignData;

  const { campaignAdGroups } = useCampaignAdGroups(campaign.id, psCampaignId);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [campaignAdGroups]);
  if (!Array.isArray(campaignAdGroups)) return;

  return (
    <TileChildrenContainer>
      {isBoxExpanded &&
        campaignAdGroups.map(adGroupData => (
          <AdGroupBox
            key={adGroupData.id || adGroupData.ad_group}
            adGroupData={{ ...adGroupData, campaignId: campaign.id }}
          />
        ))}
    </TileChildrenContainer>
  );
};
CampaignAdGroups.propTypes = {
  campaignData: PropTypes.shape({
    id: PropTypes.number,
    campaign: PropTypes.object,
  }),
  isBoxExpanded: PropTypes.bool,
};
export default CampaignAdGroups;
