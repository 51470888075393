import { fromJS, List, Map } from 'immutable';
import { adContentConst } from 'store/constants/adContentConst';
import { promotionConst } from 'store/constants/promotionConst';
import { sitelinkConst } from 'store/constants/sitelinksConst';
import { skuConst } from 'store/constants/skuConst';

const initialState = Map({
  fetchedPromotions: List([]),
  fetchedPromotionCampaigns: List([]),
  fetchedPromotionAdGroups: Map({}),
  fetchedPromotionKeywords: Map({}),
  filterProductNames: false,
  adGroupsListToFilter: List([]),
  selectedPromotion: Map({}),
  hasMoreCampaigns: false,
  adContentFiles: null,
  sitelinksFileData: null,
  filteredAdGroupsBySkuBox: List([]),
  filteredAdGroupsBySimilarity: List([]),
});

const setHasMoreCampaigns = (state, { hasMoreCampaigns }) =>
  state.set('hasMoreCampaigns', hasMoreCampaigns);

const setSelectedPromotion = (state, { promotion }) =>
  state.set('selectedPromotion', fromJS(promotion));

const setFetchedPromotions = (state, { fetchedPromotions }) =>
  state.set('fetchedPromotions', fromJS(fetchedPromotions));

const setFetchedPromotionCampaigns = (
  state,
  { fetchedPromotionsCampaigns, query },
) => {
  if (query?.page > 1) {
    return state.set(
      'fetchedPromotionCampaigns',
      state
        .get('fetchedPromotionCampaigns')
        .merge(fromJS(fetchedPromotionsCampaigns)),
    );
  }

  return state.set(
    'fetchedPromotionCampaigns',
    fromJS(fetchedPromotionsCampaigns),
  );
};

const setFetchedPromotionAdGroups = (
  state,
  { fetchedPromotionAdGroups, campaignId },
) =>
  state.set(
    'fetchedPromotionAdGroups',
    state.get('fetchedPromotionAdGroups').merge({
      [campaignId]: fromJS(fetchedPromotionAdGroups),
    }),
  );

const setFetchedPromotionKeywords = (
  state,
  { fetchedPromotionKeywords, adGroupId },
) =>
  state.set(
    'fetchedPromotionKeywords',
    state.get('fetchedPromotionKeywords').merge({
      [adGroupId]: fromJS(fetchedPromotionKeywords),
    }),
  );

const updateAdGroupsListToFilter = (state, { adGroups }) =>
  state.set(
    'adGroupsListToFilter',
    state.get('adGroupsListToFilter').merge(fromJS(adGroups)),
  );

const setAdGroupsListToFilter = (state, { adGroups }) =>
  state.set('adGroupsListToFilter', fromJS(adGroups));

const clearAdGroupsListToFilter = state =>
  state.set('adGroupsListToFilter', fromJS([]));

const setFilterProductNames = (state, { filterProductNames }) =>
  state.set('filterProductNames', filterProductNames);

const setAdContentFiles = (state, { adContentFiles }) =>
  state.set('adContentFiles', adContentFiles);

const setSitelinksFileData = (state, { sitelinksFileData }) =>
  state.set('sitelinksFileData', sitelinksFileData);

const setFilteredAdGroupsBySkuBox = (state, { filteredAdGroupsBySkuBox }) =>
  state.set('filteredAdGroupsBySkuBox', fromJS(filteredAdGroupsBySkuBox));

const updateFilteredAdGroupsBySkuBox = (
  state,
  { filteredAdGroupsBySkuBox },
) => {
  const currentFiltered = state.get('filteredAdGroupsBySkuBox');
  let newData = fromJS(filteredAdGroupsBySkuBox);
  if (currentFiltered.size) {
    newData = currentFiltered.merge(fromJS(filteredAdGroupsBySkuBox));
  }
  return state.set('filteredAdGroupsBySkuBox', fromJS(newData));
};

const setFilteredAdGroupsBySimilarity = (
  state,
  { filteredAdGroupsBySimilarity },
) =>
  state.set(
    'filteredAdGroupsBySimilarity',
    fromJS(filteredAdGroupsBySimilarity),
  );

const updateFilteredAdGroupsBySimilarity = (
  state,
  { filteredAdGroupsBySimilarity },
) => {
  const currentFiltered = state.get('filteredAdGroupsBySimilarity');
  let newData = filteredAdGroupsBySimilarity;
  if (currentFiltered.size) {
    newData = currentFiltered.merge(fromJS(filteredAdGroupsBySimilarity));
  }
  return state.set('filteredAdGroupsBySimilarity', fromJS(newData));
};

export const promotionsReducer = (state = initialState, action) =>
  ((
    {
      [promotionConst.SET_HAS_MORE_CAMPAIGNS]: setHasMoreCampaigns,
      [promotionConst.SET_SELECTED_PROMOTION]: setSelectedPromotion,
      [promotionConst.FETCH_PROMOTION_STRUCTURE_SUCCESS]: setFetchedPromotions,
      [promotionConst.FETCH_PROMOTION_CAMPAIGNS_SUCCESS]: setFetchedPromotionCampaigns,
      [promotionConst.SET_FETCHED_PROMOTION_AD_GROUPS]: setFetchedPromotionAdGroups,
      [promotionConst.FETCH_PROMOTION_KEYWORDS_SUCCESS]: setFetchedPromotionKeywords,
      [promotionConst.CLEAR_AD_GROUPS_LIST_TO_FILTER]: clearAdGroupsListToFilter,
      [promotionConst.SET_AD_GROUPS_LIST_TO_FILTER]: setAdGroupsListToFilter,
      [promotionConst.UPDATE_AD_GROUPS_LIST_TO_FILTER]: updateAdGroupsListToFilter,
      [promotionConst.SET_FILTERED_AD_GROUPS_BY_SKU_BOX]: setFilteredAdGroupsBySkuBox,
      [promotionConst.SET_FILTERED_AD_GROUPS_BY_SIMILARITY]: setFilteredAdGroupsBySimilarity,
      [skuConst.FETCH_FILTERED_ADGROUPS_BY_SKU_BOX_SUCCESS]: updateFilteredAdGroupsBySkuBox,
      [skuConst.FETCH_FILTERED_ADGROUPS_BY_SIMILARITY_SUCCESS]: updateFilteredAdGroupsBySimilarity,
      [promotionConst.SET_FILTER_PRODUCT_NAMES]: setFilterProductNames,
      [adContentConst.FETCH_AD_CONTENT_FILE_SUCCESS]: setAdContentFiles,
      [sitelinkConst.FETCH_PROMOTION_SITELINKS_FILE_SUCCESS]: setSitelinksFileData,
    }[action.type] || (fn => fn)
  )(state, action));
