import { BrandTableCell } from 'components/BrandTable/BrandTable.styled';
import { DisableCheck } from 'components/SkuFilterModal/FilteredAdGroupTable/FilteredAdGroupTable.styled';
import PropTypes from 'prop-types';
import React from 'react';

import useCheckButtonsCells from './CheckButtonsCells.hook';

const CheckButtonsCells = ({ adGroupId, labels }) => {
  const {
    brand,
    generic,
    brandProduct,
    onChangeLabelState,
  } = useCheckButtonsCells(adGroupId, labels);
  return (
    <>
      <BrandTableCell
        onClick={() => onChangeLabelState('Brand', !brand)}
        center
        flexBasis="8%"
      >
        <DisableCheck disabledRow={!brand} />
      </BrandTableCell>
      <BrandTableCell
        onClick={() => onChangeLabelState('Generic', !generic)}
        center
        flexBasis="8%"
      >
        <DisableCheck disabledRow={!generic} />
      </BrandTableCell>
      <BrandTableCell
        onClick={() => onChangeLabelState('Brand Product', !brandProduct)}
        center
        flexBasis="8%"
      >
        <DisableCheck disabledRow={!brandProduct} />
      </BrandTableCell>
    </>
  );
};

CheckButtonsCells.propTypes = {
  adGroupId: PropTypes.number,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.number,
      ps_ad_group: PropTypes.number,
    }),
  ),
};
export default CheckButtonsCells;
