import axios from 'axios';
import { userConstants } from 'store/constants/userConst';
import { handleError } from 'utils';

const loginUserBegin = () => ({
  type: userConstants.LOGIN_BEGIN,
});

const loginUserSuccess = userData => ({
  type: userConstants.LOGIN_SUCCESS,
  userData,
});

const loginUserFailure = () => ({
  type: userConstants.LOGIN_FAILURE,
});

export const loginUser = credentials => async dispatch => {
  dispatch(loginUserBegin());
  try {
    const { data } = await axios.post('user/auth/', credentials);
    dispatch(loginUserSuccess());
    return { token: data.access };
  } catch (error) {
    dispatch(loginUserFailure());
    return { error: handleError(error, 'Login user') };
  }
};
