import { useDispatch, useSelector } from 'react-redux';
import { addNewCampaignToPromotion } from 'store/actions/campaignActions/addCampaignToPromotionAction';
import { fetchCampaigns } from 'store/actions/campaignActions/fetchCampaignsAction';
import { fetchPromotionCampaigns } from 'store/actions/promotionActions/fetchPromotionCampaigns';
import { removePromotionCampaign } from 'store/actions/promotionActions/removePromotionCampaignAction';
import {
  getFetchedPromotionCampaigns,
  getSelectedPromotionId,
} from 'store/selectors/promotionsSelectors';
import { getUserAccountDetails } from 'store/selectors/userSelectors';

const useCampaign = () => {
  const dispatch = useDispatch();
  const campaigns = useSelector(getFetchedPromotionCampaigns)?.toJS();
  const promotionId = useSelector(getSelectedPromotionId);
  const { userId } = useSelector(getUserAccountDetails);

  const deleteCampaignFromPromotion = async campaignId => {
    await dispatch(removePromotionCampaign(promotionId, campaignId));
  };

  const addCampaignToPromotion = campaignId =>
    dispatch(
      addNewCampaignToPromotion(promotionId, {
        campaign: campaignId,
        with_contents: true,
      }),
    );

  const fetchCampaignsHints = async (searchQuery, page) =>
    await dispatch(
      fetchCampaigns(userId, {
        search: searchQuery,
        page,
        exclude_promotion: promotionId,
      }),
    );

  const fetchAddedPromotionCampaigns = async query => {
    if (!promotionId) return;
    await dispatch(fetchPromotionCampaigns(promotionId, query));
  };

  return {
    addCampaignToPromotion,
    campaigns,
    deleteCampaignFromPromotion,
    fetchAddedPromotionCampaigns,
    fetchCampaignsHints,
  };
};

export default useCampaign;
