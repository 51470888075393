import { useSelector } from 'react-redux';
import { getNewFeaturesDraft } from 'store/selectors/draftSelectors';
import { getFetchedPromotionAdGroups } from 'store/selectors/promotionsSelectors';

const useCampaignAdGroups = (campaignId, psCampaignId) => {
  let campaignAdGroups = [];
  const addedAdGroups = useSelector(getFetchedPromotionAdGroups);

  const featuresInDraft = useSelector(state =>
    getNewFeaturesDraft(state, psCampaignId),
  );

  if (addedAdGroups.get(String(campaignId))) {
    campaignAdGroups = addedAdGroups.get(String(campaignId)).toJS();
  }
  if (featuresInDraft) {
    campaignAdGroups.push(featuresInDraft.toJS());
  }

  return { campaignAdGroups };
};
export default useCampaignAdGroups;
