import { ReactComponent as UserIconSvg } from 'media/svg/icons/user.svg';
import styled from 'styled-components';

export const Avatar = styled.button`
  justify-content: center;
  vertical-align: middle;
  width: 2.7rem;
  height: 2.7rem;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 2px solid
    ${({ variant, theme }) =>
      variant === 'secondary' ? 'white' : theme.pallete.primary};
  display: flex;
  align-items: center;
  background: white;
  transition: 0.6s;

  &:hover {
    background: ${({ theme }) => theme.pallete.primary};
    path {
      fill: white;
    }
  }
`;

export const UserIcon = styled(UserIconSvg)`
  height: 40%;

  path {
    fill: ${({ theme }) => theme.pallete.primary};
  }
`;
